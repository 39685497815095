import * as Client from '@ClientDto/index';
import * as Enums from '@ClientDto/Enums';
import { CabinetSectionProperties } from '../properties/CabinetSectionProperties';

export class SwingFlexSubArea {
  constructor(
    public readonly parentArea: Client.SwingFlexArea,
    subAreaProperties?: CabinetSectionProperties.SwingFlexSubArea,
    swingFlex?: Client.SwingFlex,
  ) {
    if (!!subAreaProperties) {
      // Door
      this.hasDoor = subAreaProperties.hasDoor;
      this.doorMaterial = subAreaProperties.doorMaterialId
        ? (swingFlex?.pickableDoorMaterials
            .map((pickableMaterial) => pickableMaterial.item)
            .find(
              (material) => material.Id === subAreaProperties.doorMaterialId,
            ) ?? null)
        : null;

      // Desired hinge types
      if (subAreaProperties.hingeType === Enums.SwingFlexHingeType.Special)
        this.desiredHingeType = Enums.SwingFlexHingeType.Special;
      if (subAreaProperties.hingeTypeLeft === Enums.SwingFlexHingeType.Special)
        this.desiredHingeTypeLeft = Enums.SwingFlexHingeType.Special;
      if (subAreaProperties.hingeTypeRight === Enums.SwingFlexHingeType.Special)
        this.desiredHingeTypeRight = Enums.SwingFlexHingeType.Special;

      // Grip
      this.openingMethod = subAreaProperties.openingMethod;
      this.gripPosition = subAreaProperties.gripPosition;
      this.doorGrip = subAreaProperties.doorGripId
        ? (swingFlex?.availableDoorGrips.find(
            (grip) => grip && grip.Id === subAreaProperties.doorGripId,
          ) ?? null)
        : null;
      this.doorGripMaterial = subAreaProperties.doorGripMaterialId
        ? (swingFlex?.pickableDoorGripMaterials
            .map((pickableMaterial) => pickableMaterial.item)
            .find(
              (material) =>
                material.Id === subAreaProperties.doorGripMaterialId,
            ) ?? null)
        : null;

      // Middle gable
      this.hasMiddlePanel = subAreaProperties.hasMiddlePanel;
      this.middlePanelPostion = subAreaProperties.middlePanelPosition;
      this.middlePanelHeight = subAreaProperties.middlePanelHeight;
    } else {
      this.setPropertiesFromParentArea();
    }
  }

  private setPropertiesFromParentArea() {
    // Door
    this.doorMaterial = this.parentArea.doorMaterial;

    // Grip
    this.openingMethod = this.parentArea.openingMethod;
    this.doorGrip = this.parentArea.doorGrip;
    this.doorGripMaterial = this.parentArea.doorGripMaterial;
  }

  public insideRect: Client.Rectangle = new Client.Rectangle();

  // Door
  public isDoorPossible: boolean = true;
  public hasDoor: boolean = true;
  public doorMaterial: Client.Material | null = null;
  public doorItems: Client.ConfigurationItem[] = [];

  // Hinge types
  public readonly possibleHingeTypes: Enums.SwingFlexHingeType[] = [
    Enums.SwingFlexHingeType.Auto,
    Enums.SwingFlexHingeType.Special,
  ];

  public desiredHingeTypeLeft: Enums.SwingFlexHingeType =
    Enums.SwingFlexHingeType.Auto;
  public desiredHingeTypeRight: Enums.SwingFlexHingeType =
    Enums.SwingFlexHingeType.Auto;

  public actualHingeTypeLeft:
    | Enums.SwingFlexHingeType.Standard
    | Enums.SwingFlexHingeType.Special = Enums.SwingFlexHingeType.Standard;
  public actualHingeTypeRight:
    | Enums.SwingFlexHingeType.Standard
    | Enums.SwingFlexHingeType.Special = Enums.SwingFlexHingeType.Standard;

  // Grip
  public openingMethod: Enums.SwingFlexOpeningMethod =
    Enums.SwingFlexOpeningMethod.Grip;
  public gripPosition: Enums.SwingFlexGripPosition =
    Enums.SwingFlexGripPosition.Auto;
  private _doorGrip: Client.Product | null = null;
  public doorGripMaterial: Client.Material | null = null;

  public hasDrawerBelow: boolean = false;
  public hasDrawerAbove: boolean = false;

  // Middle gable
  public isMiddlePanelPossible: boolean = false;
  public availableMiddlePanelHeights: number[] = [];
  public hasMiddlePanel: boolean = false;
  public middlePanelPostion: number | null = null;
  public middlePanelHeight: number | null = null;

  // Properties
  public get desiredHingeType(): Enums.SwingFlexHingeType {
    switch (this.parentArea.hingeSide) {
      case Enums.HingeSide.Left:
        return this.desiredHingeTypeLeft;
      case Enums.HingeSide.Right:
        return this.desiredHingeTypeRight;
      default:
        return Enums.SwingFlexHingeType.Auto;
    }
  }
  public set desiredHingeType(value: Enums.SwingFlexHingeType) {
    this.desiredHingeTypeLeft = value;
    this.desiredHingeTypeRight = value;
  }

  public get actualHingeType():
    | Enums.SwingFlexHingeType.Standard
    | Enums.SwingFlexHingeType.Special {
    switch (this.parentArea.hingeSide) {
      case Enums.HingeSide.Left:
        return this.actualHingeTypeLeft;
      case Enums.HingeSide.Right:
        return this.actualHingeTypeRight;
      default:
        if (
          this.actualHingeTypeLeft === Enums.SwingFlexHingeType.Special ||
          this.actualHingeTypeRight === Enums.SwingFlexHingeType.Special
        ) {
          return Enums.SwingFlexHingeType.Special;
        }
        return Enums.SwingFlexHingeType.Standard;
    }
  }
  public set actualHingeType(
    value: Enums.SwingFlexHingeType.Standard | Enums.SwingFlexHingeType.Special,
  ) {
    this.actualHingeTypeLeft = value;
    this.actualHingeTypeRight = value;
  }

  public get position(): number {
    return this.insideRect.Y;
  }

  public get height(): number {
    return this.insideRect.Height;
  }

  public get doorGrip(): Client.Product | null {
    return this._doorGrip;
  }
  public set doorGrip(val: Client.Product | null) {
    this._doorGrip = val;
    this.doorGripMaterial = val?.materials[0] ?? null;
  }

  public save(): CabinetSectionProperties.SwingFlexSubArea {
    return {
      hasDoor: this.hasDoor,
      doorMaterialId: this.doorMaterial?.Id ?? null,
      hingeType: this.desiredHingeType,
      hingeTypeLeft: this.desiredHingeTypeLeft,
      hingeTypeRight: this.desiredHingeTypeRight,
      openingMethod: this.openingMethod,
      gripPosition: this.gripPosition,
      doorGripId: this.doorGrip?.Id ?? null,
      doorGripMaterialId: this.doorGripMaterial?.Id ?? null,
      hasMiddlePanel: this.hasMiddlePanel,
      middlePanelPosition: this.middlePanelPostion,
      middlePanelHeight: this.middlePanelHeight,
    };
  }
}
