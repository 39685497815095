import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as App from 'app/ts/app';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { InteriorService } from 'app/ts/services/ConfigurationLogic/InteriorService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { ISubscription } from 'app/ts/util/Observable';
import { ProperSvgElement } from 'app/ts/util/ProperSvgElement';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import Enumerable from 'linq';
import { DragInfoService } from './drag-info.service';
import { SelectedItemsService } from './selected-items.service';
import { SwingImageVm } from './SwingImageVm';
import { SwingFlexSubArea } from '@ClientDto/SwingFlexSubArea';
import { Cube } from 'app/ts/Interface_DTO_Draw';
import {
  SwingFlexComponent,
  SwingFlexImageComponent,
} from './swing-flex/swingFlexImage.component';

@Component({
  selector: 'interior2d',
  templateUrl: './interior2d.svg',
  styleUrls: ['../../style/d2.scss'],
})
export class Interior2dVm extends BaseVm implements OnInit {
  private static readonly topDownPadding = 120;

  constructor(
    baseVmService: BaseVmService,
    private readonly configurationItemService: ConfigurationItemService,
    private readonly floorPlanService: FloorPlanService,
    private readonly interiorService: InteriorService,
    private readonly notificationService: NotificationService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly selectedItems: SelectedItemsService,
    private readonly dragInfo2: DragInfoService,
  ) {
    super(baseVmService);
  }

  private static readonly canvasMargin = 50; //mm
  private static readonly initailMinimumDragDist = 6; //px;
  private selectedSwingSubArea: SwingFlexSubArea | null = null;

  public readonly itemRulerBackgroundWidthWide = 200;
  public readonly itemRulerBackgroundWidthNarrow = 85;

  private svgCanvas!: ProperSvgElement;
  private svgMousePoint!: SVGPoint;
  public get dragInfo():
    | Interior2dVm.ItemDragInfo
    | Interior2dVm.RectangleSelectInfo
    | Interior2dVm.NeighborItemDragPrepareInfo
    | Interior2dVm.NeighborItemDragInfo
    | undefined {
    return this.dragInfo2.dragInfo;
  }

  public set dragInfo(
    value:
      | Interior2dVm.ItemDragInfo
      | Interior2dVm.RectangleSelectInfo
      | Interior2dVm.NeighborItemDragPrepareInfo
      | Interior2dVm.NeighborItemDragInfo
      | undefined,
  ) {
    this.dragInfo2.dragInfo = value;
  }

  private cache: Partial<{
    leftNeighborInfo: Interior2dVm.NeighborInfo | null;
    rightNeighborInfo: Interior2dVm.NeighborInfo | null;
    neighbors: Interior2dVm.NeighborInfo[];
    rulers: Client.Ruler[];
    sectionRulers: Client.Ruler[];
    corpusItems: Client.ConfigurationItem[];
    corpusJoints: Interior2dVm.Joint[];
    freespaces: Interface_DTO_Draw.Rectangle[];
    swingDoors: SwingImageVm.Door[];
    swingItems: Client.ConfigurationItem[];
    swingFlexItems: Client.ConfigurationItem[];
    hinges: SwingFlexComponent.Hinge[] | undefined;
    swingFlexDoorItems: Client.ConfigurationItem[];
    sortedInteriorItems: Client.ConfigurationItem[];
  }> = {};

  @Input()
  public cabinetSection!: Client.CabinetSection;
  @Input()
  public showRulers: boolean = false;
  @Input()
  public showPullout: boolean = false;
  @Input()
  public showCorpus: boolean = true;
  @Input()
  public showHeightReduction: boolean = false;
  @Input()
  public showDummyItems: boolean = true;
  @Input()
  public showWarnings = true;
  @Input()
  public showHinges: boolean = false;
  @Input()
  public showSwingFlexDoors: boolean = false;

  public getOrderedByGableItems(): Client.ConfigurationItem[] {
    if (this.cache.sortedInteriorItems === undefined) {
      this.cache.sortedInteriorItems = Enumerable.from(
        this.cabinetSection.interior.items,
      )
        .orderBy((item) => (item.isGable ? 0 : 1))
        .thenBy((item) => item.frontZ)
        .toArray();
    }
    return this.cache.sortedInteriorItems;
  }

  public ngOnInit() {
    this.svgCanvas = window.document.getElementById('interior2d') as any;
    this.svgMousePoint = this.svgCanvas.createSVGPoint();

    this.subscribeTo(this.cabinetSection.cabinet.floorPlan.floorPlan$, (fp) =>
      this.clearCache(),
    );
    this.subscribeTo(this.cabinetSection.ruler$, (fp) =>
      this.clearSectionRulerCache(),
    );

    this.subscribeTo(this.dragInfo2.dragItem$, (dragInfo) => {
      this.handleDragInfo(dragInfo);
    });
  }

  private clearSectionRulerCache() {
    this.cache.sectionRulers = undefined;
  }

  private clearCache() {
    this.cache = {};
  }

  // { #region svg-specific strings
  public get canvasViewBoxString(): string {
    let margin = Constants.canvasMargin;
    let start = this.viewBoxStart;
    let size = this.viewBoxSize;
    return start.X + ' ' + start.Y + ' ' + size.X + ' ' + size.Y;
  }

  private get viewBoxStart(): Interface_DTO_Draw.Vec2d {
    let margin = Constants.canvasMargin;
    let result: Interface_DTO_Draw.Vec2d = {
      X: -margin,
      Y: -margin,
    };

    if (this.showRulers) {
      result.X -= 2 * Constants.rulerWidth;
      let numTopRulers = this.cabinetSection.isSwingFlex ? 3 : 2;
      result.Y -= numTopRulers * Constants.rulerWidth;
    }

    return result;
  }

  private get viewBoxSize(): Interface_DTO_Draw.Vec2d {
    let margin = Constants.canvasMargin;

    let result: Interface_DTO_Draw.Vec2d = {
      X: this.cabinetSection.Width,
      Y: this.cabinetSection.Height,
    };
    result = VectorHelper.subtract(result, this.viewBoxStart);
    if (this.cabinetSection.isSwing) {
      result.Y += Math.max(0, ...this.swingDoors.map((d) => d.pos2.Y));
    }
    if (this.showRulers) {
      if (!this.cabinetSection.isSwing) {
        result.Y += Constants.rulerWidth; //make room for bottom rulers
        result.Y +=
          this.cabinetSection.Depth -
          this.cabinetSection.interior.cube.Depth -
          this.cabinetSection.interior.cube.Z; //make room for doors
      }
    }

    result.X += margin;
    result.Y += margin;

    return result;
  }

  public get showSwingDoors(): boolean {
    return this.cabinetSection.isSwing;
  }
  public get showSwingItems(): boolean {
    return this.cabinetSection.isSwing;
  }

  public get swingItems(): Client.ConfigurationItem[] {
    if (!this.cache.swingItems) {
      this.cache.swingItems = Enumerable.from(this.cabinetSection.swing.items)
        .where(
          (item) =>
            item.ItemType !== Interface_Enums.ItemType.SwingDoor &&
            item.ItemType !== Interface_Enums.ItemType.SwingModule &&
            item.ItemType !== Interface_Enums.ItemType.SwingExtra,
        )
        .orderBy((item) => item.frontZ)
        .toArray();
    }
    return this.cache.swingItems;
  }

  public get swingFlexItems(): Client.ConfigurationItem[] {
    if (!this.cache.swingFlexItems) {
      this.cache.swingFlexItems = Enumerable.from(
        this.cabinetSection.swingFlex.items,
      )
        .where(
          (item) => item.ItemType !== Interface_Enums.ItemType.SwingFlexDoor,
        )
        .orderBy((item) => item.frontZ)
        .toArray();
    }
    return this.cache.swingFlexItems;
  }

  public get swingFlexDoorItems(): Client.ConfigurationItem[] {
    if (!this.cache.swingFlexDoorItems) {
      this.cache.swingFlexDoorItems = Enumerable.from(
        this.cabinetSection.swingFlex.items,
      )
        .where(
          (item) => item.ItemType == Interface_Enums.ItemType.SwingFlexDoor,
        )
        .orderBy((item) => item.frontZ)
        .toArray();
    }
    return this.cache.swingFlexDoorItems;
  }

  public get sectionHeight(): number {
    return this.cabinetSection.Height || 0;
  }

  public get subAreaOffsetY(): number {
    let spaceForCorpusOrFeet = this.cabinetSection.corpus.heightBottom;
    if (spaceForCorpusOrFeet <= 0)
      spaceForCorpusOrFeet =
        this.cabinetSection.swingFlex.productLineProperties
          .SwingFlexBottomOffset;
    const plinthHeight = this.cabinetSection.swingFlex.plinth
      ? this.cabinetSection.swingFlex.getPlinthHeight()
      : 0;
    const bottomShelfHeight =
      this.cabinetSection.swingFlex.getCorpusShelfHeight();
    return (
      this.cabinetSection.Height -
      (spaceForCorpusOrFeet + plinthHeight + bottomShelfHeight)
    );
  }

  public isSwingSubAreaSelected(swingSubArea: SwingFlexSubArea): boolean {
    return this.selectedSwingSubArea === swingSubArea;
  }

  public get rulers(): Client.Ruler[] {
    if (!this.cache.rulers) {
      if (this.cabinetSection.isSwing) {
        this.cache.rulers = Client.Ruler.GetSwingRulers(this.cabinetSection);
      } else if (this.cabinetSection.isSwingFlex) {
        this.cache.rulers = Client.Ruler.GetSwingFlexRulers(
          this.cabinetSection,
        );
      } else {
        let rulersDict = Client.Ruler.GetRulers(this.cabinetSection);

        let rulers = [
          rulersDict.cabinetWidthRuler,
          rulersDict.interiorWidthRuler,
          rulersDict.cabinetHeightRuler,
          rulersDict.interiorHeightRuler,
        ];

        if (rulersDict.leftNeighborInteriorBoundsRuler.length > 0) {
          rulers.push(rulersDict.leftNeighborInteriorBoundsRuler);
        }
        if (rulersDict.leftNeighborCabinetBoundsRuler.length > 0) {
          rulers.push(rulersDict.leftNeighborCabinetBoundsRuler);
        }

        if (rulersDict.rightNeighborInteriorBoundsRuler.length > 0) {
          rulers.push(rulersDict.rightNeighborInteriorBoundsRuler);
        }
        if (rulersDict.rightNeighborCabinetBoundsRuler.length > 0) {
          rulers.push(rulersDict.rightNeighborCabinetBoundsRuler);
        }
        this.cache.rulers = rulers;
      }
    }
    return this.cache.rulers;
  }

  public get freespaces(): Interface_DTO_Draw.Rectangle[] {
    if (!this.cache.freespaces) {
      let freespaces: Interface_DTO_Draw.Rectangle[] = [];
      if (this.cabinetSection.InteriorFreeSpaceLeft > 0) {
        freespaces.push({
          X:
            this.cabinetSection.interior.cube.X -
            this.cabinetSection.InteriorFreeSpaceLeft,
          Y: 0,
          Width: this.cabinetSection.InteriorFreeSpaceLeft,
          Height: this.cabinetSection.Height,
        });
      }
      if (this.cabinetSection.InteriorFreeSpaceRight > 0) {
        freespaces.push({
          X: this.cabinetSection.interior.cubeRightX,
          Y: 0,
          Width: this.cabinetSection.InteriorFreeSpaceRight,
          Height: this.cabinetSection.Height,
        });
      }
      this.cache.freespaces = freespaces;
    }
    return this.cache.freespaces;
  }

  public get swingDoors(): SwingImageVm.Door[] {
    if (!this.cache.swingDoors) {
      this.cache.swingDoors = this.cabinetSection.isSwing
        ? SwingImageVm.createDoors(this.cabinetSection.swing.areas)
        : [];
    }
    return this.cache.swingDoors;
  }

  public get hinges() {
    if (!this.cache.hinges) {
      this.cache.hinges = SwingImageVm.createHinges(this.cabinetSection);
    }
    return this.cache.hinges;
  }

  public get frontViewTransformString(): string {
    return (
      'translate(' +
      Interior2dVm.canvasMargin +
      ', ' +
      Interior2dVm.canvasMargin +
      ')'
    );
  }

  public getNeighborItemTransform(item: Interior2dVm.NeighborItem): string {
    const x = item.X;
    const y = this.cabinetSection.Height - (item.Y + item.Height);

    const transformString = ' translate( ' + x + ' , ' + y + ' ) ';
    return transformString;
  }

  public getBackingFittingPanelSpacerCubes(): Cube[] {
    const fittingPanelSpacerCubes =
      this.cabinetSection.backing.fittingCubes.filter((c) =>
        this.backingIsFittingPanelSpacer(c, this.cabinetSection),
      );

    return fittingPanelSpacerCubes;
  }

  public getFittingPanelFillStyle(fitting: Cube): string | null {
    let backingFittingSpacer = this.backingIsFittingPanelSpacer(
      fitting,
      this.cabinetSection,
    );

    return backingFittingSpacer?.Material?.ImagePath ?? null;
  }

  public getBackingFittingPanelTransformString(item: Cube): string {
    let result = '';
    let x = item.X;
    let y = this.cabinetSection.Height - (item.Height + item.Y);

    result += ' translate( ' + x + ' , ' + y + ' ) ';
    return result;
  }

  public getHingeTransformString(hinge: SwingFlexComponent.Hinge): string {
    let y = this.cabinetSection.Height - (2 * hinge.y + hinge.height);
    return ' translate( 0 , ' + y + ' ) ';
  }

  public getItemRulerString(item: Client.ConfigurationItem): string {
    let result = '' + item.PositionNumber;

    const addSnapPosition =
      item.ItemType !== Interface_Enums.ItemType.SwingFlexDoor &&
      item.ItemType !== Interface_Enums.ItemType.SwingFlexBacking &&
      item.ItemType !== Interface_Enums.ItemType.SwingFlexCorpus;

    if (addSnapPosition) {
      result += ' - ' + item.snappedHoleY.toFixed(0);
    }

    return result;
  }

  public getItemRulerBackgroundWidth(item: Client.ConfigurationItem): number {
    const useWide =
      item.ItemType !== Interface_Enums.ItemType.SwingFlexDoor &&
      item.ItemType !== Interface_Enums.ItemType.SwingFlexBacking &&
      item.ItemType !== Interface_Enums.ItemType.SwingFlexCorpus;

    return useWide
      ? this.itemRulerBackgroundWidthWide
      : this.itemRulerBackgroundWidthNarrow;
  }

  public shouldDisplayRulerFor(item: Client.ConfigurationItem): boolean {
    if (!this.showRulers) {
      return false;
    }
    if (item.ItemType === Interface_Enums.ItemType.SwingFlexBacking) {
      return false;
    }

    return this.displayItem(item);
  }

  public svgHeight(domainHeight: number): number {
    return this.cabinetSection.Height - domainHeight;
  }

  public getItemFillStyle(item: Client.ConfigurationItem): string {
    if (item.Material && item.Material.ImagePath) {
      return "url('" + item.Material.ImagePath + "')";
    } else return 'none';
  }

  // } #endregion svg-specific strings

  // { #region dragdrop

  public displayItem(item: Client.ConfigurationItem): boolean {
    return this.showDummyItems || !item.isDummy;
  }

  public backgroundMouseDown(evt: MouseEvent | TouchEvent) {
    if (this.dragInfo) {
      //user is already dragging something
      return;
    }
    let pos = this.getDomainPosition(evt);
    this.dragInfo = {
      type: 'RectangleSelectInfo',
      isStarted: false,
      mouseStartPos: pos,
      topLeft: pos,
      bottomRight: pos,
      size: { X: 0, Y: 0 },
    };
    evt.preventDefault();
  }

  public itemMouseDown(
    evt: MouseEvent | TouchEvent,
    item: Client.ConfigurationItem,
  ) {
    if (App.debug.showTouchMessages) {
      console.debug('itemMouseDown', evt, item);
    }
    if (this.dragInfo) {
      //user is already dragging something
      return;
    }

    let dragItems: Client.ConfigurationItem[] = this.isItemSelected(item)
      ? this.selectedItems.value
      : Interior2dVm.selectItems(item, this.selectedItems.value, evt.ctrlKey);

    this.selectedItems.value = dragItems;

    if (dragItems.length > 0) {
      let domainPos = this.getDomainPosition(evt);
      let snapInfoService = this.interiorService.getSnapInfoService(
        this.cabinetSection,
        dragItems as [Client.ConfigurationItem],
        domainPos,
      );
      let snapInfo = snapInfoService.getSnapInfo(domainPos);
      let dragInfo: Interior2dVm.ItemDragInfo = {
        type: 'ItemDragInfo',
        mouseStartPos: this.getPos(evt),
        offset: this.getDomainPosition(evt),
        dragItems: dragItems as [Client.ConfigurationItem],
        dragStarted: false,
        snapInfoService: snapInfoService,
        snapInfo: snapInfo,
      };
      this.dragInfo = dragInfo;
    }

    evt.preventDefault();
    evt.stopPropagation();
  }

  public neighborItemMouseDown(
    evt: MouseEvent | TouchEvent,
    neighborItemInfo: Interior2dVm.NeighborItem,
  ) {
    if (this.dragInfo) {
      //user is already dragging something
      return;
    }

    this.dragInfo = {
      type: 'NeighborItemDragPrepareInfo',
      item: neighborItemInfo,
      mouseStartPos: this.getDomainPosition(evt),
    };
  }

  public mouseUp(evt: MouseEvent | TouchEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragInfo2.value = undefined;
    if (!this.dragInfo) {
      //user did a mouseDown somewhere else and a mouseUp here - do nothing
    } else if (this.dragInfo.type === 'RectangleSelectInfo') {
      if (this.dragInfo.isStarted) {
        let selectedItems = this.selectItemsInRectangle(
          this.dragInfo,
          evt.ctrlKey,
        );
        this.selectedItems.value = selectedItems;
      } else {
        this.selectedItems.value = [];
      }
      evt.stopPropagation();
    } else if (this.dragInfo.type === 'ItemDragInfo') {
      //user dragged items around
      if (!this.dragInfo.dragStarted) {
        this.dragInfo = undefined;
      } else {
        let placeInfo = this.dragInfo.snapInfoService.place();
        let placedItems = placeInfo.items;

        let selectedItems = placedItems.some((pi) => pi.isTemplate)
          ? placedItems.filter((pi) => pi.isTemplate)
          : placedItems;

        this.notificationService.displayRecalulationMessages(
          placeInfo.recalculationMessages,
        );
        this.selectedItems.value = selectedItems;

        this.setChanged();
      }
    } else if (this.dragInfo.type === 'NeighborItemDragPrepareInfo') {
      //user clicked on an item in a neighboring section
      this.selectedItems.value = [this.dragInfo.item.item];
    } else if (this.dragInfo.type === 'NeighborItemDragInfo') {
      //User has been dragging an item in a neighboring section.
      this.dragInfo.snapInfoService.place();
      this.setChanged();
    } else {
      console.error(
        "I don't know what to do about this dragInfo: ",
        this.dragInfo,
      );
    }

    this.dragInfo = undefined;
  }

  public mouseMove(evt: MouseEvent | TouchEvent) {
    if (!this.dragInfo) {
      let dragData = this.dragInfo2.value;
      if (dragData) {
        //this.dragInfo2.value = undefined;

        //user is (as fas as we can tell) dragging in a product from productList or similar, set dragInfo
        let items: [Client.ConfigurationItem];
        if (dragData.item) {
          items = this.configurationItemService.createInteriorItemFromOtherItem(
            dragData.item,
            this.cabinetSection,
          ).items;
        } else {
          items = this.configurationItemService.createInteriorItem(
            dragData.productId,
            dragData.materialId || null,
            this.cabinetSection,
          ).items;
        }
        let mainItem = items[0];

        let pos = this.getDomainPosition(evt);
        mainItem.X = 0;
        mainItem.Y = 0;

        let snapService = this.interiorService.getSnapInfoService(
          this.cabinetSection,
          [mainItem],
          mainItem,
        );

        let dragInfo: Interior2dVm.ItemDragInfo = {
          type: 'ItemDragInfo',
          mouseStartPos: pos,
          offset: { X: mainItem.X / 2, Y: mainItem.Y / 2 },
          snapInfoService: snapService,
          snapInfo: snapService.getSnapInfo(pos),
          dragItems: [mainItem],
          dragStarted: false,
        };
        this.dragInfo = dragInfo;
        this.selectedItems.value = [mainItem];
      }
    }

    if (!this.dragInfo) {
      return;
    }

    evt.preventDefault(); //prevent browser from selecting text etc
    let mousePos = this.getDomainPosition(evt);
    if (this.dragInfo.type === 'RectangleSelectInfo') {
      this.dragInfo.topLeft = {
        X: Math.min(mousePos.X, this.dragInfo.mouseStartPos.X),
        Y: Math.max(mousePos.Y, this.dragInfo.mouseStartPos.Y),
      };
      this.dragInfo.bottomRight = {
        X: Math.max(mousePos.X, this.dragInfo.mouseStartPos.X),
        Y: Math.min(mousePos.Y, this.dragInfo.mouseStartPos.Y),
      };
      if (!this.dragInfo.isStarted) {
        let startDrag =
          VectorHelper.dist(this.dragInfo.topLeft, this.dragInfo.bottomRight) >
          Interior2dVm.initailMinimumDragDist;
        if (startDrag) {
          this.dragInfo.isStarted = true;
        }
      }
      this.dragInfo.size = {
        X: Math.abs(this.dragInfo.topLeft.X - this.dragInfo.bottomRight.X),
        Y: Math.abs(this.dragInfo.topLeft.Y - this.dragInfo.bottomRight.Y),
      };
    } else if (this.dragInfo.type === 'ItemDragInfo') {
      if (!this.dragInfo.dragStarted) {
        let currentPos = this.getPos(evt);
        let dist = VectorHelper.dist(currentPos, this.dragInfo.mouseStartPos);
        this.dragInfo.dragStarted = dist >= Interior2dVm.initailMinimumDragDist;
      }

      if (this.dragInfo.dragStarted) {
        this.dragInfo.snapInfo =
          this.dragInfo.snapInfoService.getSnapInfo(mousePos);
      }
    } else if (this.dragInfo.type === 'NeighborItemDragPrepareInfo') {
      let dist = VectorHelper.dist(mousePos, this.dragInfo.mouseStartPos);
      let startDrag = dist >= Interior2dVm.initailMinimumDragDist;

      if (startDrag && this.dragInfo.item.isMovable) {
        let domainPos = this.getDomainPosition(evt);

        let mouseItemOffset = VectorHelper.subtract(
          this.dragInfo.mouseStartPos,
          this.dragInfo.item,
        );
        let snapInfoService = this.interiorService.getSnapInfoService(
          this.dragInfo.item.item.cabinetSection,
          [this.dragInfo.item.item],
          domainPos,
        );

        let snapInfo = snapInfoService.getSnapInfo(domainPos);
        let dragInfo: Interior2dVm.NeighborItemDragInfo = {
          type: 'NeighborItemDragInfo',
          item: this.dragInfo.item,
          mouseItemOffset: mouseItemOffset,
          snapInfoService: snapInfoService,
          snapInfo: snapInfo,
        };
        this.dragInfo = dragInfo;
        this.selectedItems.value = [this.dragInfo.item.item];
      }
    } else if (this.dragInfo.type === 'NeighborItemDragInfo') {
      let actualDomainPos = this.getDomainPosition(evt);
      let domainPos = ObjectHelper.copy(actualDomainPos);
      domainPos.X = this.dragInfo.item.item.X;
      this.dragInfo.snapInfo =
        this.dragInfo.snapInfoService.getSnapInfo(domainPos);
      this.dragInfo.snapInfo.rulers = this.dragInfo.snapInfo.rulers.filter(
        (ruler) => ruler.isVertical,
      );
      this.dragInfo.snapInfo.collisionAreas = [];
      for (let ruler of this.dragInfo.snapInfo.rulers) {
        ruler.start.X = actualDomainPos.X;
      }
    }
  }

  // } #endregion dragdrop

  // region touch dragdrop

  private handleDragInfo(dragInfo?: Client.ProductDragInfo) {
    if (
      dragInfo &&
      dragInfo.touchEventObservable &&
      dragInfo.touchEventObservable.value
    ) {
      //touch event is starting
      let lastEvent: TouchEvent | undefined;
      //this.mouseMove(dragInfo.touchEventObservable.value);
      this.touchEventSubscription = dragInfo.touchEventObservable.observe(
        (touchEvent) => {
          if (!touchEvent) {
            this.touchEventSubscription = undefined;
            if (lastEvent) this.mouseUp(lastEvent);
          } else {
            let touchPos = this.getDomainPosition(touchEvent);
            if (touchPos.X > 0 && touchPos.X < this.cabinetSection.Width) {
              //quick check to see if touch is inside canvas bounds
              lastEvent = touchEvent;
              this.mouseMove(touchEvent);
            }
          }
        },
      );
    } else {
      //touch drag event is ending
      this.touchEventSubscription = undefined;
    }
  }

  private _touchEventSubscription: ISubscription | undefined;
  private set touchEventSubscription(val: ISubscription | undefined) {
    if (this._touchEventSubscription) {
      this._touchEventSubscription.dispose();
    }
    this._touchEventSubscription = val;
  }

  //endregion touch-dragdrop

  private selectItemsInRectangle(
    dragInfo: Interior2dVm.RectangleSelectInfo,
    toggleSelectedItems: boolean,
  ): Client.ConfigurationItem[] {
    let selectionRectangle: Interface_DTO_Draw.Rectangle = {
      X: dragInfo.topLeft.X,
      Y: dragInfo.bottomRight.Y,
      Width: dragInfo.size.X,
      Height: dragInfo.size.Y,
    };
    let selectedItems: Client.ConfigurationItem[] =
      this.cabinetSection.interior.items.filter((item) =>
        VectorHelper.overlaps(selectionRectangle, item),
      );

    //select module parents
    selectedItems = selectedItems.map((i) => i.moduleBaseItem);

    return selectedItems;
  }

  private static selectItems(
    clickedItem: Client.ConfigurationItem,
    selectedItems: Client.ConfigurationItem[],
    addToExistingSelection: boolean,
  ): Client.ConfigurationItem[] {
    clickedItem = clickedItem.moduleBaseItem;
    if (addToExistingSelection) {
      //Ctrl key is down - toggle selection of item
      let newItems = selectedItems.slice(); //copy array
      let index = newItems.indexOf(clickedItem);

      if (index < 0) {
        //add item to list of selected items
        newItems.push(clickedItem);
      } else {
        //remove item from list of selectedItems
        newItems.splice(index, 1);
      }
      return newItems;
    } else {
      return [clickedItem];
    }
  }

  // #region corpus stuff

  public get corpusItems(): Client.ConfigurationItem[] {
    if (!this.cache.corpusItems) {
      this.cache.corpusItems = this.cabinetSection.corpus.allCorpusItems;
    }
    return this.cache.corpusItems;
  }

  public get corpusJoints(): Interior2dVm.Joint[] {
    if (!this.cache.corpusJoints) {
      let topJoints = this.cabinetSection.corpus.jointsTop.map((j) => {
        let height = Math.max(
          0,
          ...this.cabinetSection.corpus.itemsTop.map((i) => i.Height),
        );
        return <Interior2dVm.Joint>{
          X: j + this.cabinetSection.corpus.topDeductionLeft,
          Y: this.cabinetSection.Height - height,
          height: height,
        };
      });
      let bottomJoints = this.cabinetSection.corpus.jointsBottom.map((j) => {
        let height = Math.max(
          0,
          ...this.cabinetSection.corpus.itemsBottom.map((i) => i.Height),
        );
        return <Interior2dVm.Joint>{
          X: j + this.cabinetSection.corpus.bottomDeductionLeft,
          Y: 0,
          height: height,
        };
      });
      this.cache.corpusJoints = topJoints.concat(...bottomJoints);
    }
    return this.cache.corpusJoints;
  }

  // #endregion corpus stuff }

  //#region Door stuff

  public get doors() {
    return this.cabinetSection ? this.cabinetSection.doors.doors : [];
  }

  public get topDownPosX() {
    return 0;
  }
  public get topDownPosY() {
    return (
      this.cabinetSection.Height +
      Interior2dVm.topDownPadding -
      this.cabinetSection.interior.cube.Depth -
      this.cabinetSection.interior.cube.Z
    );
  }

  //#endregion Door stuff

  // { #region rulers

  public get sightHeightRulerOffsetX() {
    return -(Constants.rulerWidth / 2 + this.cabinetSection.ExtraRailWidthLeft);
  }
  public get sightHeightRulerOffsetY() {
    let result =
      this.cabinetHeight -
      this.cabinetSection.SightHeight -
      this.cabinetSection.sightOffsetY;
    return result;
  }

  public get cabinetHeightRulerOffsetX() {
    return -(
      (Constants.rulerWidth * 3) / 2 +
      this.cabinetSection.ExtraRailWidthLeft
    );
  }
  public get cabinetHeightRulerOffsetY() {
    return 0;
  }

  public get sightWidthRulerOffsetX() {
    return this.cabinetSection.sightOffsetX;
  }
  public get sightWidthRulerOffsetY() {
    return -Constants.rulerWidth / 2;
  }

  public get cabinetWidthRulerOffsetX() {
    return 0;
  }
  public get cabinetWidthRulerOffsetY() {
    return (-Constants.rulerWidth * 3) / 2;
  }

  public readonly itemRulerBackgroundWidth = 200;
  public readonly itemRulerBackgroundHeight = 70;

  public get sightHeight() {
    return this.cabinetSection.SightHeight;
  }
  public get cabinetHeight() {
    return this.cabinetSection.Height;
  }

  public get sightWidth() {
    return CabinetSectionHelper.getSightWidth(this.cabinetSection);
  }
  public get cabinetWidth() {
    return this.cabinetSection.Width;
  }

  public get sectionRulers(): Client.Ruler[] {
    if (!this.cache.sectionRulers) {
      this.cache.sectionRulers = Client.Ruler.GetColumnRulers(
        this.cabinetSection,
      );
    }
    return this.cache.sectionRulers;
  }

  public get showSectionRulers(): boolean {
    return !this.cabinetSection.isSwing;
  }

  // } #endregion rulers

  // #region neighbors {

  public get leftNeighborInfo(): Interior2dVm.NeighborInfo | null {
    if (this.cache.leftNeighborInfo === undefined) {
      const neighbor = this.cabinetSection.leftNeighbor;
      const corner = this.cabinetSection.interior.leftCorner;
      if (!corner || !neighbor) {
        this.cache.leftNeighborInfo = null;
      } else {
        const itemMinX = neighbor.Width - this.cabinetSection.InteriorDepth;

        const items: Interior2dVm.NeighborItem[] = neighbor.interior.items
          .filter((item) => !item.isGable && item.rightX > itemMinX)
          .sort((i1, i2) => i1.X + i1.Width - (i2.X + i2.Width))
          .map((item) => {
            const result: Interior2dVm.NeighborItem = {
              item: item,
              X: item.Z,
              Y: item.Y,
              Width: item.hasWidth2 ? item.width2 : item.Depth,
              Height: item.Height,
              isMovable:
                item.snapLeftAndRight || item.snapLeft || item.snapRight,
            };
            return result;
          });
        const firstGable = this.cabinetSection.interior.gables[0];

        this.cache.leftNeighborInfo = {
          items: items,
          cabinetBounds: {
            X: this.cabinetSection.interior.cube.X,
            Y: this.cabinetSection.interior.cube.Y,
            Width: firstGable ? firstGable.X : corner.acceptableSpace,
            Height: this.cabinetSection.interior.cube.Height,
          },
          interiorBounds: {
            X: this.cabinetSection.interior.cube.X,
            Y: this.cabinetSection.interior.cube.Y,
            Width: corner.minimumSpace,
            Height: this.cabinetSection.interior.cube.Height,
          },
          isMirrored: false,
          offset: 0,
          side: 'left',
        };
      }
    }
    return this.cache.leftNeighborInfo;
  }

  public get rightNeighborInfo(): Interior2dVm.NeighborInfo | null {
    if (this.cache.rightNeighborInfo === undefined) {
      const neighbor = this.cabinetSection.rightNeighbor;
      const corner = this.cabinetSection.interior.rightCorner;
      if (!corner || !neighbor) {
        this.cache.rightNeighborInfo = null;
      } else {
        const itemMaxX = this.cabinetSection.InteriorDepth;
        const items = neighbor.interior.items
          .filter((item) => !item.isGable && item.X < itemMaxX)
          .sort((i1, i2) => i2.X + i2.Width - (i1.X + i1.Width))
          .map((item) => {
            const result: Interior2dVm.NeighborItem = {
              item: item,
              X: item.Z,
              Y: item.Y,
              Width: item.hasWidth2 ? item.width2 : item.Depth,
              Height: item.Height,
              isMovable:
                item.snapLeftAndRight || item.snapLeft || item.snapRight,
            };
            return result;
          });

        const lastGable =
          this.cabinetSection.interior.gables[
            this.cabinetSection.interior.gables.length - 1
          ];
        this.cache.rightNeighborInfo = {
          items: items,
          interiorBounds: {
            X: 0,
            Y: this.cabinetSection.interior.cube.Y,
            Width: corner.minimumSpace,
            Height: this.cabinetSection.interior.cube.Height,
          },
          cabinetBounds: {
            X: 0,
            Y: this.cabinetSection.interior.cube.Y,
            Width: lastGable
              ? this.cabinetSection.Width - lastGable.rightX
              : corner.acceptableSpace,
            Height: this.cabinetSection.interior.cube.Height,
          },
          offset:
            this.cabinetSection.Width -
            CabinetSectionHelper.interiorOffset(
              this.cabinetSection,
              Interface_Enums.Direction.Right,
            ),
          isMirrored: true,
          side: 'right',
        };
      }
    }
    return this.cache.rightNeighborInfo;
  }

  public get neighbors(): Interior2dVm.NeighborInfo[] {
    if (!this.cache.neighbors) {
      this.cache.neighbors = [];
      if (this.leftNeighborInfo)
        this.cache.neighbors.push(this.leftNeighborInfo);
      if (this.rightNeighborInfo)
        this.cache.neighbors.push(this.rightNeighborInfo);
    }
    return this.cache.neighbors;
  }

  // } #endregion neighbors

  public changeGableHeight(
    evt: MouseEvent | TouchEvent,
    item: Client.ConfigurationItem,
    count: number,
  ): void {
    evt.stopPropagation();
    if (!item.Product) return;

    let updateHeightReduction = !item.isHeightReductionPossible();
    //find the item's product category and select the next item in the list of products
    let productCategory = this.findProductCategory(
      item.Product,
      this.cabinetSection.interior.availableProductCategories,
    );
    if (!productCategory) return;

    let products = productCategory.products.filter((p) => !p.overrideChain);

    let productIndex = products.indexOf(item.Product);
    if (productIndex < 0) {
      productIndex = 0;
    }

    productIndex += count;
    productIndex =
      ((productIndex % products.length) + products.length) % products.length;

    item.Product = products[productIndex];
    item.Height = this.cabinetSection.interior.cube.Height;

    if (updateHeightReduction && item.isHeightReductionPossible())
      item.HeightReduction = this.cabinetSection.interior.heightReduction;

    this.setChanged();
  }

  private findProductCategory(
    product: Client.Product,
    categories: Client.ProductCategory[],
  ): Client.ProductCategory | null {
    for (let pg of categories) {
      if (pg.products.indexOf(product) >= 0) {
        return pg;
      }
      let recursive = this.findProductCategory(product, pg.Children);
      if (recursive) return recursive;
    }
    return null;
  }

  public isItemSelected(item: Client.ConfigurationItem): boolean {
    return this.selectedItems.value.indexOf(item) > -1;
  }

  public getItemStyle(item: Client.ConfigurationItem): string {
    let style = 'pointer-events: none; ';
    if (this.showWarnings) {
      switch (item.maxErrorLevel) {
        case Interface_Enums.ErrorLevel.Info:
          style += 'fill: rgba(0, 0, 255, 0.3);';
          break;
        case Interface_Enums.ErrorLevel.Warning:
          style += 'fill: rgba(255, 165, 0, 0.3);';
          break;
        case Interface_Enums.ErrorLevel.Critical:
          style += 'fill: rgba(255, 0, 0, 0.5);';
          break;
        default:
          style += 'fill: rgba(0, 0, 0, 0);';
          break;
      }
    } else {
      style += 'fill: rgba(0, 0, 0, 0);';
    }

    return style;
  }

  public backgroundClick(evt: MouseEvent | TouchEvent) {
    evt.stopPropagation();
    this.selectedItems.value = [];
  }

  private async setChanged() {
    await this.floorPlanService.setChanged(
      this.cabinetSection.cabinet.floorPlan,
    );
  }

  private getDomainPosition(
    evt: MouseEvent | TouchEvent,
  ): Interface_DTO_Draw.Vec2d {
    let clientX: number;
    let clientY: number;
    if (evt instanceof MouseEvent) {
      clientX = evt.clientX;
      clientY = evt.clientY;
    } else if (evt instanceof TouchEvent) {
      clientX = evt.changedTouches[0].clientX;
      clientY = evt.changedTouches[0].clientY;
    } else {
      throw new Error('unknown event type');
    }

    this.svgMousePoint.x = clientX;
    this.svgMousePoint.y = clientY;
    let pt = this.svgMousePoint.matrixTransform(
      this.svgCanvas.getScreenCTM().inverse(),
    );
    let result = {
      X: pt.x,
      Y: this.cabinetSection.Height - pt.y,
    };

    return result;
  }

  private getPos(event: MouseEvent | TouchEvent): Interface_DTO_Draw.Vec2d {
    if (event instanceof MouseEvent) {
      return {
        X: event.clientX,
        Y: event.clientY,
      };
    } else {
      return {
        X: event.changedTouches[0].clientX,
        Y: event.changedTouches[0].clientY,
      };
    }
  }

  public roundUp(n: number): number {
    return Math.ceil(n);
  }

  public override dispose() {
    super.dispose();
    this.touchEventSubscription = undefined;
  }

  public trackByIndex(index: number, element: any) {
    const t =
      'translate( {{ joint.X }} {{ cabinetSection.Height - joint.Y }} )';
    return index;
  }

  private backingIsFittingPanelSpacer(
    fitting: Interface_DTO_Draw.Cube,
    section: Client.CabinetSection,
  ): Client.ConfigurationItem | undefined {
    let gableFittingPanelSpacer = Enumerable.from(
      section.interior.gables,
    ).firstOrDefault(
      (g) =>
        g.isFittingPanel &&
        (g.X === fitting.X + fitting.Width || g.X === fitting.X - g.Width) &&
        g.Y === fitting.Y,
    );

    return gableFittingPanelSpacer;
  }
}

export module Interior2dVm {
  export interface RectangleSelectInfo {
    type: 'RectangleSelectInfo';
    mouseStartPos: Interface_DTO_Draw.Vec2d;
    isStarted: boolean;
    topLeft: Interface_DTO_Draw.Vec2d;
    bottomRight: Interface_DTO_Draw.Vec2d;
    size: Interface_DTO_Draw.Vec2d;
  }

  export interface ItemDragInfo {
    type: 'ItemDragInfo';
    offset: Interface_DTO_Draw.Vec2d;
    mouseStartPos: Interface_DTO_Draw.Vec2d;
    snapInfoService: ISnapInfoService;
    snapInfo: Client.SnapInfo;
    dragItems: [Client.ConfigurationItem];
    dragStarted: boolean;
  }

  export interface NeighborItemDragPrepareInfo {
    type: 'NeighborItemDragPrepareInfo';
    item: NeighborItem;
    mouseStartPos: Interface_DTO_Draw.Vec2d;
  }

  /**
   * For dragging an item in a neighboring section
   * */
  export interface NeighborItemDragInfo {
    type: 'NeighborItemDragInfo';
    item: NeighborItem;
    mouseItemOffset: Interface_DTO_Draw.Vec2d;
    snapInfoService: ISnapInfoService;
    snapInfo: Client.SnapInfo;
  }

  export interface NeighborInfo {
    interiorBounds: Interface_DTO_Draw.Rectangle;
    cabinetBounds: Interface_DTO_Draw.Rectangle;
    items: Interior2dVm.NeighborItem[];
    offset: number;
    isMirrored: boolean;
    side: 'left' | 'right';
  }

  export interface NeighborItem extends Interface_DTO_Draw.Rectangle {
    item: Client.ConfigurationItem;
    isMovable: boolean;
  }

  export interface Joint extends Interface_DTO_Draw.Vec2d {
    height: number;
  }
}
