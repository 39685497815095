import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { RequestConfig } from 'app/backend-service/RequestConfig';
import {
  ActiveUser,
  ActiveUserInjector,
  activeUserProvider,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import {
  ClientSetting,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import {
  UseFullCatalogInjector,
  UseFullCatalog,
} from 'app/functional-core/ambient/clientSetting/UseFullCatalog';
import { activeStoreProvider } from 'app/functional-core/ambient/stores/ActiveStore';
import { ApplicationState } from 'app/functional-core/ApplicationState';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { PermissionService } from 'app/identity-and-access/permission.service';
import { NavigateService } from 'app/routing/navigate-service';
import { ScreenshotService } from 'app/screenshooting/screenshot.service';
import * as App from 'app/ts/app';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import * as Exception from 'app/ts/exceptions';
import { DtoContainer } from 'app/ts/interfaces/DtoContainer';
import { TableColumn } from 'app/ts/interfaces/TableColumn';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as DTO from 'app/ts/interface_dto/index';
import * as Interface_DTO_DomainError from 'app/ts/Interface_DTO_DomainError';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { AssetService } from 'app/ts/services/AssetService';
import { CustomerBaseVmService } from 'app/ts/services/CustomerBaseVmService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { FormattingService } from 'app/ts/services/FormattingService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { OrderService } from 'app/ts/services/OrderService';
import { PieceListService } from 'app/ts/services/PieceListService';
import { RouteService } from 'app/ts/services/RouteService';
import { ITranslationService } from 'app/ts/services/TranslationService';
import { Collapsible } from 'app/ts/util/Collapsible';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ISelectable } from 'app/ts/util/ISelectable';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { CustomerBaseVm } from 'app/ts/viewmodels/CustomerBaseVm';
import Enumerable from 'linq';
import { first } from 'rxjs';
import { NavigationService } from '../services/NavigationService';
import { UserMessageService } from 'app/user-messages/user-message.service';
import { ModalService } from '../services/ModalService';

@Component({
  selector: 'floorplans',
  templateUrl: 'floorplans.html',
  styleUrls: [
    '../../../style/briefStyling.scss',
    '../../../style/overviews.scss',
    '../../../style/IE-hacks.scss',
    '../../../style/orderStatus.scss',
    '../../../style/floorplans.scss',
    '../../../style/rightMenu.scss',
    '../../../style/tables.scss',
    '../../../style/nav.scss',
  ],
  providers: [
    activeStoreProvider,
    clientSettingProvider,
    activeUserProvider,
    PieceListService,
    ScreenshotService,
  ],
})
export class FloorPlansVm extends CustomerBaseVm<Interface_DTO.FloorPlanOverview> {
  private static readonly numCustomersPerPage = 25;

  private _pageNum = 1;
  public numPages: number = 1;
  public unpaginatedCustomers: FloorPlansVm.Customer[] = [];

  //The customers that should be displayed on screen, after pagination
  public customers: FloorPlansVm.Customer[] = [];

  public get columns() {
    return this.getColumns();
  }

  public objects: Client.FloorPlanOverview[] = [];

  public readonly floorPlanIdPrefix = 'floorplan';
  public isCopyingInProgress: boolean = false;
  public isMovingInProgress: boolean = false;
  public numCopies = 1;
  public selectedColumnNames: string[] = [];
  public timestamp: string = new Date().valueOf().toString();
  public detailTabNumber = 0;

  private isFullCatalogActivated: boolean = false;

  public userMessages: Interface_DTO.UserMessage[] = [];

  public get showBackButtons(): boolean {
    return this.clientSettingsValue?.showExtraOverviewTabs;
  }

  public get applyFiltersOnMove(): boolean {
    return this.clientSettingsValue?.showExtraOverviewTabs;
  }

  public get showEditButtons(): boolean {
    return !this.clientSettingsValue?.showExtraOverviewTabs;
  }

  public get isInitialized(): boolean {
    return (
      this.data.ambient.applicationState ==
      ApplicationState.FinishedInitializing
    );
  }

  //Discriminated type union
  public selection:
    | { type: 'customer'; customer: DTO.Customer }
    | { type: 'project'; project: DTO.Project }
    | { type: 'deliveryAddress'; deliveryAddress: Client.DeliveryAddress }
    | { type: 'none' } = { type: 'none' };

  constructor(
    customerBaseVmService: CustomerBaseVmService,
    @Inject(ClientSettingInjector) clientSettings: ClientSetting,
    @Inject(ActiveUserInjector) activeUser: ActiveUser,
    @Inject(UseFullCatalogInjector) private useFullCatalog: UseFullCatalog,
    private httpClient: PromisingBackendService,
    router: Router,

    activatedRoute: ActivatedRoute,
    private navigate: NavigateService,
    private assetService: AssetService,
    private readonly floorPlanService: FloorPlanService,
    private readonly permissions: PermissionService,
    private readonly notificationService: NotificationService,
    private readonly pieceListService: PieceListService,
    private readonly routeService: RouteService,
    private readonly orderService: OrderService,
    private readonly formattingService: FormattingService,
    private readonly screenshotService: ScreenshotService,
    private readonly navigationService: NavigationService,
    private readonly data: FunctionalCoreService,
    private readonly userMessageService: UserMessageService,
  ) {
    super(
      customerBaseVmService,
      router,
      activatedRoute,
      activeUser,
      clientSettings,
    );

    // TODO: Figure out some way to get sales types injected directly
    // Resolvers in routing does not help us, it seems.
    this.subscribeTo(
      this.assetService.salesTypes$.pipe(first((val) => val.length > 0)),
      (sts) => {
        this.salesTypes = {};
        for (let st of sts) {
          if (!st.Enabled) continue;
          this.salesTypes[st.Number] = st;
        }
      },
    );

    useFullCatalog.subscribe((val) => (this.isFullCatalogActivated = val));

    userMessageService.userMessages$.subscribe((messages) => {
      this.userMessages = messages;
    });
    //userMessageService.loadUserMessages();
  }

  @Input()
  protected setPageNum(value: number) {
    this.pageNum = value;
    this.updateQueryParams();
    this.search();
  }

  private getSelectedColumnNames() {
    const explicitlySelectedColumnNames =
      this.clientSettingsValue.columnsFloorPlan;
    const implicitlySelectedColumnNames = [];
    const possibleColumns = this.getColumns();
    for (const [name, col] of Object.entries(possibleColumns)) {
      if (col.removable) continue;
      if (explicitlySelectedColumnNames.includes(name)) continue;

      implicitlySelectedColumnNames.push(name);
    }
    return explicitlySelectedColumnNames.concat(implicitlySelectedColumnNames);
  }

  protected override onSearchUpdated(customers: Client.Customer[]): void {
    this.searchUpdated(customers, this.getSelectedColumnNames());
  }

  private searchUpdated(customers: Client.Customer[], columnNames: string[]) {
    let columns = columnNames.map((name) => {
      return {
        ...this.columns[name],
        name: name,
      };
    });
    this.selectedColumnNames = columnNames;
    this.objects = Enumerable.from(customers)
      .selectMany((cust) => cust.Projects)
      .selectMany((proj) => proj.DeliveryAddresses)
      .selectMany((da) => da.FloorPlans)
      .toArray();
    let vmCustomers = customers
      .sort(
        (c1, c2) => -c1.ChildModifiedDate.localeCompare(c2.ChildModifiedDate),
      )
      .map(
        (clientCustomer) =>
          new FloorPlansVm.Customer(
            clientCustomer,
            columns,
            this.baseVmService.translationService,
          ),
      );
    this.unpaginatedCustomers = vmCustomers;
    this.numPages = Math.max(
      Math.ceil(
        this.unpaginatedCustomers.length / FloorPlansVm.numCustomersPerPage,
      ),
      1,
    );
    this.pageNum = parseInt(this.filterParams.page || '1');
    let start = FloorPlansVm.numCustomersPerPage * (this.pageNum - 1);
    this.customers = this.unpaginatedCustomers.slice(
      start,
      start + FloorPlansVm.numCustomersPerPage,
    );

    let hashIds = this.hashIds;
    for (let obj of this.objects) {
      if (hashIds[obj.Id]) {
        obj.selected = true;
      }
    }

    let selectedFloorPlanIds: string[] | undefined = this.filterParams.selected;
    if (selectedFloorPlanIds !== undefined && selectedFloorPlanIds.length > 0) {
      selectedFloorPlanIds = selectedFloorPlanIds[0].split(',');
    }

    for (let customer of this.unpaginatedCustomers) {
      if (this.filterParams.customerId === customer.id.toString()) {
        customer.collapsed = false;
      }
      for (let project of customer.projects) {
        if (this.filterParams.projectId === project.id.toString()) {
          customer.collapsed = false;
          project.collapsed = false;
        }
        for (let deliveryAddress of project.deliveryAddresses) {
          if (
            this.filterParams.deliveryAddressId ===
            deliveryAddress.clientDeliveryAddress.ProjectDeliveryAddressId.toString()
          ) {
            customer.collapsed = false;
            project.collapsed = false;
            deliveryAddress.collapsed = false;
            break;
          }
          for (let floorplan of deliveryAddress.floorPlans) {
            if (selectedFloorPlanIds !== undefined) {
              if (selectedFloorPlanIds.includes(floorplan.id.toString())) {
                floorplan.selected = true;
              }
            }

            if (floorplan.selected) {
              customer.collapsed = false;
              project.collapsed = false;
              deliveryAddress.collapsed = false;
              //break;
            }
          }
        }
      }
    }
    this.selectedFloorPlans = this.getSelectedFloorPlans();
  }

  public editCustomer(customer: FloorPlansVm.Customer) {
    this.closeRightMenu();
    this.selection = {
      type: 'customer',
      customer: ObjectHelper.copy(customer.clientCustomer.dtoObject),
    };
  }

  public editProject(project: FloorPlansVm.Project) {
    this.closeRightMenu();
    this.selection = {
      type: 'project',
      project: ObjectHelper.copy(project.clientProject.dtoObject),
    };
  }

  public editDeliveryAddress(deliveryAddress: FloorPlansVm.DeliveryAddress) {
    this.closeRightMenu();
    this.selection = {
      type: 'deliveryAddress',
      deliveryAddress: deliveryAddress.clientDeliveryAddress,
    };
  }

  public override closeRightMenu() {
    for (let obj of this.objects) obj.selected = false;
    this.selection = { type: 'none' };
  }
  public get isActionInProgress() {
    return this.isCopyingInProgress || this.isMovingInProgress;
  }

  public get isMovable(): boolean {
    return (
      this.selectedFloorPlans.every(
        (fp) => fp.ActualStatus == Interface_Enums.DeliveryStatus.Quote,
      ) ||
      this.orderService.isCancelable(this.selectedFloorPlans) ||
      this.orderService.isOrderable(this.selectedFloorPlans)
    );
  }

  public get pageNum() {
    return this._pageNum;
  }
  public set pageNum(val: number) {
    this._pageNum = val;
    if (val === 1) {
      this.filterParams.page = undefined;
    } else {
      this.filterParams.page = val.toString();
    }
  }

  private getInitials(userName: string): string {
    let resultArray = userName
      .split(/[\s-]+/) //Split username on space or dash
      .map((namePart) => (namePart.length === 0 ? '' : namePart[0]));

    return ''.concat(...resultArray).toLocaleUpperCase();
  }

  private genWeekDays(deliveryDays: string): string {
    let activeDeliveryDays = ObjectHelper.getWeekDays(deliveryDays);

    let translatedDayNames = activeDeliveryDays.map((add) =>
      this.translate('weekday_' + add, add),
    );
    return translatedDayNames.join(', ');
  }

  public async selectColumns() {
    let selectedColumns = this.clientSettingsValue.columnsFloorPlan;
    let newCols = await this.modalService.selectColumns(
      this.columns,
      selectedColumns,
    );
    if (newCols) {
      const newSettings = {
        ...this.clientSettingsValue,
      };

      newSettings.columnsFloorPlan = newCols;
      this.clientSettings.set(newSettings);
    }
  }

  public static joinNameParts(
    ...parts: (string | number | null | undefined)[]
  ): string {
    return parts
      .filter((part) => part !== null)
      .filter((part) => part !== undefined)
      .filter((part) => part !== '')
      .filter((part) => part !== ' ')
      .map((part) => (<number | string>part).toString())
      .join(' | ');
  }

  private getColumns(): {
    [colName: string]: TableColumn<Client.FloorPlanOverview>;
  } {
    return {
      name: {
        displayName: this.translate('floorplans_col_name_name', 'Name'),
        removable: false,
        colClass: 'text-col fp-name-col',
        val: (fp) => fp.Name,
        cellClass: (fp) => 'name-cell',
        titleVal: (fp) =>
          this.translate(
            'floorplan_table_status_title_' + fp.CurrentStatus,
            'Status ' + fp.CurrentStatus,
          ) +
          ' ' +
          this.translate(
            'floorplan_table_errorlevel_title_' + fp.MaxErrorLevel.toString(),
            'ErrorLevel ' + fp.MaxErrorLevel.toString(),
          ),
      },
      orderNumber: {
        displayName: this.translate(
          'floorplans_col_name_order_number',
          'Order Number',
        ),
        removable: false,
        colClass: 'short-text-col fp-order-number-col',
        val: (fp) => fp.OrderNo,
      },
      deliveryWeek: {
        displayName: this.translate(
          'floorplans_col_name_delivery_week',
          'Delivery Week',
        ),
        removable: false,
        colClass: 'number-col fp-delivery-week-col',
        val: (fp) => fp.DeliveryWeek,
      },
      editDeadline: {
        displayName: this.translate(
          'floorplans_col_name_edit_deadline',
          'Edit Deadline',
        ),
        removable: false,
        colClass: 'date-col fp-edit-deadline-col',
        val: (fp) => {
          if (fp.CorrectionDeadlineDate) {
            let date = DateHelper.fromIsoString(fp.CorrectionDeadlineDate);
            return this.formattingService.formatDate(date, false);
          }
          return this.translate(
            'floorplans_col_correction_deadline_unknown',
            'Unknown',
          );
        },
        sortVal: (fp) => fp.CorrectionDeadlineDate || '',
      },
      id: {
        displayName: this.translate('floorplans_col_name_id', 'Id'),
        removable: false,
        colClass: 'id-col',
        val: (fp) => fp.Id,
      },
      reference: {
        displayName: this.translate(
          'floorplans_col_name_reference',
          'Reference',
        ),
        removable: false,
        colClass: 'text-col fp-reference-col',
        val: (fp) => fp.Reference,
      },
      status: {
        displayName: this.translate('floorplan_col_name_status', 'Status'),
        removable: true,
        colClass: 'short-text-col',
        sortVal: (fp) => fp.CurrentStatus,
        val: (fp) =>
          this.translate(
            'floorplan_table_status_title_' + fp.CurrentStatus,
            'Status ' + fp.CurrentStatus,
          ),
        titleVal: (fp) =>
          this.translate(
            'floorplan_table_status_title_' + fp.CurrentStatus,
            'Status ' + fp.CurrentStatus,
          ),
      },
      createdDate: {
        displayName: this.translate(
          'floorplans_col_name_created_date',
          'Last Change',
        ),
        removable: true,
        colClass: 'datetime-col fp-last-change-col',
        val: (fp) => {
          let date = DateHelper.fromIsoString(fp.CreatedDate);
          return this.formattingService.formatDate(date, true);
        },
        sortVal: (fp) => fp.CreatedDate,
      },
      deliveryDays: {
        displayName: this.translate(
          'floorplans_col_name_delivery_days',
          'Delivery weekdays',
        ),
        removable: true,
        colClass: 'short-text-col fp-delivery-days-col',
        val: (fp) => this.genWeekDays(fp.ExpectedDeliveryDays),
      },
      initials: {
        displayName: this.translate('floorplans_col_name_initials', 'Initials'),
        removable: true,
        colClass: 'initial-col fp-initial-col',
        val: (fp) => {
          let userInitials = this.getInitials(fp.UserName);
          if (fp.SupporterName) {
            let supporterInitials = this.getInitials(fp.SupporterName);
            return this.translate(
              'floorplans_table_initials_value_{0}_{1}',
              '{0} / {1}',
              userInitials,
              supporterInitials,
            );
          } else {
            return userInitials;
          }
        },
        titleVal: (fp) => {
          if (fp.SupporterName) {
            return this.translate(
              'floorplans_table_initials_title_{0}_{1}',
              '{0} (supported by {1})',
              fp.UserName,
              fp.SupporterName,
            );
          } else {
            return fp.UserName;
          }
        },
      },
      usesFullCatalog: {
        displayName: this.translate(
          'floorplans_col_name_usesFullCatalog',
          'Full Catalog',
        ),
        removable: true,
        colClass: 'short-text-col fp-full-catalog-col',

        val: (fp) =>
          fp.UsesFullCatalog
            ? this.translate('floorplan_table_usesFullCatalog_yes', 'Yes')
            : this.translate('floorplan_table_usesFullCatalog_no', 'No'),
        sortVal: (fp) => (fp.UsesFullCatalog ? 1 : 0),
        cellClass: (fp) =>
          fp.UsesFullCatalog ? 'full-catalog-enabled' : 'full-catalog-disabled',
      },
      salesType: {
        displayName: this.translate(
          'floorplan_col_name_sales_type',
          'Sales Type',
        ),
        removable: true,
        colClass: 'text-col',
        val: (fp) => {
          let st = this.salesTypes[fp.SalesTypeNumber];
          return st ? st.Name : '--';
        },
        sortVal: (fp) => {
          let st = this.salesTypes[fp.SalesTypeNumber];
          return st ? parseInt(st.Number) : 0;
        },
      },
      smsPhone: {
        displayName: this.translate(
          'floorplan_col_name_sms_phone',
          'SMS Phone',
        ),
        removable: true,
        colClass: 'text-col',
        val: (fp) => fp.SmsNumber,
      },
      numCabinets: {
        displayName: this.translate(
          'floorplan_col_name_num_cabinets',
          'Cabinets',
        ),
        removable: true,
        colClass: 'number-col',
        val: (fp) => fp.NumCabinets.toString(),
      },
      driverInfo: {
        displayName: this.translate(
          'floorplan_col_name_driver_info',
          'Driver Info',
        ),
        removable: true,
        colClass: 'text-col',
        val: (fp) => fp.DriverInformation,
      },
      internalNote: {
        displayName: this.translate(
          'floorplan_col_name_internal_note',
          'Internal Note',
        ),
        removable: true,
        colClass: 'text-col',
        val: (fp) => fp.InternalNote,
      },
      totalPrice: {
        displayName: this.translate(
          'floorplan_col_name_total_price',
          'Total price excl VAT',
        ),
        removable: true,
        val: (fp) =>
          fp.TotalPrice.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        colClass: 'number-col',
      },
      totalPriceInclVat: {
        displayName: this.translate(
          'floorplan_col_name_total_price_incl_vat',
          'Total price incl. VAT',
        ),
        removable: true,
        val: (fp) =>
          fp.TotalPriceInclVat.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        colClass: 'number-col',
      },
      deliveryDate: {
        displayName: this.translate(
          'floorplan_col_name_delivery_date',
          'Delivery Date',
        ),
        removable: true,
        colClass: 'short-text-col',
        sortVal: (fp) => fp.PlannedDeliveryDate,
        val: (fp) => {
          let pdd = fp.PlannedDeliveryDate
            ? DateHelper.fromIsoString(fp.PlannedDeliveryDate)
            : null;
          if (!pdd || pdd < DateHelper.earliestSaneDate) pdd = null;
          return pdd
            ? this.formattingService.formatDate(pdd, false)
            : this.translate(
                'floorplan_overview_planned_delivery_day_unknown',
                'Unknown',
              );
        },
      },
    };
  }

  public setOrder(colName: string) {
    console.debug('FloorPlansVm.setOrder( ' + colName + ' )');
    if (this.order === colName) {
      this.reverse = !this.reverse;
    } else {
      this.order = colName;
      this.reverse = false;
    }
  }

  private salesTypes: { [number: string]: Interface_DTO.SalesType } = {};

  public async editOldCabinet(
    oldCabinet: Interface_DTO.CabinetOverview,
  ): Promise<void> {
    '/floorPlan/' + Constants.editor.oldFloorplanIdPrefix + oldCabinet.Id;
  }

  public async downloadPieceList(overview: Interface_DTO.FloorPlanOverview) {
    let printOptions: Interface_DTO.PrintOptions;

    if (overview.IsLegacyFloorPlan) {
      try {
        let modal = this.modalService.getPrintDialog(undefined);
        printOptions = await modal.result;
      } catch (e: any) {
        //User cancelled, do nothing
        return;
      }
      await this.notificationService.loadPromise(
        this.pieceListService.downloadPiecelistFromId(
          overview.Id,
          printOptions,
          overview.Name,
        ),
      );
    } else {
      this.floorPlanService.removeFromCache(overview.Id);
      var floorPlan = await this.floorPlanService.getFloorPlan(overview.Id);
      this.floorPlanService.floorPlanHydratePartition(floorPlan);
      try {
        let modal = this.modalService.getPrintDialog(floorPlan);
        printOptions = await modal.result;
      } catch (e: any) {
        //User cancelled, do nothing
        return;
      }
      var downloadPromise = this.pieceListService.downloadPiecelist(
        floorPlan,
        printOptions,
      );

      try {
        await this.notificationService.loadPromise(downloadPromise);
      } catch (err: any) {
        this.notificationService.exception(
          'PDF_Download_error',
          err,
          'Error downloading .pdf file. Please refresh this page and try again.',
        );
      }
    }
  }

  public async newCustomer() {
    let modalPromise =
      this.modalService.getNewCustomerModalChained('floorplan');
    let modalResult = await modalPromise;

    if (modalResult.type === 'floorPlan') {
      this.routeService.setCustomerIdForLastSearch(modalResult.customerId);
      this.navigate.floorPlan(modalResult.floorPlanId, {
        openedFromOverview: true,
      });
    } else if (modalResult.type !== 'none') {
      this.navigate.floorPlans({
        customerId: modalResult.customerId.toString(),
      });
      this.routeService.setCustomerIdForLastSearch(modalResult.customerId);
    }
  }

  public customerBack(customer: FloorPlansVm.Customer) {
    const queryParams = {
      ...this.filterParams,
      projectId: null,
      deliveryAddressId: null,
      selected: customer.id.toString(),
    };
    this.router.navigate(['/customers'], { queryParams: queryParams });
  }

  public deliveryAddressBack(deliveryAddress: FloorPlansVm.DeliveryAddress) {
    const queryParams = {
      ...this.filterParams,
      projectId: deliveryAddress.clientDeliveryAddress.project.Id.toString(),
      deliveryAddressId: null,
      selected: deliveryAddress.id.toString(),
    };
    this.router.navigate(['/deliveryaddresses'], { queryParams: queryParams });
  }

  public projectBack(project: FloorPlansVm.Project) {
    const queryParams = {
      ...this.filterParams,
      projectId: null,
      customerid: project.clientProject.CustomerId,
      deliveryAddressId: null,
      selected: project.id.toString(),
    };
    this.router.navigate(['/projects'], { queryParams: queryParams });
  }

  public async newProject(customer: Client.Customer) {
    let modalResult = await this.modalService.getNewProjectModalChained(
      customer,
      'floorplan',
    );
    if (modalResult.type === 'floorPlan') {
      this.navigateToNewlyCreatedFloorPlan(modalResult.floorPlanId);
    }
  }

  public async newDeliveryAddress(project: Client.Project) {
    let modalResult = await this.modalService.getNewDeliveryAddressModalChained(
      project,
      project.customer,
      'floorplan',
    );
    if (modalResult.type === 'floorPlan') {
      this.navigateToNewlyCreatedFloorPlan(modalResult.floorPlanId);
    }
  }
  public async newFloorPlan(deliveryAddress: Client.DeliveryAddress) {
    let modalResult = await this.modalService.getNewFloorPlanModalChained(
      deliveryAddress.ProjectDeliveryAddressId,
      'floorplan',
    );
    if (modalResult.type === 'floorPlan') {
      this.navigateToNewlyCreatedFloorPlan(modalResult.floorPlanId);
    }
  }

  private navigateToNewlyCreatedFloorPlan(floorPlanId: number) {
    this.filterParams.selected = [floorPlanId.toString()];
    this.navigationService.setLastSearch(this.filterParams);
    this.navigate.floorPlan(floorPlanId, {
      openedFromOverview: true,
    });
  }

  public selectionChanged() {
    this.selection = { type: 'none' };
    this.selectedFloorPlans = this.getSelectedFloorPlans();
    if (App.debug.showSelectionChanges) {
      console.log('selected floorplans changed: ', this.selectedFloorPlans);
    }

    this.updateHash();
  }

  public selectionChangedWithParams(
    selected: boolean,
    clientfloorPlan: Client.FloorPlanOverview,
  ) {
    clientfloorPlan.selected = selected;
    this.selectionChanged();
  }

  public updateSelected() {
    this.selectedFloorPlans = this.getSelectedFloorPlans();
  }

  public setWrongSelection(floorPlan: FloorPlansVm.FloorPlan) {}
  public setSelection(floorPlan: Client.FloorPlanOverview) {
    for (let obj of this.objects) {
      obj.selected = false;
    }
    floorPlan.selected = true;
    this.selectionChanged();
  }

  public collapseAll() {
    for (let cust of this.customers) {
      cust.collapsed = true;
      for (let proj of cust.projects) {
        proj.collapsed = true;
        for (let da of proj.deliveryAddresses) {
          da.collapsed = true;
        }
      }
    }
  }

  public uncollapseAll() {
    for (let cust of this.customers) {
      cust.collapsed = false;
      for (let proj of cust.projects) {
        proj.collapsed = false;
        for (let da of proj.deliveryAddresses) {
          da.collapsed = false;
        }
      }
    }
  }

  public get allCollapsed(): boolean {
    return this.customers.some((c) => c.collapsed);
  }

  public selectedFloorPlans: Client.FloorPlanOverview[] = [];

  private getSelectedFloorPlans(): Client.FloorPlanOverview[] {
    return Enumerable.from(this.unpaginatedCustomers)
      .selectMany((cust) => cust.projects)
      .selectMany((proj) => proj.deliveryAddresses)
      .selectMany((da) => da.floorPlans)
      .where((fp) => fp.selected)
      .select((fp) => fp.clientFloorPlan)
      .toArray();
  }

  public get selectionName(): string {
    if (!this.selectedFloorPlans) return '';
    if (this.selectedFloorPlans.length === 0) return '';
    if (this.selectedFloorPlans.length === 1)
      return this.selectedFloorPlans[0].Name;
    return this.translate(
      'floorplans_nultiple_names_{0}',
      '{0} floor plans',
      this.selectedFloorPlans.length.toString(),
    );
  }

  public async copySelection() {
    if (this.isActionInProgress) return;
    try {
      this.isCopyingInProgress = true;
      let selectedFloorPlanIds = this.getSelectedFloorPlans().map((fp) => {
        return { Id: fp.Id, IsLegacyFloorPlan: fp.IsLegacyFloorPlan };
      });
      let addressModal = this.modalService.getDeliveryAddressSelector(
        null,
        true,
        false,
        false,
      );
      let targetDeliveryAddresses =
        (await addressModal.result) as Client.DeliveryAddress[];
      let targetDeliveryAddressIds = targetDeliveryAddresses.map(
        (da) => da.ProjectDeliveryAddressId,
      );
      try {
        await this.floorPlanService.copyFloorPlans(
          selectedFloorPlanIds,
          targetDeliveryAddressIds,
          this.numCopies,
        );
        this.notificationService.success(
          'floorPlans_copy_success',
          'Copy successful',
        );
        this.navigate.floorPlans({ owner: this.filterParams.owner });
        if (this.applyFiltersOnMove) {
          this.navigate.floorPlans({
            owner: this.filterParams.owner,
            deliveryAddressId: targetDeliveryAddressIds.join(
              Constants.overview.idSearchSplitter,
            ),
          });
        } else {
          this.navigate.floorPlans({
            owner: this.filterParams.owner,
          });
        }
      } catch (e: any) {
        this.notificationService.exception(
          'floorPlans_copy_failed',
          e,
          'Copy failed',
        );
        throw e;
      }
    } finally {
      this.isCopyingInProgress = false;
      this.numCopies = 1;
    }
  }

  public async moveSelection() {
    if (!this.isMovable) return;
    if (this.isActionInProgress) return;
    try {
      this.isMovingInProgress = true;
      let selectedFloorPlanIds = this.getSelectedFloorPlans().map((fp) => {
        return { Id: fp.Id, IsLegacyFloorPlan: fp.IsLegacyFloorPlan };
      });
      let addressModal = this.modalService.getDeliveryAddressSelector(
        null,
        false,
        false,
        false,
      );
      let targetDeliveryAddresses = await addressModal.result; //this will cause an exception if user cancels. Do not give an error message just because the user cancels.
      let targetDeliveryAddressesId =
        targetDeliveryAddresses[0].ProjectDeliveryAddressId;
      try {
        let movePromise = this.floorPlanService.moveFloorPlans(
          selectedFloorPlanIds,
          targetDeliveryAddressesId,
          (deliveryInfo) =>
            this.modalService.acceptDeliveryInfo(
              deliveryInfo,
              '',
              false,
              deliveryInfo.CurrentStatus,
              targetDeliveryAddresses[0],
            ),
        );
        this.notificationService.loadPromise(movePromise);
        let wasMoved = await movePromise;
        if (!wasMoved) return;
        this.notificationService.success(
          'floorPlans_move_success',
          'Move successful',
        );
        if (this.applyFiltersOnMove) {
          this.navigate.floorPlans({
            owner: this.filterParams.owner,
            deliveryAddressId: targetDeliveryAddressesId.toString(),
          });
        } else {
          this.navigate.floorPlans({
            owner: this.filterParams.owner,
          });
        }

        if (!this.clientSettingsValue.showAllFloorplansOnEmptySearch) {
          this.navigate.floorPlans({
            customerId:
              targetDeliveryAddresses[0].project.customer.Id.toString(),
          });
        }
      } catch (e: any) {
        if (
          e instanceof Exception.ServerDomainException &&
          e.Type ===
            Interface_DTO_DomainError.ErrorType.DeliveryLeadtimeTooShort
        ) {
          this.notificationService.userError(
            'floorplans_move_correction_deadline_exceeded',
            'Could not move floorplans because correction deadline is exceeded',
          );
        } else if (
          e instanceof Exception.ServerDomainException &&
          e.Type === Interface_DTO_DomainError.ErrorType.FloorPlanPriceChanged
        ) {
          this.notificationService.userError(
            'floorplans_move_failed_price_changed',
            'Could not move floorplans because it would change the freight price',
          );
        } else if (
          e instanceof Exception.ServerDomainException &&
          e.Type === Interface_DTO_DomainError.ErrorType.MultipleActiveOrders
        ) {
          this.notificationService.userError(
            'floorplans_move_failed_multiple_active_orders',
            'Could not move floorplans - it is only possible to move one active order at a time',
          );
        } else {
          this.notificationService.exception(
            'floorPlans_move_failed',
            e,
            'Move failed',
          );
        }
      }
    } finally {
      this.isMovingInProgress = false;
    }
  }

  public isFloorPlanCompatible(
    fp: DtoContainer<Interface_DTO.FloorPlanOverview>,
  ): boolean {
    let result = !!(
      fp.dtoObject.AppCompatibility &
      Interface_Enums.AppCompatibility.RetailHtml5
    );
    return result;
  }

  public isSelectionEditable(): boolean {
    let floorPlans = this.selectedObjects;
    if (!floorPlans || floorPlans.length !== 1) return false;
    let floorPlan = floorPlans[0];
    //Wether full catalog is activated in dark sidebar or not
    if (floorPlan.dtoObject.UsesFullCatalog && !this.isFullCatalogActivated)
      return false;
    if (!this.isFloorPlanCompatible(floorPlan)) return false;

    return true;
  }

  public asDateString(isoString: string): string {
    let date = DateHelper.fromIsoString(isoString);
    return this.formattingService.formatDate(date, false);
  }

  public async deleteSelected() {
    let sure = confirm(
      this.translate(
        'floorplan_confirm_delete',
        'Are you sure you want to delete the selected floorplans?',
      ),
    );
    if (!sure) return;
    let floorPlanIds = this.selectedFloorPlans
      .filter((fpo) => fpo.isFloorPlan)
      .map((fpo) => fpo.Id);

    let oldCabinetIds = this.selectedFloorPlans
      .filter((fp) => fp.IsLegacyFloorPlan)
      .map((ocab) => ocab.Id);

    let oldCabinetPromises = oldCabinetIds.map((id) =>
      this.floorPlanService.deleteOldCabinet(id),
    );
    let floorPlanPromises = floorPlanIds.map((id) =>
      this.floorPlanService.deleteFloorPlans([id]),
    );

    let allDeletePromises = oldCabinetPromises
      .concat(floorPlanPromises)
      .map(async (promise) => {
        try {
          await promise;
        } catch (e: any) {
          if (e.Type === Interface_DTO_DomainError.ErrorType.ActiveOrder) {
            this.notificationService.userError(
              'floorplan_delete_fail_active_order',
              'Cannot delete the floorplan because it is in order',
            );
          } else {
            this.notificationService.exception(
              'floorplan_delete_fail_unknown',
              e,
              'Unknown error while deleting floorplan',
            );
          }
        }
      });

    let combinedPromise = Promise.all(allDeletePromises);
    await this.notificationService.loadPromise(
      combinedPromise.then((_) => this.customerService.updateCustomerList()),
    );
    this.closeRightMenu();
  }

  protected getId(o: Interface_DTO.FloorPlanOverview): number {
    return o.Id;
  }

  public displayUserMessage(userMessage: Interface_DTO.UserMessage): void {
    this.modalService.displayUserMessages([userMessage]);
  }

  public get showResendScreenshots(): boolean {
    return this.permissions.canResendScreeenshots;
  }

  public async resendScreenshots() {
    try {
      await this.notificationService.loadPromise(this.resendScreenshotsAsync());
      console.log('Element list re-generated');
      this.notificationService.success(
        'floorplan_resend_success',
        'Element list re-generated',
      );
    } catch (e: any) {
      this.notificationService.exceptionWithDefaultText(e);
    }
  }
  private async resendScreenshotsAsync() {
    let floorPlanIds = this.selectedFloorPlans
      .filter((fpo) => fpo.isFloorPlan)
      .map((fpo) => fpo.Id);
    for (let fpid of floorPlanIds) {
      await this.resendScreenshotsForFloorPlan(fpid);
    }
  }

  private async resendScreenshotsForFloorPlan(
    floorPlanId: number,
  ): Promise<void> {
    let floorPlan = await this.floorPlanService.downloadFloorPlan(floorPlanId);
    let screenshotPromises =
      this.screenshotService.getAllScreenshots(floorPlan);
    let screenshotSendPromises = screenshotPromises.map((ssp) =>
      ssp.then((screenshot) =>
        this.httpClient.post<void>(
          'api/floorPlan/' + floorPlanId + '/screenshot',
          screenshot,
          { responseType: 'void' },
        ),
      ),
    );
    await Promise.all(screenshotSendPromises);
    let voidConfig: RequestConfig = {};
    voidConfig.responseType = 'void';
    await this.httpClient.post(
      'api/order/regeneratePieceList/' + floorPlanId,
      {},
      voidConfig,
    );
  }

  public trackCustomerById(index: number, customer: FloorPlansVm.Customer) {
    return customer.id;
  }

  public trackDeliveryAddressById(
    index: number,
    deliveryAddress: FloorPlansVm.DeliveryAddress,
  ) {
    return deliveryAddress.id;
  }

  public navigateToEditor() {
    this.navigationService.setLastSearch(this.filterParams);
    this.navigate.floorPlan(
      this.selectedObjects[0].dtoObject.Id,
      {
        openedFromOverview: true,
      },
      this.selectedObjects[0].dtoObject.IsLegacyFloorPlan,
    );
  }
}

export module FloorPlansVm {
  export class Cell {
    public readonly content: string;
    public readonly cssClass: string;
    public readonly title: string;
    constructor(
      col: TableColumn<Client.FloorPlanOverview>,
      floorPlan: Client.FloorPlanOverview,
    ) {
      let val = col.val(floorPlan);
      this.content = val ? val.toString() : '';
      this.cssClass = col.cellClass ? col.cellClass(floorPlan) : '';
      this.title = col.titleVal ? col.titleVal(floorPlan).toString() : '';
    }
  }
  export class FloorPlan implements ISelectable {
    constructor(
      public readonly clientFloorPlan: Client.FloorPlanOverview,
      cols: (TableColumn<Client.FloorPlanOverview> & { name: string })[],
    ) {
      this.cells = cols.map((col) => new Cell(col, clientFloorPlan));
      this.id = clientFloorPlan.Id;
      for (let col of cols) {
        if (col.sortVal) {
          this.sortValues[col.name] = col.sortVal(clientFloorPlan).toString();
        } else {
          this.sortValues[col.name] = (
            col.val(clientFloorPlan) || ''
          ).toString();
        }
      }
    }
    public readonly id: number;
    public readonly name: string = '';
    public readonly cells: Cell[];
    public readonly sortValues: { [colName: string]: string } = {};

    public get cssClass(): string {
      let result = '';
      if (this.selected) result += ' selected ';
      result += ' order-status-' + this.clientFloorPlan.CurrentStatus;
      result += ' floorplan-errorlevel-' + this.clientFloorPlan.MaxErrorLevel;
      return result;
    }
    public get selected() {
      return this.clientFloorPlan.selected;
    }
    public set selected(val: boolean) {
      this.clientFloorPlan.selected = val;
    }
  }
  export class DeliveryAddress implements Collapsible {
    private _order: string = 'createdDate';
    private _reverseOrder: boolean = true;

    constructor(
      public readonly clientDeliveryAddress: Client.DeliveryAddress,
      cols: (TableColumn<Client.FloorPlanOverview> & { name: string })[],
      translationService: ITranslationService,
    ) {
      this.floorPlans = clientDeliveryAddress.FloorPlans.map(
        (flPl) => new FloorPlan(flPl, cols),
      );
      this.id = clientDeliveryAddress.ProjectDeliveryAddressId;
      //this.name = clientDeliveryAddress.Name;
      this.name = FloorPlansVm.joinNameParts(
        clientDeliveryAddress.Name,
        clientDeliveryAddress.Address1,
        clientDeliveryAddress.Address2,
        translationService.translate(
          'floorplans_brief_delivery_address_floor',
          '{0}. floor',
          clientDeliveryAddress.Floor.toString(),
        ),
        (clientDeliveryAddress.PostalCode || '') +
          ' ' +
          (clientDeliveryAddress.City || ''),
        clientDeliveryAddress.Phone,
      );
      this.sortChildren();
    }
    public readonly id: number;
    public readonly name: string;
    public readonly floorPlans: FloorPlan[];

    public get collapsed(): boolean {
      return this.clientDeliveryAddress.collapsed;
    }
    public set collapsed(val: boolean) {
      this.clientDeliveryAddress.collapsed = val;
    }

    public get isAllSelected() {
      return this.floorPlans.every((fp) => fp.selected);
    }
    public set isAllSelected(val: boolean) {
      for (let fp of this.floorPlans) {
        fp.selected = val;
      }
    }

    //Delete this function
    // public selectedFloorplan(clF: FloorPlan): FloorPlan | undefined {
    //     for (let fp of this.floorPlans) {
    //         if (fp.clientFloorPlan.Id == clF.clientFloorPlan.Id) {
    //             return fp;
    //         }
    //         return fp;
    //     }
    //     return undefined;
    // }

    public get order() {
      return this._order;
    }
    public set order(val: string) {
      if (this.order === val) {
        this._reverseOrder = !this._reverseOrder;
      } else {
        this._reverseOrder = false;
      }
      this._order = val;

      this.sortChildren();
    }
    public get reverseOrder() {
      return this._reverseOrder;
    }

    private sortChildren() {
      this.floorPlans.sort((a, b) => {
        let aSort = a.sortValues[this.order] || '';
        let bSort = b.sortValues[this.order] || '';
        return aSort.localeCompare(bSort) * (this.reverseOrder ? -1 : 1);
      });
    }
  }
  export class Project implements Collapsible {
    constructor(
      public readonly clientProject: Client.Project,
      cols: (TableColumn<Client.FloorPlanOverview> & { name: string })[],
      translationService: ITranslationService,
    ) {
      this.deliveryAddresses = clientProject.DeliveryAddresses.map(
        (da) => new DeliveryAddress(da, cols, translationService),
      ).sort(
        (daA, daB) =>
          -daA.clientDeliveryAddress.CreatedDate.localeCompare(
            daB.clientDeliveryAddress.CreatedDate,
          ),
      );
      this.id = clientProject.Id;
      this.name = FloorPlansVm.joinNameParts(
        clientProject.Name,
        clientProject.Description,
      );
    }
    public readonly id: number;
    public readonly name: string;
    public readonly deliveryAddresses: DeliveryAddress[];

    public get collapsed(): boolean {
      return this.clientProject.collapsed;
    }
    public set collapsed(val: boolean) {
      this.clientProject.collapsed = val;
    }
  }
  export class Customer implements Collapsible {
    constructor(
      public readonly clientCustomer: Client.Customer,
      cols: (TableColumn<Client.FloorPlanOverview> & { name: string })[],
      translationService: ITranslationService,
    ) {
      this.projects = clientCustomer.Projects.map(
        (proj) => new Project(proj, cols, translationService),
      ).sort(
        (projA, projB) =>
          -projA.clientProject.ChildModifiedDate.localeCompare(
            projB.clientProject.ChildModifiedDate,
          ),
      );
      this.id = clientCustomer.Id;
      this.name = FloorPlansVm.joinNameParts(
        clientCustomer.DebitorNo,
        clientCustomer.Name,
        clientCustomer.Address1,
        clientCustomer.PostalCode + ' ' + clientCustomer.City,
      );
    }
    public readonly id: number;
    public readonly name: string;
    public readonly projects: Project[];

    public get collapsed(): boolean {
      return this.clientCustomer.collapsed;
    }
    public set collapsed(val: boolean) {
      this.clientCustomer.collapsed = val;
    }
  }
}
