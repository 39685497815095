import { Component } from '@angular/core';
import { WindowService } from 'app/ng/window.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { ModalService } from 'app/ts/services/ModalService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { PieceListService } from 'app/ts/services/PieceListService';
import { RouteService } from 'app/ts/services/RouteService';
import { EditorVm } from 'app/floor-plan-editing/EditorVm';
import { BehaviorSubject } from 'rxjs';
import { DeliveryAddressService } from '../services/DeliveryAddressService';
import { FloorPlanSaveService } from '../services/FloorPlanSaveService';
import { SelectedItemsService } from 'app/floor-plan-editing/selected-items.service';

/** NOTE! This was previously named just PieceListVm, but was renamed
 * since this conflicted with another PieceListVm.
 */
@Component({
  selector: 'piece-list-top',
  templateUrl: './pieceListTop.html',
  styleUrls: ['../../../style/Picelist.scss', '../../../style/editor.scss'],
  providers: [PieceListService, DeliveryAddressService],
})
export class PieceListTopVm extends EditorVm {
  private _searchString$ = new BehaviorSubject<string>('');

  public get searchString$() {
    return this._searchString$;
  }

  private _searchString = '';
  public get searchString() {
    return this._searchString;
  }

  public set searchString(value: string) {
    this._searchString = value;
    this._searchString$.next(value);
  }

  constructor(
    baseVmService: BaseVmService,
    floorPlanService: FloorPlanService,
    floorPlanSaveService: FloorPlanSaveService,
    $window: WindowService,
    routeService: RouteService,
    private readonly modalService: ModalService,
    private readonly pieceListService: PieceListService,
    private readonly notificationService: NotificationService,
    private readonly selectedItemsService: SelectedItemsService,
    routing: AppRoutingModule,
    deliveryAddressService: DeliveryAddressService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorPlanSaveService,
      routeService,
      routing,
      deliveryAddressService,
    );
  }

  // TODO: Figure out WTF this is and how it is supposed to be populated
  public item!: Client.ConfigurationItem;

  protected floorPlanChanged() {}

  public async itemClicked(pieceListItem: Client.ConfigurationItem) {
    if (
      pieceListItem.ItemType == Interface_Enums.ItemType.Custom ||
      pieceListItem.ItemType == Interface_Enums.ItemType.CustomPercent
    ) {
      let modal = this.modalService.getAddFittingDialog(
        this.showPercentOption(),
        pieceListItem,
      );
      let result = await modal.result;

      if (result == undefined) return;

      for (let cab of this.editorType.floorPlan.cabinets) {
        if (cab.CabinetIndex !== pieceListItem.CabinetIndex) continue;
        for (let sec of cab.cabinetSections) {
          if (sec.CabinetSectionIndex !== pieceListItem.CabinetSectionIndex)
            continue;
          for (let i = 0; i < sec.CustomItems.length; i++) {
            let otherItem = sec.CustomItems[i];
            if (
              pieceListItem.ConfigurationItemIndex !==
              otherItem.ConfigurationItemIndex
            ) {
              continue;
            }

            if (result.action === 1) {
              //change
              otherItem.ActualHeight = parseFloat(result.Value);
              otherItem.Description = result.Description;
              otherItem.ItemType = result.ItemType;
              this.setChanged();
            } else if (result.action === 2) {
              //delete
              sec.CustomItems.splice(i, 1);
              this.setChanged();
            }

            break;
          }
          break;
        }
        break;
      }
    } else if (
      pieceListItem.ItemType == Interface_Enums.ItemType.ManuallyAdded
    ) {
      await this.modalService.EditItem(pieceListItem);
    }
  }

  public async openAddFittings() {
    let cabinetSection = this.editorType.floorPlan.cabinets
      .filter(
        (c) => c.CabinetType == Interface_Enums.CabinetType.SharedItems,
      )[0]
      .cabinetSections.filter(
        (cs) => cs.CabinetType == Interface_Enums.CabinetType.SharedItems,
      )[0];

    let modal = this.modalService.getAddFittingDialog(
      this.showPercentOption(),
      undefined,
    );

    let result = await modal.result;

    if (result != undefined) {
      let ciDTO: Interface_DTO.ConfigurationItem = {
        ItemType: result.ItemType,
        Description: result.Description,
        ActualHeight: parseFloat(result.Value),
        CabinetIndex: cabinetSection.CabinetIndex,
        CabinetSectionIndex: cabinetSection.CabinetSectionIndex,
        Children: [],
        ConfigurationItemIndex: 0,
        ExternalItemNo: '',
        Height: 0,
        HeightReduction: false,
        IsHidden: false,
        IsLocked: false,
        ItemNo: '',
        MaterialId: 0,
        ParentItemNo: '',
        ParentModuleItemIndex: null,
        ParentModuleProductId: 0,
        PositionNumber: -1,
        Price: 0,
        ProductId: 0,
        Quantity: 1,
        Width: 0,
        X: 0,
        Y: 0,
        Z: 0,
        Depth: 0,
        VariantOptions: [],
      };

      if (ciDTO.ItemType == Interface_Enums.ItemType.CustomPercent) {
        ciDTO.ActualHeight = parseFloat(result.Value);
      }

      if (ciDTO.ItemType == Interface_Enums.ItemType.Custom) {
        ciDTO.ActualHeight = parseFloat(result.Value);
        //Calculation moved to picelist;
        //ciDTO.Price = parseFloat(result.Value) / vat;
      }
      console.log(ciDTO);

      let ci = new Client.ConfigurationItem(ciDTO, cabinetSection);
      this.floorPlanService.addCustomItem(this.floorPlan, ci);
      this.setChanged();
    }
  }

  public showPercentOption(): boolean {
    let cabinetSection = this.editorType.floorPlan.cabinets
      .filter(
        (c) => c.CabinetType == Interface_Enums.CabinetType.SharedItems,
      )[0]
      .cabinetSections.filter(
        (cs) => cs.CabinetType == Interface_Enums.CabinetType.SharedItems,
      )[0];

    let showPercentOption: boolean = true;
    for (let ci of cabinetSection.CustomItems) {
      if (ci.ItemType == Interface_Enums.ItemType.CustomPercent) {
        showPercentOption = false;
      }
    }

    return showPercentOption;
  }

  public async openAddProduct() {
    await this.modalService.AddProducts(this.cabinetSection!);
  }

  public async downloadPdf() {
    //This deselects all items in interior page, before making screenshots
    this.selectedItemsService.value = [];

    let modal = this.modalService.getPrintDialog(this.floorPlan);

    let result = await modal.result;

    try {
      if (result != undefined) {
        let downloadPromise = this.pieceListService.downloadPiecelist(
          this.floorPlan,
          result,
        );
        this.notificationService.loadPromise(downloadPromise);
        await downloadPromise;
      }
    } catch (e: any) {
      this.notificationService.exception(
        'PDF_Download_error',
        e,
        'Error downloading .pdf file. Please refresh this page and try again.',
      );
    }
  }
}
