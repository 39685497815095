import * as Enums from 'app/ts/clientDto/Enums';
import { DoorSetup } from 'app/ts/clientDto/DoorSetup';
export class Constants {
  static readonly sizeAndPositionTolerance = 0.3;

  static readonly maxTallCabinetHeight = 7750;
  static readonly minCabinetDepth = 280;
  static readonly maxCabinetDepth = 1250;

  static readonly defaultCabinetDepth = 680;
  static readonly minimumSpaceForDoorsAndWindows = 1000;
  static readonly minSpaceBehindDoors = 20;

  static readonly itemWarningWidth = 1250;

  static readonly defaultProductLineId = 1;

  static readonly doorsOnlyCabinetDefaultWidth = 100;

  //#region Corpus

  static readonly roofSupportDistance = 1500;

  static readonly minimumCornerAreaOpening = 590;

  static readonly defaultCornerWidth = 800;

  static readonly fishJointCornerOffset = 300;
  static readonly CornerJointOffset = 50;

  static readonly sidePanelJointOffset = 50;

  static readonly floorUnevenCorpusPanel = '1979';
  static readonly floorEvenCorpusPanel = '0';

  static readonly defaultRoofPanel = '1998';
  static readonly defaultNonRoofPanel = '0';

  static readonly WallUnevenPanel = '1994';
  static readonly WallEvenPanel = '0';
  static readonly WallNonePanel = '1983';

  //#endregion Corpus

  //#region Door

  static readonly absoluteMinExtraRailWidth = 0;
  static readonly absoluteMaxExtraRailWidth = 2000;

  static readonly optimalDoorWidth = 1219;

  static readonly possibleDoorSetupsDoorOnly: DoorSetup[] = [
    // 1 rail
    new DoorSetup([1]), // 1
    new DoorSetup([1, 1]), // 2
    new DoorSetup([1, 1, 1]), // 3
    new DoorSetup([1, 1, 1, 1]), // 4
    new DoorSetup([1, 1, 1, 1, 1]), // 5
    new DoorSetup([1, 1, 1, 1, 1, 1]), // 6
    new DoorSetup([1, 1, 1, 1, 1, 1, 1]), // 7
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1]), // 8
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1, 1]), // 9
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1, 1, 1]), // 10
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]), // 11
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]), // 12
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]), // 13
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]), // 14
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]), // 15
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]), // 16
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]), // 17
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]), // 18
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]), // 19
    new DoorSetup([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]), // 20
  ];

  static readonly possibleDoorSetups: DoorSetup[] = [
    // 1 rail
    new DoorSetup([1]),
    new DoorSetup([1, 1]),

    // 2 rails
    new DoorSetup([2, 1]), // 2
    new DoorSetup([2, 1, 2]), // 3
    new DoorSetup([2, 1, 1, 2]), // 4
    new DoorSetup([2, 1, 2, 1]), // 4
    new DoorSetup([2, 1, 2, 1, 2]), // 5
    new DoorSetup([2, 1, 2, 2, 1]), // 5
    new DoorSetup([2, 1, 2, 2, 1, 2]), // 6
    new DoorSetup([2, 1, 2, 1, 2, 1]), // 6
    new DoorSetup([1, 2, 2, 1, 2, 2, 1]), // 7
    new DoorSetup([2, 1, 2, 1, 2, 1, 2]), // 7
    new DoorSetup([2, 1, 1, 2, 2, 1, 1, 2]), // 8
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1]), // 8
    new DoorSetup([2, 1, 1, 2, 2, 1, 1, 2, 1]), // 9
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1, 2]), // 9
    new DoorSetup([2, 1, 1, 2, 2, 1, 1, 2, 2, 1]), // 10
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1, 2, 1]), // 10
    new DoorSetup([2, 1, 1, 2, 2, 1, 2, 2, 1, 1, 2]), // 11
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2]), // 11
    new DoorSetup([2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2]), // 12
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1]), // 12
    new DoorSetup([2, 1, 1, 2, 2, 1, 2, 2, 1, 1, 2, 2, 1]), // 13
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2]), // 13
    new DoorSetup([2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2, 2, 1]), // 14
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1]), // 14
    new DoorSetup([2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 2]), // 15
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2]), // 15
    new DoorSetup([2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2]), // 16
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1]), // 16
    new DoorSetup([2, 1, 2, 2, 1, 1, 2, 2, 1, 2, 2, 1, 1, 2, 2, 1, 2]), // 17
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2]), // 17
    new DoorSetup([2, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 2]), // 18
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1]), // 18
    new DoorSetup([2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2]), // 19
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2]), // 19
    new DoorSetup([2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2]), // 20
    new DoorSetup([2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1]), // 20

    // 3 rails
    new DoorSetup([3, 2, 1]), // 3
    new DoorSetup([3, 2, 1, 2, 3]), // 5
    new DoorSetup([3, 2, 1, 1, 2, 3]), // 6
    new DoorSetup([3, 2, 1, 1, 2, 2, 3]), // 7
    new DoorSetup([3, 2, 2, 1, 1, 2, 2, 3]), // 8
    new DoorSetup([3, 2, 1, 1, 2, 3, 3, 2, 1]), // 9
    new DoorSetup([3, 2, 1, 1, 2, 2, 3, 3, 2, 1]), // 10
    new DoorSetup([3, 2, 2, 1, 1, 2, 3, 3, 2, 2, 1]), // 11
    new DoorSetup([3, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 3]), // 12
    new DoorSetup([3, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 2, 3]), // 13
    new DoorSetup([3, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 3, 2, 3]), // 14
    new DoorSetup([3, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 3, 3, 2, 1]), // 15
    new DoorSetup([3, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 3, 3, 2, 2, 1]), // 16
    new DoorSetup([3, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 3, 3, 2, 1, 2, 3]), // 17
    new DoorSetup([3, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 3]), // 18
    new DoorSetup([3, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 2, 3]), // 19
    new DoorSetup([3, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 3, 3, 2]), // 20
  ];

  static readonly absoluteMinNumberOfFillings = 1;
  static readonly absoluteMaxNumberOfFillings = 7;

  static readonly absoluteMinimumFillingHeight = 200;
  static readonly designFillingHeight = 300;
  static readonly fillingDepth = 8;

  static readonly defaultFillingMaterialNumber = '62';

  static readonly defaultGripPlacement = Enums.GripPlacement.None;
  static readonly gripMinDistanceFromTopAndBottom = 200;
  static readonly gripCenterDistanceFromEdge = 50;

  /**Fish eyes are mounted on the side of doors. Only mounted where the door can touch the side of the cabinet section. */
  static readonly fishEyeWidth = 5.0;

  static readonly defaultCornerCutVariantNumber = '';
  static readonly defaultCornerMouldingMaterialNumber = '17';

  //#endregion Door

  //#region Swing

  static readonly swingMinCabinetHeight = 1975;
  static readonly swingMaxCabinetHeight = 2750;

  static readonly swingBottomOffset = 15; // Space for adjustable feet below the actual cabinet
  static readonly swingBottomDrillOffset = 19;
  static readonly swingDoorSpace = 4; // Space around all 4 sides of a door

  static readonly swingInteriorShelfMinSpaceAbove = 212;
  static readonly swingInteriorShelfPositionY = 2085; // The max Y position for a shelf, that is part of a swing template (2064 + 15)
  static readonly swingInteriorCoatHangerSpaceAbove = 115; // The space above a coat hanger, that is part of a swing template

  static readonly swingDefaultDoorMaterialNumber = '62';

  //#endregion Swing

  //#region Interior

  static readonly minimumInteriorSideGapWidth = 22;

  static readonly ExtraAccessoriesProductCategoryNumber = 90;
  static readonly ModulePartsCategoryNumber = 199;

  static readonly minimumCornerShelfExtraWidth = 60; // Both widths of a corner shelf must be at least the opposite depth + this value

  static readonly interiorDepthRanges: {
    readonly min: number;
    readonly max: number;
  }[] = [
    { min: 280, max: 470 },
    { min: 471, max: 1250 },
  ];

  static readonly drawerGripSpace = 35;
  static readonly drawerGripEdgemountOffset = 21;

  //#endregion Interior

  //#region Backing

  static readonly BackingFittingExtraDepth = 3;
  static readonly backingOverlapForGables = 5.5;

  //#endregion Backing

  static readonly rulerWidth = 70;
  static readonly canvasMargin = 40;

  static readonly snapDist = 50;

  static readonly maxfloorPlanNameLength = 22; //max length of Navision field is (probably) 50. Needs to be shared between floor plan name, cabinet name, section number and punctuation
  static readonly maxCabinetNameLength = 22;
  static readonly maxPartitionNameLength = 22;

  static readonly D3 = {
    defaultBarModelWidth: 1301,
    defaultSideProfileModelHeight: 2000,
    defaultTopBottomProfileWidth: 1000,
  };

  static readonly localStorageKeys = {
    rememberMeChecked: 'rememberMeChecked',
    clientSetting: 'clientSetting',
    loginToken: 'loginToken',
    autoSaved: 'autoSaved',
  };

  static readonly overview = {
    anyOwnerSearchString: 'anyone',
    onlyMineOwnerSearchString: 'me',
    idSearchSplitter: '_',
    orderFarInFutureMs: 1000 * 60 * 60 * 24 * 200,
  };

  static readonly editor = {
    oldFloorplanIdRegex: /o\-(\d+)/,
    oldFloorplanIdPrefix: 'o-',
  };

  static readonly userCancelled = 'user cancelled';
}
