<div class="editor-all editor-swing" *ngIf="editorType.ready">
  <editor-topbar
    [vm]="this"
    [selection]="'swing-flex'"
    [cabinetSection]="cabinetSection"
  ></editor-topbar>

  <dark-side-bar [floorplan]="floorPlan"></dark-side-bar>

  <!-- #region Section editor -->

  <div class="tool-menu left-tool-menu">
    <div class="input-group input-group-sm">
      <span
        class="input-group-text medium-addon"
        [textContent]="
          translate('swing_cabinet_section_label_section_count', 'Sections')
        "
      ></span>
      <input
        #sectionsInput
        type="number"
        class="form-control"
        min="0"
        [value]="numberOfSwingFlexAreas"
        (change)="numberOfSwingFlexAreas = sectionsInput.valueAsNumber"
      />
    </div>

    <h3>{{ translate("editor_swing_section_widths", "Section widths") }}</h3>

    <div
      class="input-group input-group-sm"
      *ngFor="let area of swingFlexAreas; index as $index"
    >
      <span
        class="input-group-text medium-addon"
        [textContent]="$index + 1"
      ></span>
      <input
        #sectionWidthInput
        type="number"
        class="form-control"
        min="0"
        [disabled]="isSwingSectionLocked($index)"
        [value]="getSwingSectionWidth($index)"
        (change)="setSwingSectionWidth($index, sectionWidthInput.valueAsNumber)"
      />
      <span class="input-group-text">mm</span>
      <span
        *ngIf="area.widthAdjustedByUser"
        class="input-group-text"
        (click)="resetSwingSectionWidth($index)"
      >
        *
      </span>
    </div>

    <div class="product-search">
      <div class="input-group">
        <input
          type="text"
          id="productfilter"
          [(ngModel)]="productFilter"
          class="form-control search-input"
          [attr.placeholder]="
            translate('productlist_search_placeholder', 'Search')
          "
          aria-describedby="basic-addon2"
          keydown="$event.keyCode === 13 && searchEnter()"
        />
        <button
          class="btn btn-default"
          id="basic-addon2"
          onclick="var inp = document.getElementById('productfilter'); inp.focus()"
          (click)="productFilter = ''"
        >
          <span class="bi bi-search"></span>
        </button>
      </div>
    </div>

    <div
      class="product-sub-category-list btn-group btn-group-sm btn-group-vertical"
    >
      <button
        class="product-sub-category btn btn-default"
        *ngFor="let subCategory of subCategories"
        (click)="selectedSubCategory = subCategory"
        [textContent]="subCategory.Name"
        [ngClass]="{ active: subCategory.Id === selectedSubCategory?.Id }"
      ></button>
    </div>

    <div class="product-list">
      <pickable
        class="product clickable"
        [ngClass]="{ draggable: isItemsDraggable }"
        *ngFor="let pickable of productPickables"
        (mousedown)="startDrag($event, pickable.item)"
        (mouseup)="stopDrag($event, pickable.item)"
        (touchstart)="touchStart($event, pickable.item)"
        (touchend)="touchEnd($event)"
        (touchmove)="touchMove($event)"
        (click)="clickItem(pickable.item)"
        [pickable]="pickable"
      ></pickable>
    </div>
  </div>

  <!-- #endregion -->
  <!-- #region Ribbon menu -->

  <div class="ribbon-menu">
    <div class="ribbon-block checkbox-ribbon-block visibility">
      <label>
        <input type="checkbox" [(ngModel)]="showRulers" />
        {{ translate("editor_swing_show_rulers", "Show rulers") }}
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showCorpus" />
        {{ translate("editor_label_show_corpus", "Corpus") }}
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showInterior" />
        {{ translate("editor_label_show_interior", "Interior") }}
      </label>
    </div>

    <div class="ribbon-block checkbox-ribbon-block visibility">
      <label>
        <input type="checkbox" [(ngModel)]="showDoors" />
        {{ translate("editor_swing_show_doors", "Show doors") }}
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showTopdown" />
        {{ translate("editor_swing_show_topdown", "Show doors top-down") }}
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showHinges" />
        {{ translate("editor_swing_show_hinges", "Show hinges") }}
      </label>
    </div>

    <div
      class="ribbon-block checkbox-ribbon-block visibility"
      *ngIf="plinthAvailable"
    >
      <label>
        <input type="checkbox" [(ngModel)]="plinth" />
        {{ translate("editor_swing_plinth", "Plinth") }}
      </label>
      <label>
        <input
          type="checkbox"
          [disabled]="!plinth"
          [(ngModel)]="doorCoversPlinth"
        />
        {{ translate("editor_swing_door_covers_plinth", "Cover plinth") }}
      </label>
    </div>

    <div class="ribbon-block">
      <pickable
        [pickable]="doorMaterialPickable"
        class="modal-opener material door-material"
        (click)="selectDoorMaterial()"
      ></pickable>
    </div>

    <div class="ribbon-block">
      <div
        class="btn-group"
        ngbDropdown
        #materialsDropdown="ngbDropdown"
        autoClose="outside"
      >
        <button class="btn btn-default" ngbDropdownToggle>
          <span
            [textContent]="
              translate('editor_swingflex_material_dropdown', 'Materials')
            "
          ></span>
          <span class="bi bi-caret-down-fill"></span>
        </button>
        <div class="dropdown-menu" ngbDropdownMenu role="menu">
          <ul class="pickable-dropdown">
            <li class="no-margin">
              <h3
                [textContent]="
                  translate('editor_swing_corpus_material_overlay', 'Corpus')
                "
              ></h3>
            </li>
            <li role="menuitem">
              <pickable
                [pickable]="corpusMaterialPickable"
                class="modal-opener material corpus-material"
                (click)="selectCorpusMaterial()"
              ></pickable>
            </li>
            <li class="no-margin">
              <h3
                [textContent]="
                  translate('editor_swing_backing_material_overlay', 'Backing')
                "
              ></h3>
            </li>
            <li role="menuitem">
              <pickable
                [pickable]="backingMaterialPickable"
                class="modal-opener material backing-material"
                (click)="selectBackingMaterial()"
              ></pickable>
            </li>
          </ul>
          <button class="btn" (click)="materialsDropdown.close()">
            {{ translate("button_close", "Close") }}
          </button>
        </div>
      </div>
    </div>

    <div class="ribbon-block">
      <div
        class="btn-group"
        ngbDropdown
        #gripDropdown="ngbDropdown"
        autoClose="outside"
      >
        <button class="btn btn-default" ngbDropdownToggle>
          <span
            [textContent]="translate('editor_swingflex_grip_dropdown', 'Grip')"
          ></span>
          <span class="bi bi-caret-down-fill"></span>
        </button>
        <div class="dropdown-menu" ngbDropdownMenu role="menu">
          <ul class="pickable-dropdown">
            <li role="menuitem">
              <pickable
                [pickable]="doorGripPickable"
                class="modal-opener product grip-product"
                (click)="selectDoorGripForSection()"
              ></pickable>
            </li>
            <li role="menuitem">
              <pickable
                [pickable]="doorGripMaterialPickable"
                class="modal-opener material grip-material"
                (click)="selectDoorGripMaterial()"
              ></pickable>
            </li>
            <li role="menuitem" *ngIf="drawerGripPossible">
              <pickable
                [pickable]="drawerGripPickable"
                class="modal-opener product grip-product"
                (click)="selectDrawerGripForSection()"
              ></pickable>
            </li>
            <li role="menuitem" *ngIf="drawerGripMaterialPossible">
              <pickable
                [pickable]="drawerGripMaterialPickable"
                class="modal-opener material grip-material"
                (click)="selectDrawerGripMaterialForSection()"
              ></pickable>
            </li>
          </ul>

          <button class="btn" (click)="gripDropdown.close()">
            {{ translate("button_close", "Close") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- #endregion -->
  <!-- #region Image -->

  <swing-flex-image
    class="editor-center center-svg"
    [cabinetSection]="cabinetSection!"
    [showRulers]="showRulers"
    [showCorpus]="showCorpus"
    [showDoors]="showDoors"
    [showTopdown]="showTopdown"
    [showHinges]="showHinges"
    [showInterior]="showInterior"
    [showCorpusMovable]="true"
    [selectionObservable]="selectionObservable"
    [selectionSubAreaObservable]="selectionSubAreaObservable"
    [itemSelectionObservable]="itemSelectionObservable"
  ></swing-flex-image>

  <!-- #endregion -->
  <!-- #region Tool menu -->

  <div class="tool-menu right-tool-menu">
    <div *ngIf="selectedSwingFlexArea">
      <!-- #region Gable stuff -->
      <subsection-header
        [text]="translate('editor_swingFlex_gable_left_header', 'Left Gable')"
      >
        <swing-flex-gable-editor
          [gableItem]="selectedSwingFlexArea!.leftGableItem"
          [(gableInfo)]="selectedSwingFlexArea!.leftGableInfo"
          (gableInfoChange)="setChanged()"
        ></swing-flex-gable-editor>
      </subsection-header>

      <subsection-header
        [text]="translate('editor_swingFlex_gable_right_header', 'Right Gable')"
      >
        <swing-flex-gable-editor
          [gableItem]="rightGableItemForSelectedArea"
          [(gableInfo)]="rightGableInfoForSelectedArea"
          (gableInfoChange)="setChanged()"
        ></swing-flex-gable-editor>
      </subsection-header>
      <!-- #endregion Gable Stuff -->
    </div>

    <div
      *ngIf="selectedSwingFlexArea != null && selectedSubAreaDoorPossible"
      class="swing-area-details"
    >
      <!-- #region door stuff-->
      <subsection-header
        [text]="translate('editor_swingFlex_door_header', 'Door')"
      >
        <button
          type="button"
          class="btn"
          [ngClass]="{
            'btn-primary': selectedSubAreaHasDoor,
            'btn-secondary': !selectedSubAreaHasDoor,
          }"
          (click)="selectedSubAreaHasDoor = !selectedSubAreaHasDoor"
        >
          <span
            class="bi"
            [ngClass]="{
              'bi-check2-square': selectedSubAreaHasDoor,
              'bi-square': !selectedSubAreaHasDoor,
            }"
          ></span>
          {{ translate("editor_swing_swing_subarea_details_hasDoor", "Door") }}
        </button>
        <label> </label>

        <div *ngIf="selectedSubAreaHasDoor">
          <!-- Door Material -->
          <div>
            <h4
              [textContent]="
                translate(
                  'editor_swing_swing_area_details_door_material',
                  'Door Material'
                )
              "
            ></h4>
            <pickable
              [pickable]="selectedSubAreaDoorMaterialPickable"
              class="material door-material modal-opener"
              (click)="selectDoorMaterial(selectedSubArea!)"
            ></pickable>
          </div>

          <div
            *ngIf="
              selectedSwingFlexArea &&
              selectedSwingFlexArea.possibleHingeSides.length > 1
            "
          >
            <!-- Hinge type -->
            <div>
              <h4
                [textContent]="
                  translate(
                    'editor_swing_swing_area_details_hinge_type_header',
                    'Hinge Type'
                  )
                "
              ></h4>
              <select
                class="form-control"
                [(ngModel)]="selectedSubAreaHingeType"
              >
                <option
                  *ngFor="let hingeType of selectedSubArea?.possibleHingeTypes"
                  [ngValue]="hingeType"
                >
                  {{ translateHingeType(hingeType) }}
                </option>
              </select>
            </div>
            <!-- Hinge side -->
            <div>
              <h4
                [textContent]="
                  translate(
                    'editor_swing_swing_area_details_hinge_side_header',
                    'Hinge Side'
                  )
                "
              ></h4>
              <select class="form-control" [(ngModel)]="selectedAreaHingeSide">
                <option
                  *ngFor="
                    let hingeSide of selectedSwingFlexArea.possibleHingeSides
                  "
                  [ngValue]="hingeSide"
                >
                  {{ translateHingeSide(hingeSide) }}
                </option>
              </select>
            </div>
          </div>

          <div
            *ngIf="
              selectedSwingFlexArea &&
              selectedSwingFlexArea.possibleHingeSides.length === 1
            "
          >
            <!-- Left Hinge type -->
            <div>
              <h4
                [textContent]="
                  translate(
                    'editor_swing_swing_area_details_hinge_type_left_header',
                    'Left Hinge Type'
                  )
                "
              ></h4>
              <select
                class="form-control"
                [(ngModel)]="selectedSubAreaHingeTypeLeft"
              >
                <option
                  *ngFor="let hingeType of selectedSubArea?.possibleHingeTypes"
                  [ngValue]="hingeType"
                >
                  {{ translateHingeType(hingeType) }}
                </option>
              </select>
            </div>
            <!-- Right Hinge type -->
            <div>
              <h4
                [textContent]="
                  translate(
                    'editor_swing_swing_area_details_hinge_type_right_header',
                    'Right Hinge Type'
                  )
                "
              ></h4>
              <select
                class="form-control"
                [(ngModel)]="selectedSubAreaHingeTypeRight"
              >
                <option
                  *ngFor="let hingeType of selectedSubArea?.possibleHingeTypes"
                  [ngValue]="hingeType"
                >
                  {{ translateHingeType(hingeType) }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </subsection-header>
      <!-- #endregion door stuff -->

      <!-- #region grip / opening method -->
      <div *ngIf="selectedSubAreaHasDoor">
        <subsection-header
          [text]="translate('editor_swingFlex_grip_header', 'Grip')"
        >
          <!-- Grip -->
          <div>
            <h4
              [textContent]="
                translate(
                  'editor_swing_swing_area_details_opening_method_header',
                  'Opening method'
                )
              "
            ></h4>
            <pickable
              [pickable]="selectedSwingFlexSubAreaDoorGripPickable"
              class="product grip-product modal-opener"
              (click)="selectDoorGripForSubArea()"
            ></pickable>
          </div>

          <div *ngIf="showDoorGripChoices">
            <!-- Grip color -->
            <div>
              <h4
                [textContent]="
                  translate(
                    'editor_swing_swing_area_details_grip_material',
                    'Grip Material'
                  )
                "
              ></h4>
              <pickable
                [pickable]="selectedSwingFlexSubAreaDoorGripMaterialPickable"
                class="material door-material modal-opener"
                (click)="selectDoorGripMaterialForSubArea()"
              ></pickable>
            </div>

            <!-- Grip position -->
            <div>
              <h4
                [textContent]="
                  translate(
                    'editor_swing_swing_area_details_grip_position_header',
                    'Grip position'
                  )
                "
              ></h4>
              <select
                class="form-control"
                [(ngModel)]="selectedSubAreaGripPosition"
              >
                <option
                  *ngFor="let gripPosition of possibleGripPositions"
                  [ngValue]="gripPosition"
                >
                  {{ translateGripPosition(gripPosition) }}
                </option>
              </select>
            </div>
          </div>
        </subsection-header>
      </div>
      <!-- #endregion grip / opening method -->
    </div>

    <!-- #region Middle panel -->
    <div *ngIf="allowMiddlePanel">
      <subsection-header
        [text]="
          translate('editor_swingswingFlex_middle_panel_header', 'Middle Panel')
        "
      >
        <button
          type="button"
          class="btn"
          style="margin-bottom: 12px"
          [ngClass]="{
            'btn-primary': hasMiddlePanel,
            'btn-secondary': !hasMiddlePanel,
          }"
          (click)="hasMiddlePanel = !hasMiddlePanel"
        >
          <span
            class="bi"
            [ngClass]="{
              'bi-check2-square': hasMiddlePanel,
              'bi-square': !hasMiddlePanel,
            }"
          ></span>
          {{
            translate(
              "editor_swing_swing_area_details_middlePanel",
              "Middle panel"
            )
          }}
        </button>

        <div *ngIf="hasMiddlePanel">
          <div class="input-group input-group-sm">
            <span
              class="input-group-text medium-addon"
              [textContent]="
                translate(
                  'editor_swing_swing_area_details_middlePanel_height',
                  'Height'
                )
              "
            >
            </span>
            <select class="form-select" [(ngModel)]="selectedMiddlePanelHeight">
              <option
                *ngFor="let height of availableMiddlePanelHeights"
                [ngValue]="height"
              >
                {{ height }}
              </option>
            </select>
          </div>
        </div>
      </subsection-header>
    </div>

    <div *ngIf="selectedItem != null" class="swing-area-details">
      <item-editor
        [item]="selectedItem"
        (itemChanged)="setChanged()"
        *ngIf="selectedItem"
        class="item-editor"
      ></item-editor>

      <!-- Delete -->
      <button
        class="btn btn-danger btn-block"
        (click)="deleteSelectedItem()"
        [textContent]="translate('swingflex_item_delete_button_text', 'Delete')"
      ></button>

      <error-info
        *ngFor="let errorInfo of selectedItem.errors"
        [showLink]="false"
        [errorInfo]="errorInfo"
      ></error-info>
    </div>
  </div>
  <!-- #endregion -->

  <corner-name-bubble [floorPlan]="floorPlan"></corner-name-bubble>
</div>
