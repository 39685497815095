import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import * as Enums from '@ClientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';

type RenderMethod =
  | 'model2D'
  | 'material'
  | 'noMaterial'
  | 'material2'
  | 'outline';

/** Displays an item in a 2d svg view. */
@Component({
  selector: 'g[app-interior-item]',
  templateUrl: './interior-item.component.html',
  styleUrl: './interior-item.component.scss',
})
export class InteriorItemComponent {
  @Input('app-interior-item')
  public item!: Client.ConfigurationItem;

  /** Shows yellow/red box on top of item */
  @Input()
  public showWarnings: boolean = true;

  /** shows a black-bordered outline around the item instead of the item itself */
  @Input()
  public showStroke: boolean = false;

  /** If false, does not show the item itself, only the content. For displaying rulers or other overlays */
  @Input()
  public showItem: boolean = true;

  @Output()
  public readonly mouseDown = new EventEmitter<MouseEvent | TouchEvent>();

  @Output()
  public readonly mouseUp = new EventEmitter<MouseEvent | TouchEvent>();

  public get cabinetSection() {
    return this.item.cabinetSection;
  }

  public get mirrorTransformString() {
    if (this.item.isMirrored) {
      return ` translate( ${this.item.visualWidth} ) scale( -1 1 ) `;
    } else {
      return '';
    }
  }

  public get itemTransformString(): string {
    let x = this.item.X;
    x -= this.item.frontExtraWidthLeft;

    let y = this.cabinetSection.Height - this.item.topY;

    let result = ` translate( ${x} , ${y} ) `;

    if (this.item.rotation === Enums.ItemRotation.Clockwise90DegreesZ) {
      result += ' rotate(-90)';
    } else if (
      this.item.rotation === Enums.ItemRotation.CounterClockwise90DegreesZ
    ) {
      result += ' rotate(-90)';
    }
    return result;
  }

  public get renderMethod(): RenderMethod {
    if (this.showStroke) return 'outline';
    if (this.item.getModel2DPath()) return 'model2D';
    const mat = this.item.Material;
    if (mat) {
      if (mat.TexturePath && mat.TextureWidthMm && mat.TextureHeightMm)
        return 'material2';
      if (mat.ImagePath) return 'material';
    }

    return 'noMaterial';
  }

  get material2ClipPath() {
    // https://developer.mozilla.org/en-US/docs/Web/CSS/basic-shape/rect
    // rect( <top> , <right> , <bottom> , <left> )
    return `rect(0px ${this.item.visualWidth}px ${this.item.Height}px 0px )`;
  }
  get material2Path(): string | undefined {
    const p = this.item.Material?.TexturePath;
    if (!p) return;
    return `/StaticAssets/images/Materials2/${p}/map.jpg`;
  }

  private get shouldRotateMaterial() {
    return this.item.isShelf;
  }
  private get isMaterial2() {
    return !!(
      this.item.Material?.TexturePath &&
      this.item.Material?.TextureHeightMm &&
      this.item.Material?.TextureWidthMm
    );
  }
  get materialWidth() {
    return this.shouldRotateMaterial
      ? this.materialHeightUnrotated
      : this.materialWidthUnrotated;
  }
  get materialWidthUnrotated() {
    if (!this.item.Material?.TexturePath) return this.item.visualWidth;
    return this.item.Material?.TextureWidthMm ?? this.item.visualWidth;
  }

  get materialHeight() {
    return this.shouldRotateMaterial
      ? this.materialWidthUnrotated
      : this.materialHeightUnrotated;
  }

  get materialHeightUnrotated() {
    if (!this.item.Material?.TexturePath) return this.item.Height;
    return this.item.Material?.TextureHeightMm ?? this.item.Height;
  }

  get materialRotateTransform() {
    if (!this.shouldRotateMaterial) return '';
    if (this.isMaterial2) {
      return `rotate(90 0 0) translate(0 -${this.materialWidth})`;
    } else {
      return `rotate(90 0 0) translate(0 -${this.materialWidthUnrotated})`;
    }
  }

  /** A string that *should* be unique to this item */
  get idPostfix() {
    return `${this.cabinetSection.CabinetSectionIndex}_item_${this.item.PositionNumber}_${this.item.X}_${this.item.Y}`;
  }

  public get errorOverlayStyle() {
    let style = 'pointer-events: none; ';
    if (this.showWarnings) {
      switch (this.item.maxErrorLevel) {
        case Interface_Enums.ErrorLevel.Info:
          style += 'fill: rgba(0, 0, 255, 0.3);';
          break;
        case Interface_Enums.ErrorLevel.Warning:
          style += 'fill: rgba(255, 165, 0, 0.3);';
          break;
        case Interface_Enums.ErrorLevel.Critical:
          style += 'fill: rgba(255, 0, 0, 0.5);';
          break;
        default:
          style += 'fill: rgba(0, 0, 0, 0);';
          break;
      }
    } else {
      style += 'fill: rgba(0, 0, 0, 0);';
    }

    return style;
  }
}
