<div class="product-options" *ngIf="item">
  <select
    class="form-select"
    *ngIf="alternateProducts.length > 0"
    [(ngModel)]="item.Product"
    (ngModelChange)="setChanged()"
  >
    <option *ngFor="let product of alternateProducts" [ngValue]="product">
      {{product.Name}}
    </option>
  </select>

  <pickable
    *ngFor="let matInfo of Materials"
    [pickable]="matInfo.materialPickable"
    class="material interior-item-material modal-opener"
    (click)="matInfo.openModal()"
  ></pickable>

  <div *ngIf="showGripSelector">
    <label>
      <span
        [textContent]="translate('item_property_label_grip', 'Grip')"
      ></span>
    </label>
    <pickable
      [pickable]="currentGripPickable"
      class="product grip-product modal-opener"
      (click)="selectGrip()"
    ></pickable>

    <div *ngIf="item.gripProduct">
      <label>
        <span
          [textContent]="translate('item_property_label_grip_material', 'Grip Material')"
        ></span>
      </label>
      <pickable
        [pickable]="currentGripMaterialPickable"
        class="material grip-material modal-opener"
        (click)="selectGripMaterial()"
      ></pickable>
    </div>
  </div>

  <label>
    <span
      [textContent]="translate('item_property_label_width', 'Width')"
    ></span>
    <span class="input-group input-group-sm">
      <input
        class="form-control"
        type="number"
        [disabled]="!item.isFlexWidth"
        [attr.min]="item.minWidth"
        [attr.max]="item.maxWidth"
        #width1Inp
        [value]="width"
        (change)="width = width1Inp.valueAsNumber; widthChanged()"
      />
      <span class="input-group-text">mm</span>
    </span>
  </label>

  <label *ngIf="item.hasWidth2">
    <span
      [textContent]="translate('item_property_label_width2', 'Width 2')"
    ></span>
    <span class="input-group input-group-sm">
      <input
        class="form-control"
        type="number"
        [attr.min]="item.minWidth"
        [attr.max]="item.maxWidth"
        #width2Inp
        [value]="width2"
        (change)="width2 = width2Inp.valueAsNumber"
      />
      <span class="input-group-text">mm</span>
    </span>
  </label>

  <label for="flexheight">
    <span
      [textContent]="translate('item_property_label_height', 'Height')"
    ></span>

    <span class="input-group input-group-sm">
      <input
        class="form-control"
        type="number"
        [disabled]="!item.isFlexHeight"
        [attr.min]="item.minHeight"
        [attr.max]="item.maxHeight"
        #heightInp
        [value]="height"
        (change)="height = heightInp.valueAsNumber; heightChanged()"
      />
      <span class="input-group-text">mm</span>
    </span>
  </label>
  <label>
    <span
      [textContent]="translate('item_property_label_depth', 'Depth')"
    ></span>
    <span class="input-group input-group-sm">
      <input
        class="form-control"
        type="number"
        [disabled]="!item.isFlexDepth"
        [attr.min]="item.minDepth"
        [attr.max]="item.maxDepth"
        #depth1Inp
        [value]="depth"
        (change)="depth = depth1Inp.valueAsNumber; depthChanged()"
      />
      <span class="input-group-text">mm</span>
    </span>
  </label>
  <label *ngIf="item.hasDepth2">
    <span
      [textContent]="translate('item_property_label_depth2', 'Depth 2')"
    ></span>
    <span class="input-group input-group-sm">
      <input
        class="form-control"
        type="number"
        [disabled]="!item.isFlexDepth"
        [attr.min]="item.minDepth"
        [attr.max]="item.maxDepth"
        #depth2Inp
        [value]="depth2"
        (change)="depth2 = depth2Inp.valueAsNumber; depthChanged()"
      />
      <span class="input-group-text">mm</span>
    </span>
  </label>

  <label
    *ngIf="showDrillDepth"
    for="drillDepth"
    [title]="translate('item_property_drillDepth_hint', 'Must have a value different from 0 (zero)')"
  >
    <span
      [textContent]="translate('item_property_label_drillDepth', 'Drill depth')"
    ></span>
    <span class="input-group input-group-sm">
      <input
        class="form-control"
        type="number"
        [placeholder]="translate('item_property_drillDepth_hint', 'Must have a value different from 0 (zero)')"
        [ngModelOptions]="modelOptions"
        [(ngModel)]="drillDepth"
        id="drillDepth"
        [required]="drillDepthRequired"
      />
    </span>
  </label>

  <div *ngIf="canChangePosX">
    <span
      [textContent]="translate('item_property_label_pos_x', 'Position')"
    ></span>
    <span class="input-group input-group-sm">
      <button
        (mousedown)="startShiftPosX(-1)"
        (mouseup)="stopShiftPosX()"
        (mouseleave)="stopShiftPosX()"
        class="btn btn-default"
        [attr.title]="translate('item_property_pos_x_left', 'Move left')"
      >
        <span class="bi bi-arrow-left"></span>
      </button>
      <button
        (mousedown)="startShiftPosX(1)"
        (mouseup)="stopShiftPosX()"
        (mouseleave)="stopShiftPosX()"
        class="btn btn-default"
        [attr.title]="translate('item_property_pos_x_right', 'Move right')"
      >
        <span class="bi bi-arrow-right"></span>
      </button>
      <input
        class="form-control no-spinner"
        type="number"
        [attr.min]="posXMin"
        [attr.max]="posXMax"
        [ngModelOptions]="modelOptions"
        [(ngModel)]="posX"
      />
      <span class="input-group-text">mm</span>
    </span>
  </div>

  <div class="item-variants" *ngIf="allowUserVariants">
    <label
      *ngFor="let itemVariant of item.UserSelectableItemVariants"
      [attr.data-item-variant]="itemVariant.variant?.Number"
    >
      <span *ngIf="displayAdminOnlyVariants || !itemVariant.OnlyAdmin">
        <span
          *ngIf="itemVariant.OnlyAdmin"
          [attr.title]="translate('editor_variant_only_admin', 'This variant can only be changed by support')"
          class="bi bi-exclamation-triangle-fill"
          style="color: goldenrod"
        ></span>
        <span [textContent]="itemVariant.variant?.DefaultName"></span>
        <select
          class="form-select"
          [(ngModel)]="itemVariant.variantOption"
          (ngModelChange)="setChanged()"
        >
          <option
            *ngFor="let opt of itemVariant.variant?.VariantOptions"
            [attr.data-variant-option]="opt.Number"
            [ngValue]="opt"
          >
            {{opt.DefaultName}}
          </option>
        </select>
      </span>
    </label>
  </div>

  <div class="spacer" style="height: 5px"></div>
  <div>
    <label
      [hidden]="!isHeightReductionPossible"
      [attr.title]="translate('item_property_label_height_reduction_tooltip', 'Cuts the item to the desired height from the factory.')"
    >
      <span
        *ngIf="warnAboutHeightReduction"
        [attr.title]="translate('editor_variant_only_admin', 'This variant can only be changed by support')"
        class="bi bi-exclamation-triangle-fill"
        style="color: goldenrod"
      ></span>
      <input type="checkbox" [(ngModel)]="heightReduction" />
      <span
        [textContent]="translate('item_property_label_height_reduction', 'Height Reduction')"
      ></span>
    </label>
  </div>
  <div></div>
  <div>
    <label>
      <input type="checkbox" [(ngModel)]="locked" />
      <span
        [textContent]="translate('item_property_label_locked', 'Lock Choices')"
      ></span>
    </label>
  </div>
</div>

<div class="product-options">
  <pickable
    class="product draggable"
    [pickable]="pickableProduct"
    (mousedown)="startDrag($event)"
    (mouseup)="stopDrag($event)"
    (touchstart)="touchStart($event)"
    (touchend)="touchEnd($event)"
    (touchmove)="touchMove($event)"
  ></pickable>
</div>
