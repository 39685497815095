<svg:g
  [attr.transform]="itemTransformString"
  (mousedown)="mouseDown.emit($event)"
  (touchstart)="mouseDown.emit($event)"
  [attr.data-description]="item.Description"
  [attr.data-render-method]="renderMethod"
  [ngClass]="{ 'grip-item': item.isGrip }"
>
  <g *ngIf="!item.isTemplate && showItem">
    <g class="item-image" [attr.transform]="mirrorTransformString">
      <image
        *ngIf="renderMethod === 'model2D'"
        preserveAspectRatio="none"
        href=""
        [attr.href]="item.getModel2DPath()"
        width="0"
        [attr.width]="item.visualWidth"
        height="0"
        [attr.height]="item.Height"
      />

      <g *ngIf="renderMethod === 'material2'">
        <defs>
          <!-- A tiling pattern for the material. -->
          <pattern
            [attr.id]="idPostfix"
            [attr.width]="materialWidth"
            [attr.height]="materialHeight"
            patternUnits="userSpaceOnUse"
          >
            <g [attr.transform]="materialRotateTransform">
              <image
                class="item-material2 mat2-br"
                preserveAspectRatio="none"
                href=""
                x="0"
                y="0"
                [attr.href]="material2Path"
                width="0"
                [attr.width]="item.Material!.TextureWidthMm"
                height="0"
                [attr.height]="item.Material!.TextureHeightMm"
              />
            </g>
          </pattern>
        </defs>
        <rect
          [attr.fill]="'url(#' + idPostfix + ')'"
          class="item-material2-rect"
          style="stroke: gray; stroke-width: 2px"
          x="0"
          y="0"
          width="0"
          [attr.width]="item.visualWidth"
          height="0"
          [attr.height]="item.Height"
        ></rect>
      </g>

      <g *ngIf="renderMethod === 'material'">
        <defs>
          <pattern
            [attr.id]="idPostfix"
            [attr.width]="materialWidth"
            [attr.height]="materialHeight"
            patternUnits="userSpaceOnUse"
          >
            <g [attr.transform]="materialRotateTransform">
              <g>
                <image
                  class="item-material mat-br"
                  preserveAspectRatio="none"
                  href=""
                  x="0"
                  y="0"
                  [attr.href]="item.Material?.ImagePath"
                  width="0"
                  [attr.width]="materialWidth"
                  height="0"
                  [attr.height]="materialHeight"
                />
              </g>
            </g>
          </pattern>
        </defs>
        <rect
          [attr.fill]="'url(#' + idPostfix + ')'"
          class="item-material-rect"
          style="stroke: gray; stroke-width: 2px"
          x="0"
          y="0"
          width="0"
          [attr.width]="item.visualWidth"
          height="0"
          [attr.height]="item.Height"
        ></rect>
      </g>

      <rect
        class="item-no-img-fallback"
        *ngIf="renderMethod === 'noMaterial'"
        style="stroke: gray; stroke-width: 2px; fill: rgba(0, 0, 0, 0.01)"
        width="0"
        [attr.width]="item.visualWidth"
        height="0"
        [attr.height]="item.Height"
      ></rect>

      <rect
        class="item-no-img-fallback"
        *ngIf="renderMethod === 'outline'"
        style="stroke: #000000; stroke-width: 5px; fill: rgba(0, 0, 0, 0.05)"
        width="0"
        [attr.width]="item.visualWidth"
        height="0"
        [attr.height]="item.Height"
      ></rect>

      <rect
        width="0"
        height="0"
        class="item-mouse-area"
        style="fill: rgba(0, 0, 0, 0)"
        [attr.height]="item.mouseClickOffset.Height"
        [attr.width]="item.mouseClickOffset.Width"
        [attr.x]="item.mouseClickOffset.X"
        [attr.y]="item.mouseClickOffset.Y"
        (mouseup)="mouseUp.emit($event)"
      ></rect>
    </g>
  </g>

  <ng-content></ng-content>

  <rect
    *ngIf="showItem &amp;&amp; showWarnings"
    class="errorOverlay"
    [attr.data-error-level]="item.maxErrorLevel"
    [attr.style]="errorOverlayStyle"
    x="0"
    y="0"
    width="1"
    height="1"
    [attr.width]="item.visualWidth - 2"
    [attr.height]="item.Height - 2"
  ></rect>
</svg:g>
