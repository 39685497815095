import {
  Component,
  HostListener,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import * as App from 'app/ts/app';
import { EditorVm } from '../EditorVm';
import { BaseVmService } from '@Services/BaseVmService';
import { FloorPlanService } from '@Services/FloorPlanService';
import { FloorPlanSaveService } from '@Services/FloorPlanSaveService';
import { RouteService } from '@Services/RouteService';
import { DeliveryAddressService } from '@Services/DeliveryAddressService';
import { WindowService } from '../../ng/window.service';
import { ModalService } from 'app/ts/services/ModalService';
import { AppRoutingModule } from '../../routing/app-routing.module';
import { Pickable } from 'app/ts/interfaces/Pickable';
import { ModelOptions } from 'app/ng/ModelOptions';
import { ProductHelper } from '@Util/ProductHelper';
import { MaterialHelper } from '@Util/MaterialHelper';
import * as Client from '@ClientDto/index';
import * as Enums from '@ClientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import {
  ClientSettingInjector,
  ClientSetting,
  ClientSettingData,
  SwingFlexSetting,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { ConfigurationItemService } from '@Services/ConfigurationItemService';
import { SelectedItemsService } from '../selected-items.service';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import Enumerable from 'linq';
import { DragInfoService } from '../drag-info.service';
import { SwingFlexSubArea } from '@ClientDto/SwingFlexSubArea';
import { Observable } from '@Util/Observable';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CabinetSectionProperties } from 'app/ts/properties/CabinetSectionProperties';

type GableInfo = CabinetSectionProperties.SwingFlexGableInfo;
export type SubModule = { val: number; name: string };

@Component({
  selector: 'swing-flex',
  templateUrl: './swing-flex.component.html',
  styleUrls: ['./swing-flex.component.scss'],
  providers: [clientSettingProvider, DeliveryAddressService],
})
export class SwingFlexComponent extends EditorVm {
  public readonly possibleOpeningMethods = [
    Enums.SwingFlexOpeningMethod.PushToOpen,
    Enums.SwingFlexOpeningMethod.Grip,
  ];

  public readonly possibleGripPositions = [
    Enums.SwingFlexGripPosition.Auto,
    Enums.SwingFlexGripPosition.Top,
    Enums.SwingFlexGripPosition.Middle,
    Enums.SwingFlexGripPosition.Bottom,
  ];

  // Timeout in ms for re-opening the materials/grip dropdown.
  // Using timeout function because dropdown is closing after selecting option. Can't use dropdown is-open variable because component is destroyed after selecting option.
  private materialsGripDropdownOpenTimeout: number = 15;

  private clientSettingsData!: ClientSettingData;
  private _selectedSubCategory: Client.ProductCategory | undefined;

  private cache: Partial<{
    corpusMaterialPickable: Pickable<Client.Material | null>;
    doorMaterialPickable: Pickable<Client.Material | null>;
    backingMaterialPickable: Pickable<Client.Material | null>;
    doorGripPickable: Pickable<
      Client.Product | Enums.SwingFlexOpeningMethod.PushToOpen | null
    >;
    drawerGripPickable: Pickable<Client.Product | null>;
    doorGripMaterialPickable: Pickable<Client.Material | null>;
    drawerGripMaterialPickable: Pickable<Client.Material | null>;
    doorSplitterProducts: Client.Product[];
    doorSplitterPickables: Pickable<Client.Product>[];
    filteredProductCategories: Client.ProductCategory[];
    subCategories: Client.ProductCategory[];
    products: Client.Product[];
    productPickables: Pickable<Client.Product>[];
    plinthAvailable: boolean;
    drawerGripPossible: boolean;
  }> = {};

  private selectionCache: Partial<{
    subAreaDoorMaterialPickable: Pickable<Client.Material | null>;
    subAreaDoorGripPickable: Pickable<
      Client.Product | Enums.SwingFlexOpeningMethod.PushToOpen | null
    >;
    subAreaDoorGripMaterialPickable: Pickable<Client.Material | null>;

    drawerGripPickable: Pickable<Client.Product | null>;
    drawerGripMaterialPickable: Pickable<Client.Material | null>;
  }> = {};

  private ongoingTouch: { observable: Observable<TouchEvent> } | undefined;

  public isAdvancedMenuOpen: boolean = false;
  public itemPropertyModelOptions: ModelOptions = {
    ...this.modelOptions,
  };
  public selectionObservable: Subject<Client.SwingFlexArea | null>;
  private _selectedSwingFlexArea: Client.SwingFlexArea | null = null;

  public selectionSubAreaObservable: Subject<SwingFlexSubArea | null>;
  private _selectedSubArea: SwingFlexSubArea | null = null;

  public itemSelectionObservable: Subject<Client.ConfigurationItem | null>;
  private _selectedItem: Client.ConfigurationItem | null = null;

  constructor(
    baseVmService: BaseVmService,
    $window: WindowService,
    floorPlanService: FloorPlanService,
    floorPlanSaveService: FloorPlanSaveService,
    routeService: RouteService,
    routing: AppRoutingModule,
    deliveryAddressService: DeliveryAddressService,
    private readonly dragInfo: DragInfoService,
    @Inject(ClientSettingInjector) private clientSetting: ClientSetting,
    private readonly modalService: ModalService,
    private readonly configurationItemService: ConfigurationItemService,
    private readonly selectedItems: SelectedItemsService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorPlanSaveService,
      routeService,
      routing,
      deliveryAddressService,
    );

    this.selectionObservable = new Subject();
    this.selectionSubAreaObservable = new Subject();
    this.itemSelectionObservable = new Subject();

    super.ensureUnsubscribe(
      clientSetting.subscribe((next) => {
        this.clientSettingsData = next;
      }),
    );

    let lastSelection: Client.SwingFlexArea | null = null;
    this.ensureUnsubscribe(
      this.selectionObservable.subscribe((newSelection) => {
        if (lastSelection !== newSelection) {
          this.clearSelectionCache();
          if (App.debug.showSelectionChanges) {
            console.debug('SwingFlexComponent selectionChange: ', newSelection);
          }
        }
        lastSelection = newSelection;

        this._selectedSwingFlexArea = newSelection;
      }),
    );

    let previousSelectedSubArea: SwingFlexSubArea | null = null;
    this.ensureUnsubscribe(
      this.selectionSubAreaObservable.subscribe((newSelectedSubArea) => {
        if (previousSelectedSubArea !== newSelectedSubArea) {
          this.clearSelectionCache();
          if (App.debug.showSelectionChanges) {
            console.debug(
              'SwingFlexComponent selectionChange: ',
              newSelectedSubArea,
            );
          }
        }
        previousSelectedSubArea = newSelectedSubArea;

        this._selectedSubArea = newSelectedSubArea;
      }),
    );

    let previousSelectedItem: Client.ConfigurationItem | null = null;
    this.ensureUnsubscribe(
      this.itemSelectionObservable.subscribe((newSelectedItem) => {
        this.clearSelectionCache();
        if (previousSelectedItem !== newSelectedItem) {
          if (App.debug.showSelectionChanges) {
            console.debug(
              'SwingFlexComponent selectionChange: ',
              newSelectedItem,
            );
          }
        }
        previousSelectedItem = newSelectedItem;
        this._selectedItem = newSelectedItem;
      }),
    );
  }

  @HostListener('window:keydown', ['$event'])
  private handleKeyboardEvent2(evt: KeyboardEvent | null) {
    if (!evt) return;

    if (evt.defaultPrevented) return;

    if (
      evt.code === 'Delete' ||
      evt.key === 'Del' ||
      evt.key === 'Delete' ||
      evt.keyCode === 46
    ) {
      this.deleteSelectedItem();
      evt.stopPropagation();
      evt.preventDefault();
    }
  }

  ngOnInit(): void {
    this.subscribeTo(this.cabinetSection?.cabinet.floorPlan.floorPlan$, (fp) =>
      this.clearCache(),
    );
  }

  @ViewChild('materialsDropdown', { static: false }) materialsDropdown:
    | NgbDropdown
    | undefined;
  @ViewChild('gripDropdown', { static: false }) gripDropdown:
    | NgbDropdown
    | undefined;

  @Input()
  public isItemsDraggable: boolean = true;

  public get showRulers(): boolean {
    return this.clientSettingsData.swingFlex.displayRulers;
  }
  public set showRulers(val: boolean) {
    this.setSwingFlexSetting({ displayRulers: val });
  }

  public get showCorpus(): boolean {
    return this.clientSettingsData.swingFlex.displayCorpus;
  }
  public set showCorpus(val: boolean) {
    this.setSwingFlexSetting({ displayCorpus: val });
  }

  public get showInterior(): boolean {
    return this.clientSettingsData.swingFlex.displayInterior;
  }
  public set showInterior(val: boolean) {
    this.setSwingFlexSetting({ displayInterior: val });
  }

  public get showDoors(): boolean {
    return this.clientSettingsData.swingFlex.displayDoors;
  }
  public set showDoors(val: boolean) {
    this.setSwingFlexSetting({ displayDoors: val });
  }

  public get showTopdown() {
    return this.clientSettingsData.swingFlex.displayTopdown;
  }
  public set showTopdown(val: boolean) {
    this.setSwingFlexSetting({ displayTopdown: val });
  }

  public get showHinges() {
    return this.clientSettingsData.swingFlex.displayHinges;
  }
  public set showHinges(val: boolean) {
    this.setSwingFlexSetting({ displayHinges: val });
  }

  public get showDoorGripChoices(): boolean {
    return (
      this.selectedSubArea !== null &&
      this.selectedSubArea.openingMethod ===
        Enums.SwingFlexOpeningMethod.Grip &&
      !!this.selectedSubArea.doorGrip
    );
  }

  public get corpusMaterialPickable(): Pickable<Client.Material | null> {
    if (!this.cache.corpusMaterialPickable) {
      let pickable: Pickable<Client.Material | null>;
      let material = this.cabinetSection!.swingFlex.corpusMaterial;
      if (material) {
        pickable = MaterialHelper.getPickable(material);
      } else {
        pickable = {
          item: null,
          name: this.translate(
            'editor_swing_no_corpus_material_selected',
            'Select Corpus Material',
          ),
          isSelected: true,
          override: false,
          disabledReason: null,
        };
      }
      this.cache.corpusMaterialPickable = pickable;
    }
    return this.cache.corpusMaterialPickable;
  }

  public get doorMaterialPickable(): Pickable<Client.Material | null> {
    if (!this.cache.doorMaterialPickable) {
      let pickable: Pickable<Client.Material | null>;
      let material = this.cabinetSection!.swingFlex.doorMaterial;
      if (material) {
        pickable = MaterialHelper.getPickable(material);
      } else {
        pickable = {
          item: null,
          name: this.translate(
            'editor_swing_no_door_material_selected',
            'Select Door Material',
          ),
          isSelected: true,
          override: false,
          disabledReason: null,
        };
      }
      pickable.overlay = this.translate(
        'editor_swing_door_material_overlay',
        'Door',
      );
      this.cache.doorMaterialPickable = pickable;
    }
    return this.cache.doorMaterialPickable;
  }

  public get doorGripPickable(): Pickable<
    Client.Product | Enums.SwingFlexOpeningMethod.PushToOpen | null
  > {
    if (!this.cache.doorGripPickable) {
      let pickable: Pickable<
        Client.Product | Enums.SwingFlexOpeningMethod.PushToOpen | null
      >;
      let product = this.cabinetSection!.swingFlex.doorGrip;
      if (
        this.cabinetSection?.swingFlex.doorOpeningMethod ===
        Enums.SwingFlexOpeningMethod.PushToOpen
      ) {
        pickable = this.cabinetSection.swingFlex.pushToOpenPickable;
      } else if (product) {
        pickable = ProductHelper.getPickable(product);
      } else {
        pickable = { ...this.cabinetSection!.interior.noGripPickable };
      }
      pickable.overlay = this.translate(
        'editor_swingFlex_door_grip_overlay',
        'Door Grip',
      );

      this.cache.doorGripPickable = pickable;
    }
    return this.cache.doorGripPickable;
  }

  public get doorGripMaterialPickable(): Pickable<Client.Material | null> {
    if (!this.cache.doorGripMaterialPickable) {
      let pickable: Pickable<Client.Material | null>;
      let material = this.cabinetSection!.swingFlex.doorGripMaterial;
      if (material) {
        pickable = MaterialHelper.getPickable(material);
      } else {
        pickable = {
          item: null,
          name: this.translate(
            'editor_swingFlex_no_door_grip_material_selected',
            'Select Grip Material',
          ),
          isSelected: true,
          override: false,
          disabledReason: null,
        };
      }
      pickable.overlay = this.translate(
        'editor_swingFlex_door_grip_material_overlay',
        'Door Grip Material',
      );
      this.cache.doorGripMaterialPickable = pickable;
    }
    return this.cache.doorGripMaterialPickable;
  }

  public get drawerGripPickable(): Pickable<Client.Product | null> {
    if (!this.cache.drawerGripPickable) {
      let pickable: Pickable<Client.Product | null>;
      let product = this.cabinetSection!.swingFlex.drawerGrip;
      if (product) {
        pickable = ProductHelper.getPickable(product);
      } else {
        pickable = { ...this.cabinetSection!.interior.noGripPickable };
      }
      pickable.overlay = this.translate(
        'editor_swingFlex_drawer_grip_overlay',
        'Drawer Grip',
      );
      this.cache.drawerGripPickable = pickable;
    }
    return this.cache.drawerGripPickable;
  }

  public get drawerGripMaterialPickable(): Pickable<Client.Material | null> {
    if (!this.cache.drawerGripMaterialPickable) {
      let pickable: Pickable<Client.Material | null>;
      let material = this.cabinetSection!.swingFlex.drawerGripMaterial;
      if (material) {
        pickable = MaterialHelper.getPickable(material);
      } else {
        pickable = {
          item: null,
          name: this.translate(
            'editor_swingFlex_no_drawer_grip_material_selected',
            'No Grip Material',
          ),
          isSelected: true,
          override: false,
          disabledReason: null,
        };
      }
      pickable.overlay = this.translate(
        'editor_swingFlex_drawer_grip_material_overlay',
        'Drawer Grip Material',
      );
      this.cache.drawerGripMaterialPickable = pickable;
    }
    return this.cache.drawerGripMaterialPickable;
  }

  public get backingMaterialPickable(): Pickable<Client.Material | null> {
    if (!this.cache.backingMaterialPickable) {
      let pickable: Pickable<Client.Material | null>;
      let material = this.cabinetSection!.swingFlex.backingMaterial;
      if (material) {
        pickable = MaterialHelper.getPickable(material);
      } else {
        pickable = {
          item: null,
          name: this.translate(
            'editor_swing_no_backing_material_selected',
            'Select Backing Material',
          ),
          isSelected: true,
          override: false,
          disabledReason: null,
        };
      }
      this.cache.backingMaterialPickable = pickable;
    }
    return this.cache.backingMaterialPickable;
  }

  public get productCategories() {
    var editorAssets = this.cabinetSection!.editorAssets;

    return editorAssets.productCategoriesByProductLine[
      this.cabinetSection!.cabinet.ProductLineId
    ];
  }

  public get filteredProductCategories(): Client.ProductCategory[] {
    if (!this.cache.filteredProductCategories) {
      this.cache.filteredProductCategories = this.productCategories.filter(
        (pc) => this.filterProductCategory(pc),
      );
    }
    return this.cache.filteredProductCategories;
  }

  private filterProductCategory(
    productCategory: Client.ProductCategory,
  ): boolean {
    return productCategory.anyProduct(
      (product, cat) =>
        ProductHelper.isProductDataType(
          Interface_Enums.ProductDataType.SwingFlexDoorBreaker,
          product,
        ) && this.filterProductGroup(product, this.productFilter),
    );
  }

  public get subCategories(): Client.ProductCategory[] {
    if (!this.cache.subCategories) {
      let actualCategories: Client.ProductCategory[] = [];

      this.productCategories.forEach((pc) => {
        if (pc.Children.length > 0) {
          pc.Children.forEach((child) => {
            actualCategories.push(child);
          });
        } else {
          actualCategories.push(pc);
        }
      });

      this.cache.subCategories = actualCategories.filter((pc) =>
        this.filterProductCategory(pc),
      );
    }
    return this.cache.subCategories;
  }

  public get selectedSubCategory(): Client.ProductCategory | undefined {
    if (
      !this._selectedSubCategory ||
      this.subCategories.indexOf(this._selectedSubCategory) < 0
    ) {
      this._selectedSubCategory = this.subCategories[0];
    }
    return this._selectedSubCategory;
  }
  public set selectedSubCategory(val: Client.ProductCategory | undefined) {
    this._selectedSubCategory = val;
    this.clearCache();
    this.scrollProductsToTop();
  }

  private scrollProductsToTop() {
    try {
      window.document.getElementsByClassName('product-list')[0].scrollTo(0, 0);
    } catch (e: any) {
      //ignore
    }
  }

  public get products(): Client.Product[] {
    if (!this.cache.products) {
      if (!this.selectedSubCategory) {
        this.cache.products = [];
      } else {
        let products = this.selectedSubCategory.products;
        this.cache.products = products.filter((prod) =>
          this.filterProductGroup(prod, this.productFilter),
        );
      }
    }
    return this.cache.products;
  }

  public get productPickables(): Pickable<Client.Product>[] {
    if (!this.cache.productPickables) {
      this.cache.productPickables = this.products.map((p) => {
        var pick = ProductHelper.getPickableAsProductGroup(p);
        if (
          p.ProductLineIds.indexOf(this.cabinetSection!.cabinet.ProductLineId) <
          0
        ) {
          let existingWarning = pick.warnReason ? pick.warnReason + '\n' : '';
          let warning = this.translate(
            'productlist_product_not_in_line_tooltip',
            'Product not in product line',
          );
          pick.warnReason = existingWarning + warning;
        }
        return pick;
      });
    }
    return this.cache.productPickables;
  }

  // Filter
  //#region

  public get fullCatalog() {
    return (
      this.cabinetSection!.editorAssets.fullCatalog &&
      this.cabinetSection!.cabinet.floorPlan.FullCatalogAllowOtherProducts
    );
  }

  private get showOtherMaterials() {
    return this.cabinetSection!.cabinet.floorPlan
      .FullCatalogAllowOtherMaterials;
  }

  private _productFilter: string = '';

  public get productFilter(): string {
    return this._productFilter;
  }

  public set productFilter(val: string) {
    this._productFilter = val;
    this.clearCache();
  }

  private filterProductGroup(
    prod: Client.Product,
    searchString: string,
  ): boolean {
    return prod.productGroup.some((subProduct) =>
      this.filterProduct(subProduct, searchString),
    );
  }
  private filterProduct(
    subProduct: Client.Product,
    searchString: string,
  ): boolean {
    return (
      this.isAvailable(subProduct) &&
      this.productMatchesSearch(subProduct, searchString, this.fullCatalog) &&
      ProductHelper.isProductDataType(
        Interface_Enums.ProductDataType.SwingFlexDoorBreaker,
        subProduct,
      )
    );
  }

  private isAvailable(product: Client.Product): boolean {
    if (!this.fullCatalog) {
      if (product.overrideChain) return false;

      const productData = product.getProductData();
      if (
        productData &&
        productData.Status === Interface_Enums.ProductDataStatus.Discontinued
      )
        return false;
    }
    if (!product.Enabled) return false;
    if (
      product.ProductLineIds.indexOf(
        this.cabinetSection!.cabinet.ProductLineId,
      ) < 0
    )
      return false;
    if (!this.showOtherMaterials && product.hasOnlyDisabledMaterials())
      return false;
    return true;
  }

  private productMatchesSearch(
    prod: Client.Product,
    searchString: string,
    fullCatalog: boolean,
  ): boolean {
    if (!searchString) return true;
    return (
      this.nameSearch(prod.Name, searchString) ||
      this.numberSearch(prod.ProductNo, searchString) ||
      this.getVariantOptions(searchString, prod, fullCatalog).length > 0
    );
  }
  private nameSearch(haystack: string, needle: string): boolean {
    return haystack.toLowerCase().indexOf(needle.toLowerCase()) > -1;
  }
  private numberSearch(haystack: string, needle: string): boolean {
    return haystack.toLowerCase().indexOf(needle.toLowerCase()) === 0;
  }

  private getVariantOptions(
    filter: string,
    product: Client.Product,
    useDisabledColors: boolean,
  ): Interface_DTO.VariantOption[] {
    filter = filter.toLowerCase();
    if (!this.numberSearch(filter, product.ProductNo)) return [];
    let remainingfFilter = filter.substr(product.ProductNo.length);

    let options: Interface_DTO.VariantOption[] = [];
    let variantIds = [
      product.AddVariant1,
      product.AddVariant2,
      product.AddVariant3,
    ];

    nextAddVariant: for (let id of variantIds) {
      if (id < 0) continue;
      for (let variant of product.getAllVariants()) {
        if (variant.Id == id) {
          for (let vo of variant.VariantOptions) {
            let needle = vo.Number;
            if (remainingfFilter.indexOf(needle) === 0) {
              remainingfFilter = remainingfFilter.substr(needle.length);

              //check if the variant option is valid for the product (ie. the option is not discontinued)
              if (!useDisabledColors) {
                if (!vo.Enabled) continue nextAddVariant;

                if (
                  variant.Number === VariantNumbers.Color ||
                  variant.Number === VariantNumbers.FrameColor ||
                  variant.Number === VariantNumbers.SpotColor ||
                  variant.Number === VariantNumbers.DoorFillingColor ||
                  variant.Number === VariantNumbers.DoorFillingColorDetailed
                ) {
                  var mat = Enumerable.from(product.materials).firstOrDefault(
                    (m) => m.Number === vo.Number,
                  );
                  if (!mat || mat.isDiscontinued || mat.isOverride) {
                    continue nextAddVariant;
                  }
                }
              }

              options.push(vo);
              continue nextAddVariant;
            }
          }
        }
      }
    }

    return options;
  }

  //#endregion

  public createItemFromProduct(
    product: Client.Product | undefined,
    variants: Interface_DTO.VariantOption[] | undefined,
  ): void {
    if (!product) {
      this.selectedItems.value = [];
      return;
    }

    // Drawers use door material, others use corpus material
    let materialId: number | null = ProductHelper.isPullout(product)
      ? (this.cabinetSection!.swingFlex?.doorMaterial?.Id ?? null)
      : this.cabinetSection!.CorpusMaterialId;

    if (materialId === null) {
      if (product.materials.length > 0) {
        materialId = product.materials[0].Id;
      }
    }

    let item: Client.ConfigurationItem =
      this.configurationItemService.createConfigurationItem(
        Interface_Enums.ItemType.SwingFlexCorpusMovable,
        product.Id,
        materialId,
        this.cabinetSection!,
        1,
        variants,
      );

    if (item.canHaveGrip) {
      let gripProduct = this.cabinetSection!.swingFlex.drawerGrip;
      let gripMaterial =
        this.cabinetSection!.swingFlex.drawerGripMaterial ??
        MaterialHelper.getDefaultMaterial(gripProduct?.materials);
      item.gripProduct = gripProduct;
      item.gripMaterial = gripMaterial;
    }

    this._selectedItem = item;
  }

  // Drag and drop
  //#region

  public startDrag(
    evt: MouseEvent | TouchEvent,
    product: Client.Product,
  ): void {
    this.dragInfo.value = {
      productId: product.Id,
      touchEventObservable: null,
    };
    if (!(window as any).TouchEvent || !(evt instanceof TouchEvent)) {
      evt.preventDefault();
    }
    this.createItemFromProduct(product, []);
  }

  public stopDrag(evt: MouseEvent | TouchEvent, product: Client.Product) {
    this.dragInfo.value = undefined;
  }

  // Touch events
  // #region

  public touchStart(evt: TouchEvent, product: Client.Product): void {
    if (this.ongoingTouch) {
      this.ongoingTouch.observable.value = evt;
    } else {
      let observable = new Observable(evt);
      this.ongoingTouch = {
        observable: observable,
      };
      this.dragInfo.value = {
        productId: product.Id,
        touchEventObservable: observable,
      };
    }
  }
  public touchMove(evt: TouchEvent): void {
    if (this.ongoingTouch) {
      this.ongoingTouch.observable.value = evt;
    }
  }
  public touchEnd(evt: TouchEvent): void {
    if (evt.touches.length > 0) {
      //Touch is not done, but a finger has been lifted
      return;
    }
    if (this.ongoingTouch) {
      this.ongoingTouch = undefined;
    }
  }
  public touchCancel(evt: TouchEvent): void {
    this.touchEnd(evt);
  }

  // #endregion Touch events

  public clickItem(product: Client.Product) {
    this.createItemFromProduct(product, []);
  }

  //#endregion Drag and drop

  public get numberOfSwingFlexAreas() {
    return this.cabinetSection!.swingFlex.numberOfAreas;
  }
  public set numberOfSwingFlexAreas(val: number) {
    this.cabinetSection!.swingFlex.numberOfAreas = val;
    this.setChanged();
  }

  public get swingFlexAreas(): Client.SwingFlexArea[] {
    return this.cabinetSection!.swingFlex.areas;
  }

  public get selectedSwingFlexArea() {
    return this._selectedSwingFlexArea;
  }

  public get selectedSubArea() {
    return this._selectedSubArea;
  }

  public get selectedArea() {
    return this.selectedSubArea?.parentArea;
  }

  public get selectedItem() {
    return this._selectedItem;
  }

  public get selectedSubAreaDoorMaterialPickable(): Pickable<Client.Material | null> {
    if (!this.selectionCache.subAreaDoorMaterialPickable) {
      let material = this._selectedSubArea
        ? this._selectedSubArea.doorMaterial
        : null;
      let pickable;
      if (material) {
        pickable = MaterialHelper.getPickable(material);
      } else {
        pickable = {
          disabledReason: null,
          isSelected: false,
          item: null,
          name: this.translate(
            'editor_swing_select_door_material',
            'Select door material',
          ),
          override: false,
        };
      }
      this.selectionCache.subAreaDoorMaterialPickable = pickable;
    }
    return this.selectionCache.subAreaDoorMaterialPickable;
  }

  public get selectedSwingFlexSubAreaDoorGripPickable(): Pickable<
    Client.Product | Enums.SwingFlexOpeningMethod.PushToOpen | null
  > {
    const pto = Enums.SwingFlexOpeningMethod.PushToOpen;

    if (!this.selectionCache.subAreaDoorGripPickable) {
      let grip = this._selectedSubArea ? this._selectedSubArea.doorGrip : null;
      let pickable: Pickable<
        Client.Product | Enums.SwingFlexOpeningMethod.PushToOpen | null
      >;
      if (this.selectedSubArea?.openingMethod === pto) {
        pickable = {
          disabledReason: null,
          isSelected: false,
          item: pto,
          name: this.translateOpeningMethod(pto),
          override: false,
        };
      } else if (grip) {
        pickable = ProductHelper.getPickable(grip);
      } else {
        pickable = {
          disabledReason: null,
          isSelected: false,
          item: null,
          name: this.translate('editor_swing_no_door_grip', 'No grip'),
          override: false,
        };
      }
      this.selectionCache.subAreaDoorGripPickable = pickable;
    }
    return this.selectionCache.subAreaDoorGripPickable;
  }

  public get selectedSwingFlexSubAreaDoorGripMaterialPickable(): Pickable<Client.Material | null> {
    if (!this.selectionCache.subAreaDoorGripMaterialPickable) {
      let material = this._selectedSubArea
        ? this._selectedSubArea.doorGripMaterial
        : null;
      let pickable;
      if (material) {
        pickable = MaterialHelper.getPickable(material);
      } else {
        pickable = {
          disabledReason: null,
          isSelected: false,
          item: null,
          name: this.translate(
            'editor_swing_select_grip_material',
            'Select grip material',
          ),
          override: false,
        };
      }
      this.selectionCache.subAreaDoorGripMaterialPickable = pickable;
    }
    return this.selectionCache.subAreaDoorGripMaterialPickable;
  }

  public get itemMaterialPickable(): Pickable<Client.Material | null> {
    let material = this._selectedItem ? this._selectedItem.Material : null;
    let pickable;
    if (material) {
      pickable = MaterialHelper.getPickable(material);
    } else {
      pickable = {
        disabledReason: null,
        isSelected: false,
        item: null,
        name: this.translate(
          'editor_swing_select_item_material',
          'Select material',
        ),
        override: false,
      };
    }
    return pickable;
  }

  public get selectedSubAreaOpeningMethod(): Enums.SwingFlexOpeningMethod | null {
    return this._selectedSubArea && this._selectedSubArea.openingMethod;
  }
  public set selectedSubAreaOpeningMethod(
    openingMethod: Enums.SwingFlexOpeningMethod | null,
  ) {
    if (openingMethod === this.selectedSubAreaOpeningMethod) {
      return;
    }
    if (!openingMethod) {
      return;
    }
    let selectedSubArea = this._selectedSubArea;
    if (!selectedSubArea) {
      return;
    }
    selectedSubArea.openingMethod = openingMethod;
    this.setChanged();
  }

  public get selectedSubAreaHingeType(): Enums.SwingFlexHingeType | null {
    return this._selectedSubArea && this._selectedSubArea.desiredHingeType;
  }
  public set selectedSubAreaHingeType(
    hingeType: Enums.SwingFlexHingeType | null,
  ) {
    if (hingeType === this.selectedSubAreaHingeType) {
      return;
    }
    if (!hingeType) {
      return;
    }
    let selectedSubArea = this._selectedSubArea;
    if (!selectedSubArea) {
      return;
    }
    selectedSubArea.desiredHingeType = hingeType;
    this.setChanged();
  }

  public get selectedSubAreaHingeTypeLeft(): Enums.SwingFlexHingeType | null {
    return this._selectedSubArea && this._selectedSubArea.desiredHingeTypeLeft;
  }
  public set selectedSubAreaHingeTypeLeft(
    hingeType: Enums.SwingFlexHingeType | null,
  ) {
    if (hingeType === this.selectedSubAreaHingeTypeLeft) {
      return;
    }
    if (!hingeType) {
      return;
    }
    let selectedSubArea = this._selectedSubArea;
    if (!selectedSubArea) {
      return;
    }
    selectedSubArea.desiredHingeTypeLeft = hingeType;
    this.setChanged();
  }

  public get selectedSubAreaHingeTypeRight(): Enums.SwingFlexHingeType | null {
    return this._selectedSubArea && this._selectedSubArea.desiredHingeTypeRight;
  }
  public set selectedSubAreaHingeTypeRight(
    hingeType: Enums.SwingFlexHingeType | null,
  ) {
    if (hingeType === this.selectedSubAreaHingeTypeRight) {
      return;
    }
    if (!hingeType) {
      return;
    }
    let selectedSubArea = this._selectedSubArea;
    if (!selectedSubArea) {
      return;
    }
    selectedSubArea.desiredHingeTypeRight = hingeType;
    this.setChanged();
  }

  public get selectedAreaHingeSide(): Enums.HingeSide | null {
    return this._selectedSwingFlexArea && this._selectedSwingFlexArea.hingeSide;
  }
  public set selectedAreaHingeSide(hingeSide: Enums.HingeSide | null) {
    if (hingeSide === this.selectedAreaHingeSide) {
      return;
    }
    if (!hingeSide) {
      return;
    }
    let selectedArea = this._selectedSwingFlexArea;
    if (!selectedArea) {
      return;
    }
    selectedArea.hingeSide = hingeSide;
    this.setChanged();
  }

  public get selectedSubAreaGripPosition(): Enums.SwingFlexGripPosition | null {
    return this._selectedSubArea && this._selectedSubArea.gripPosition;
  }
  public set selectedSubAreaGripPosition(
    gripPosition: Enums.SwingFlexGripPosition | null,
  ) {
    if (gripPosition === this.selectedSubAreaGripPosition) {
      return;
    }
    if (!gripPosition) {
      return;
    }
    let selectedSubArea = this._selectedSubArea;
    if (!selectedSubArea) {
      return;
    }
    selectedSubArea.gripPosition = gripPosition;
    this.setChanged();
  }

  public get selectedDrawerGripPickable(): Pickable<Client.Product | null> {
    if (!this.selectionCache.drawerGripPickable) {
      let grip: Client.Product | null = null;
      if (!!this._selectedItem) {
        let area = this.cabinetSection!.swingFlex.getAreaContainingItem(
          this._selectedItem,
        );
        grip = area?.getDrawerGripProduct(this._selectedItem) ?? null;
      }

      let pickable;
      if (grip) {
        pickable = ProductHelper.getPickable(grip);
      } else {
        pickable = {
          disabledReason: null,
          isSelected: false,
          item: null,
          name: this.translate('editor_swing_select_door_grip', 'Select grip'),
          override: false,
        };
      }
      this.selectionCache.drawerGripPickable = pickable;
    }
    return this.selectionCache.drawerGripPickable;
  }

  public get selectedDrawerGripMaterialPickable(): Pickable<Client.Material | null> {
    if (!this.selectionCache.drawerGripMaterialPickable) {
      let material: Client.Material | null = null;
      if (!!this._selectedItem) {
        let area = this.cabinetSection!.swingFlex.getAreaContainingItem(
          this._selectedItem,
        );
        material = area?.getDrawerGripMaterial(this._selectedItem) ?? null;
      }

      let pickable;
      if (material) {
        pickable = MaterialHelper.getPickable(material);
      } else {
        pickable = {
          disabledReason: null,
          isSelected: false,
          item: null,
          name: this.translate(
            'editor_swing_select_grip_material',
            'Select grip material',
          ),
          override: false,
        };
      }
      this.selectionCache.drawerGripMaterialPickable = pickable;
    }
    return this.selectionCache.drawerGripMaterialPickable;
  }

  public get allowMiddlePanel(): boolean {
    return (
      this.selectedSubArea !== null &&
      this.selectedSubArea.isMiddlePanelPossible
    );
  }

  public get hasMiddlePanel(): boolean {
    return this.selectedSubArea !== null && this.selectedSubArea.hasMiddlePanel;
  }
  public set hasMiddlePanel(value: boolean) {
    if (value === this.hasMiddlePanel) return;

    const selectedSubArea = this._selectedSubArea;
    if (!selectedSubArea) {
      return;
    }

    this.baseVmService.notificationService.warning(
      'swingflex_middlepanel_notification',
      'Middle panel has been changed. Please note that the change may affect inventory',
    );

    selectedSubArea.hasMiddlePanel = value;
    this.setChanged();
  }

  public get allowMiddlePanelPositionChange(): boolean {
    return (
      !!this._selectedSubArea &&
      this._selectedSubArea.openingMethod !==
        Enums.SwingFlexOpeningMethod.PushToOpen
    );
  }

  public get availableMiddlePanelHeights(): number[] {
    if (!!this.selectedSubArea)
      return this.selectedSubArea.availableMiddlePanelHeights;

    return [];
  }

  public get selectedMiddlePanelHeight(): number | null {
    return this._selectedSubArea && this._selectedSubArea.middlePanelHeight;
  }
  public set selectedMiddlePanelHeight(height: number | null) {
    if (height === this.selectedMiddlePanelHeight) return;

    if (!height) return;

    let selectedSubArea = this._selectedSubArea;
    if (!selectedSubArea) {
      return;
    }

    selectedSubArea.middlePanelHeight = height;
    this.setChanged();
  }

  public get selectedSubAreaDoorPossible(): boolean {
    return this.selectedSubArea !== null && this.selectedSubArea.isDoorPossible;
  }

  public get selectedSubAreaHasDoor(): boolean {
    return (
      this.selectedSubArea !== null &&
      this.selectedSubArea.isDoorPossible &&
      this.selectedSubArea.hasDoor
    );
  }
  public set selectedSubAreaHasDoor(value: boolean) {
    if (value === this.selectedSubAreaHasDoor) return;

    if (this.selectedSubArea === null) {
      return;
    }

    this.selectedSubArea.hasDoor = value;
    this.setChanged();
  }

  /** true if current store has access to plinth products */
  public get plinthAvailable(): boolean {
    if (this.cache.plinthAvailable === undefined) {
      const section = this.cabinetSection;
      if (!section) {
        return (this.cache.plinthAvailable = false);
      }

      const allowOtherProducts =
        section.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      let corpusProducts = section.editorAssets.products.filter(
        (p) => p.ProductType === Interface_Enums.ProductType.SwingCorpus,
      );
      if (!allowOtherProducts) {
        corpusProducts = corpusProducts.filter((p) => !p.OverrideChain);
      }
      let plinthProducts = corpusProducts.filter((p) =>
        ProductHelper.isPlinth(p, section.cabinet.ProductLineId),
      );
      this.cache.plinthAvailable = plinthProducts.length > 0;
    }
    return this.cache.plinthAvailable;
  }

  public get plinth(): boolean {
    return this.cabinetSection!.swingFlex.plinth;
  }
  public set plinth(value: boolean) {
    this.cabinetSection!.swingFlex.plinth = value;
    this.setChanged();
  }

  public get doorCoversPlinth(): boolean {
    return this.cabinetSection!.swingFlex.doorCoversPlinth;
  }
  public set doorCoversPlinth(value: boolean) {
    this.cabinetSection!.swingFlex.doorCoversPlinth = value;
    this.setChanged();
  }

  protected override floorPlanChanged() {}

  public override setChanged() {
    this.clearCache();
    return super.setChanged();
  }

  private clearCache() {
    this.clearSelectionCache();
    this.cache = {};
  }

  private clearSelectionCache() {
    this.selectionCache = {};
  }

  private setSwingFlexSetting(swingFlexSetting: Partial<SwingFlexSetting>) {
    const newSwingFlexSettings = {
      ...this.clientSettingsData.swingFlex,
      ...swingFlexSetting,
    };
    const newSettings = {
      ...this.clientSetting.data,
      swingFlex: newSwingFlexSettings,
    };
    this.clientSetting.set(newSettings);
  }

  public async selectCorpusMaterial() {
    let pickables = this.cabinetSection!.swingFlex.pickableCorpusMaterials;
    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate(
        'editor_swing_select_corpus_material',
        'Select corpus material',
      ),
      'material corpus-material',
    );
    let material;
    try {
      material = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }
    this.cabinetSection!.swingFlex.corpusMaterial = material;
    this.setChanged();

    setTimeout(() => {
      this.materialsDropdown?.open();
    }, this.materialsGripDropdownOpenTimeout);
  }

  public async selectDoorMaterial(subArea?: SwingFlexSubArea) {
    let pickables = this.cabinetSection!.swingFlex.pickableDoorMaterials;

    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate(
        'editor_swing_select_door_material',
        'Select door material',
      ),
      'material door-material',
    );
    let material;
    try {
      material = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }
    if (subArea) {
      subArea.doorMaterial = material;
    } else {
      this.cabinetSection!.swingFlex.setDoorMaterial(material, true);
    }
    this.setChanged();
  }

  public async selectDoorGripForSection() {
    if (!this.cabinetSection) return;
    let pickables = this.cabinetSection.swingFlex.pickableDoorGrips;
    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate('editor_swing_select_grip', 'Select Grip type'),
      'product grip-product',
      true,
    );
    let grip;
    try {
      grip = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }
    if (grip === Enums.SwingFlexOpeningMethod.PushToOpen) {
      this.cabinetSection.swingFlex.doorGrip = null;
      this.cabinetSection.swingFlex.doorOpeningMethod =
        Enums.SwingFlexOpeningMethod.PushToOpen;
    } else {
      this.cabinetSection.swingFlex.doorGrip = grip;
      this.cabinetSection.swingFlex.doorOpeningMethod =
        Enums.SwingFlexOpeningMethod.Grip;
    }
    this.setChanged();

    setTimeout(() => {
      this.gripDropdown?.open();
    }, this.materialsGripDropdownOpenTimeout);
  }

  public async selectDoorGripMaterial() {
    let pickables = this.cabinetSection!.swingFlex.pickableDoorGripMaterials;
    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate(
        'editor_swing_select_grip_material',
        'Select grip material',
      ),
      'material grip-material',
    );
    let material;
    try {
      material = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }

    this.cabinetSection!.swingFlex.doorGripMaterial = material;
    this.setChanged();

    setTimeout(() => {
      this.gripDropdown?.open();
    }, this.materialsGripDropdownOpenTimeout);
  }

  public async selectDoorGripForSubArea() {
    if (!this._selectedSubArea) return;

    let pickables = this.cabinetSection!.swingFlex.pickableDoorGrips;
    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate('editor_swing_select_grip', 'Select Grip type'),
      'product grip-product',
      true,
    );
    let grip;
    try {
      grip = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }

    if (grip === Enums.SwingFlexOpeningMethod.PushToOpen) {
      this._selectedSubArea.doorGrip = null;
      this._selectedSubArea.openingMethod =
        Enums.SwingFlexOpeningMethod.PushToOpen;
    } else {
      this._selectedSubArea.doorGrip = grip;
      this._selectedSubArea.openingMethod = Enums.SwingFlexOpeningMethod.Grip;
    }
    this.setChanged();
  }

  public async selectDoorGripMaterialForSubArea() {
    if (!this._selectedSubArea) return;

    let pickables = this.cabinetSection!.swingFlex.getPickableGripMaterials(
      this._selectedSubArea.doorGrip,
      this._selectedSubArea.doorGripMaterial,
    );
    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate(
        'editor_swing_select_grip_material',
        'Select grip material',
      ),
      'material grip-material',
    );
    let material;
    try {
      material = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }
    this._selectedSubArea.doorGripMaterial = material;
    this.setChanged();
  }

  public async selectDrawerGripForSelectedDrawer() {
    if (this.selectedItem?.isPullout === false) return;

    let pickables = this.cabinetSection!.swingFlex.pickableDrawerGrips;
    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate('editor_swing_select_grip', 'Select Grip type'),
      'product grip-product',
      true,
    );
    let grip;
    try {
      grip = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }

    if (this._selectedItem) {
      let area = this.cabinetSection!.swingFlex.getAreaContainingItem(
        this._selectedItem,
      );
      area?.setDrawerGripProduct(this._selectedItem, grip);
      this.setChanged();
    }
  }

  public async selectDrawerGripForSection() {
    if (!this.cabinetSection) {
      return;
    }
    let pickables = this.cabinetSection.swingFlex.pickableDrawerGrips;
    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate('editor_swing_select_grip', 'Select Grip type'),
      'product grip-product',
      true,
    );
    let grip;
    try {
      grip = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }

    if (this.cabinetSection) {
      this.cabinetSection.swingFlex.drawerGrip = grip;
      this.cabinetSection.swingFlex.drawerGripMaterial =
        grip?.materials[0] ?? null;
      this.setChanged();

      setTimeout(() => {
        this.gripDropdown?.open();
      }, this.materialsGripDropdownOpenTimeout);
    }
  }

  public async selectDrawerGripMaterialForSelectedDrawer() {
    if (!this._selectedItem || !this.selectedItem?.isPullout) return;

    let area = this.cabinetSection!.swingFlex.getAreaContainingItem(
      this._selectedItem,
    );

    if (!area) {
      return;
    }

    let currentGrip = area.getDrawerGripProduct(this._selectedItem);
    let currentMaterial =
      this._selectedItem.gripMaterial ??
      this.cabinetSection?.swingFlex.drawerGripMaterial ??
      null;
    let pickables =
      this.cabinetSection!.swingFlex.getPickableDrawerGripMaterials(
        currentGrip,
        currentMaterial,
      );

    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate(
        'editor_swing_select_grip_material',
        'Select grip material',
      ),
      'material grip-material',
    );
    let material;
    try {
      material = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }

    area?.setDrawerGripMaterial(this._selectedItem, material);
    this.setChanged();
  }

  public async selectDrawerGripMaterialForSection() {
    if (!this.cabinetSection) {
      return;
    }
    let pickables = this.cabinetSection!.swingFlex.pickableDrawerGripMaterials;

    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate(
        'editor_swing_select_grip_material',
        'Select grip material',
      ),
      'material grip-material',
    );
    let material;
    try {
      material = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }
    this.cabinetSection.swingFlex.drawerGripMaterial = material;
    this.setChanged();

    setTimeout(() => {
      this.gripDropdown?.open();
    }, this.materialsGripDropdownOpenTimeout);
  }

  public async selectBackingMaterial() {
    let pickables = this.cabinetSection!.swingFlex.pickableBackingMaterials;
    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate(
        'editor_swing_select_backing_material',
        'Select backing material',
      ),
      'material backing-material',
    );
    let material;
    try {
      material = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }
    this.cabinetSection!.swingFlex.backingMaterial = material;
    this.setChanged();

    setTimeout(() => {
      this.materialsDropdown?.open();
    }, this.materialsGripDropdownOpenTimeout);
  }

  public async selectItemMaterial() {
    if (!this._selectedItem) return;

    let fullCatalog =
      this._selectedItem.editorAssets.fullCatalog &&
      this._selectedItem.cabinetSection.cabinet.floorPlan
        .FullCatalogAllowOtherMaterials;
    let materialId = this._selectedItem.MaterialId;

    let materials = this._selectedItem.Product
      ? this._selectedItem.Product.materials
      : [];

    let pickables = materials
      .filter(
        (mat) =>
          mat.Id === materialId ||
          (!mat.isDiscontinued && (!mat.isOverride || fullCatalog)),
      )
      .map((mat) => MaterialHelper.getPickable(mat));
    MaterialHelper.sortPickableMaterials(pickables);
    let selection: Interface_DTO.Material;
    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate(
        'swingflex_item_material_selector_title',
        'Select Material',
      ),
      'material interior-item-material',
      true,
    );
    try {
      selection = await modal.result;
    } catch (e: any) {
      //User cancelled or closed modal
      return;
    }

    this._selectedItem.MaterialId = selection.Id;
    this.setChanged();

    setTimeout(() => {
      this.gripDropdown?.open();
    }, this.materialsGripDropdownOpenTimeout);
  }

  public isSwingSectionLocked(sectionIndex: number): boolean {
    return this.swingFlexAreas.every(
      (area, index) => area.widthAdjustedByUser || index === sectionIndex,
    );
  }

  public getSwingSectionWidth(sectionIndex: number): number {
    return this.swingFlexAreas[sectionIndex].insideWidth;
  }

  public setSwingSectionWidth(sectionIndex: number, width: number) {
    if (this.isSwingSectionLocked(sectionIndex)) return;
    this.swingFlexAreas[sectionIndex].desiredInsideWidth = width;
    this.swingFlexAreas[sectionIndex].widthAdjustedByUser = true;
    this.setChanged();
  }

  public resetSwingSectionWidth(sectionIndex: number) {
    this.swingFlexAreas[sectionIndex].widthAdjustedByUser = false;
    this.setChanged();
  }

  public deleteSelectedItem() {
    if (!this._selectedItem) return;

    for (let a of this._selectedItem.cabinetSection.swingFlex.areas) {
      a.removeAreaSplitItem(this._selectedItem);
    }

    this.itemSelectionObservable.next(null);
    this.setChanged();
  }

  public translateOpeningMethod(
    openingMethod: Enums.SwingFlexOpeningMethod,
  ): string {
    return this.translate(
      'editor_swingflex_opening_method_' + openingMethod,
      openingMethod,
    );
  }

  public translateHingeType(hingeType: Enums.SwingFlexHingeType): string {
    return this.translate(
      'editor_swingflex_hinge_type_' + hingeType,
      hingeType,
    );
  }

  public translateHingeSide(hingeSide: Enums.HingeSide): string {
    return this.translate('editor_swing_hinge_side_' + hingeSide, hingeSide);
  }

  public translateGripPosition(
    gripPosition: Enums.SwingFlexGripPosition,
  ): string {
    return this.translate(
      'editor_swing_grip_position_' + gripPosition,
      gripPosition,
    );
  }

  private get rightNeighborToSelectedArea(): Client.SwingFlexArea | undefined {
    const selectedAreaIndex = this.selectedSubArea?.parentArea?.index;
    if (selectedAreaIndex === undefined) return undefined;
    return this.cabinetSection?.swingFlex.areas[selectedAreaIndex + 1];
  }
  public get rightGableInfoForSelectedArea(): GableInfo | undefined {
    // The right gable is owned by the right neighbor
    //if there is no right neighbor, the gable is owned by the subsectionSwingFlex
    return (
      this.rightNeighborToSelectedArea?.leftGableInfo ??
      this.cabinetSection?.swingFlex.rightGableInfo
    );
  }

  public set rightGableInfoForSelectedArea(val: GableInfo) {
    let neighbor = this.rightNeighborToSelectedArea;
    if (neighbor) {
      neighbor.leftGableInfo = val;
    } else if (this.cabinetSection) {
      this.cabinetSection.swingFlex.rightGableInfo = val;
    }
  }

  public get rightGableItemForSelectedArea():
    | Client.ConfigurationItem
    | undefined {
    return (
      this.rightNeighborToSelectedArea?.leftGableItem ??
      this.cabinetSection?.swingFlex.rightGableItem
    );
  }

  public get drawerGripPossible(): boolean {
    if (this.cache.drawerGripPossible === undefined) {
      const anyAvailableDrawerGrips =
        this.cabinetSection!.interior.availableDrawerGrips.length > 0;
      const anyAvailableDrawers = this.productCategories.some((pc) =>
        pc.anyProduct((product) => {
          let result = ProductHelper.canHaveGrip(product, null);
          result &&=
            !product.OverrideChain ||
            this.cabinetSection!.cabinet.floorPlan
              .FullCatalogAllowOtherProducts;
          result &&= ProductHelper.isProductDataType(
            Interface_Enums.ProductDataType.SwingFlexDoorBreaker,
            product,
          );
          return result;
        }),
      );
      this.cache.drawerGripPossible =
        anyAvailableDrawerGrips && anyAvailableDrawers;
    }
    return this.cache.drawerGripPossible;
  }

  public get drawerGripMaterialPossible(): boolean {
    return this.drawerGripPossible;
  }
}
