import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { Injectable } from '@angular/core';
import { CabinetType } from 'app/ts/Interface_Enums';
import { ObjectHelper } from '@Util/ObjectHelper';

@Injectable({ providedIn: 'root' })
export class DimensionLogic {
  constructor() {}

  public recalculate(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    this.limitHeight(section);

    //this.reacalculateSight(section);
    if (App.useDebug && App.debug.showTimings)
      console.time('Recalculate Dimension');

    section.Depth = ObjectHelper.clamp(
      section.minDepth,
      section.Depth,
      section.maxDepth,
    );

    this.updateCabinetSectionDto(section);

    if (App.useDebug && App.debug.showTimings)
      console.timeEnd('Recalculate Dimension');
    return [];
  }

  public recalculatePartTwo(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    if (!section.isDepthUserAdjustable) {
      this.setActualDepth(section);
    }
    return [];
  }

  //#region Calculations, Cabinet section

  /**
   * Updates values, that are dependent on dimensions, on cabinet section
   * @param section
   */
  private updateCabinetSectionDto(section: Client.CabinetSection) {
    this.setInteriorDepth(section);
  }

  private limitHeight(section: Client.CabinetSection) {
    const productLine = section.cabinet.productLine;

    const minHeight = productLine.MinHeight;
    const maxHeight = section.cabinet.floorPlan.FullCatalogTallCabinets
      ? Constants.maxTallCabinetHeight
      : productLine.MaxHeight;
    const newHeight = ObjectHelper.clamp(minHeight, section.Height, maxHeight);
    if (section.Height !== newHeight) {
      section.Height = newHeight;
    }
  }

  /**
   * Calculates and sets interior depth
   * @param section
   */
  private setInteriorDepth(section: Client.CabinetSection) {
    if (
      !section.InteriorDepth ||
      section.InteriorDepth < section.minInteriorDepth ||
      section.InteriorDepth > section.maxInteriorDepth
    ) {
      section.InteriorDepth = section.cabinet.productLine.DefaultDepthInterior;
    }
  }

  private setActualDepth(section: Client.CabinetSection) {
    let items = section.configurationItems;

    //find and remove all corpus lighting items
    {
      let lightingProductIds = Enumerable.from(
        section.editorAssets.corpusPanels,
      )
        .selectMany((cp) => cp.lightingProducts)
        .select((lp) => lp.Id)
        .toArray();

      let isDoor = section.CabinetType == CabinetType.Doors;
      items = items.filter(
        (item) =>
          lightingProductIds.indexOf(item.ProductId) < 0 &&
          (!isDoor || (item.Product?.ProductType as number) != 90),
      );
    }

    if (items.length < 1) {
      section.Depth = Constants.doorsOnlyCabinetDefaultWidth;
    } else {
      let minZ = Math.min(...items.map((i) => i.Z));
      let maxZ = Math.max(...items.map((i) => i.Z + i.Depth));
      section.Depth = maxZ - minZ;
    }
  }

  //#endregion Calculations, Cabinet section
}
