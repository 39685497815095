export class Lazy<T> {
  constructor(private readonly factory: () => T) {}

  private _isValueCreated = false;
  private _value: T | undefined = undefined;

  public get value(): T {
    if (!this._isValueCreated) {
      this._isValueCreated = true;
      this._value = this.factory();
    }
    return this._value!;
  }

  public get isValueCreated() {
    return this._isValueCreated;
  }
}
