export interface ConfigurationItemProperties {
  swingFlexAreaIndex?: number;
  gripProductId?: number;
  gripMaterialId?: number;
  actualWidth?: number;
  actualDepth?: number;
}

export module ConfigurationItemProperties {
  export const defaultValue: Readonly<ConfigurationItemProperties> = {};
}
