import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { VariantTypeNumbers } from 'app/ts/VariantTypeNumbers';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { AssetService } from 'app/ts/services/AssetService';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Injectable } from '@angular/core';
import { MaterialHelper } from '@Util/MaterialHelper';
import { Vec2d } from '../Interface_DTO_Draw';
import { Constants } from '../Constants';
@Injectable({ providedIn: 'root' })
export class ConfigurationItemService {
  public static readonly Name = 'configurationItemService';

  constructor(private readonly assetService: AssetService) {}

  public createItemFromOtherItem(
    sourceItem: Client.ConfigurationItem,
    cabinetSection: Client.CabinetSection,
  ): Client.ConfigurationItem {
    let mainItem = this.createConfigurationItem(
      sourceItem.ItemType,
      sourceItem.ProductId,
      sourceItem.MaterialId,
      cabinetSection,
    );

    mainItem.X = sourceItem.X;
    mainItem.Y = sourceItem.Y;
    mainItem.Z = sourceItem.Z;
    mainItem.Width = sourceItem.Width;
    mainItem.Height = sourceItem.Height;
    mainItem.Depth = sourceItem.Depth;
    mainItem.IsLocked = sourceItem.IsLocked;
    mainItem.HeightReduction = sourceItem.HeightReduction;
    for (let variantOption of sourceItem.VariantOptions) {
      mainItem.setItemVariant(variantOption.dto, false);
    }
    return mainItem;
  }

  public createConfigurationItem(
    type: Interface_Enums.ItemType,
    productId: number,
    materialId: number | null,
    cabinetSection: Client.CabinetSection,
    quantity: number = 1,
    variantOptions: Interface_DTO.VariantOption[] | null = null,
    setAllItemVariants: boolean = false,
  ): Client.ConfigurationItem {
    if (variantOptions != null && variantOptions.length > 0) {
      let p = this.getProductById(productId, cabinetSection.editorAssets);
      for (let vo of variantOptions) {
        for (let v of p.getVariants(cabinetSection.cabinet.ProductLineId)) {
          if (
            vo.VariantId == v.Id &&
            (v.TypeNumber == '1' || v.TypeNumber == '2')
          ) {
            for (let m of this.assetService.editorAssets.materials) {
              if (m.Number == vo.Number) {
                for (let x of p.materials) {
                  if (x.Id == m.Id) {
                    materialId = m.Id;
                  }
                }
              }
            }
          }
        }
      }
    }

    let dtoConfigurationItem: Interface_DTO.ConfigurationItem =
      this.createDtoItem(
        type,
        productId,
        materialId,
        cabinetSection,
        setAllItemVariants,
        quantity,
      );

    if (variantOptions != null) {
      for (let vo of variantOptions) {
        let iv: Interface_DTO.ItemVariant = {
          ActualValue: '',
          VariantId: vo.VariantId,
          VariantOptionId: vo.Id,
        };
        dtoConfigurationItem.VariantOptions.push(iv);
      }
    }

    let mainItem = new Client.ConfigurationItem(
      dtoConfigurationItem,
      cabinetSection,
    );

    if (mainItem.hasWidth2) mainItem.width2 = mainItem.Width;

    if (mainItem.hasDepth2) mainItem.depth2 = mainItem.Depth;

    return mainItem;
  }

  public createInteriorItemFromOtherItem(
    sourceItem: Client.ConfigurationItem,
    cabinetSection: Client.CabinetSection,
  ): {
    items: [Client.ConfigurationItem];
    recalculationMessages: Client.RecalculationMessage[];
  } {
    let result = this.createInteriorItem(
      sourceItem.ProductId,
      sourceItem.MaterialId,
      cabinetSection,
    );

    let mainItem = result.items[0];

    mainItem.X = sourceItem.X;
    mainItem.Y = sourceItem.Y;
    mainItem.Z = sourceItem.Z;
    mainItem.Width = sourceItem.Width;
    mainItem.Height = sourceItem.Height;
    mainItem.Depth = sourceItem.Depth;
    mainItem.IsLocked = sourceItem.IsLocked;
    mainItem.HeightReduction = sourceItem.HeightReduction;
    for (let variantOption of sourceItem.VariantOptions) {
      mainItem.setItemVariant(variantOption.dto, false);
    }
    return result;
  }

  /**
   * returns one or more interiorItems. If product is a module, the module item will be the first item in the list, and the following items will be the module children.
   * This method will not insert the interiorItem(s) into the cabinetSection
   */
  public createInteriorItem(
    productId: number,
    materialId: number | null,
    cabinetSection: Client.CabinetSection,
    minConfigurationItemIndex?: number,
  ): {
    items: [Client.ConfigurationItem];
    recalculationMessages: Client.RecalculationMessage[];
  } {
    if (materialId == null) {
      materialId = cabinetSection.InteriorMaterialId;
    }
    let dtoConfigurationItem: Interface_DTO.ConfigurationItem =
      this.createDtoItem(
        Interface_Enums.ItemType.Interior,
        productId,
        materialId,
        cabinetSection,
        false,
        1,
        minConfigurationItemIndex,
      );

    let mainItem = new Client.ConfigurationItem(
      dtoConfigurationItem,
      cabinetSection,
    );
    let moduleChildren = this.getModuleChildren(mainItem, cabinetSection);
    let items: [Client.ConfigurationItem] = [mainItem];
    items.push(...moduleChildren);
    let interiorDepth = cabinetSection.InteriorDepth;
    let msgs: Client.RecalculationMessage[] = [];
    for (let item of items) {
      this.setMaterialVariantOptions(item);
      if (item.isGable) {
        if (
          !item.trySetDepth(interiorDepth, false) &&
          item.minDepth != item.maxDepth
        ) {
          item.trySetDepth(item.defaultDepth);
          msgs.push({
            cabinetSection: cabinetSection,
            key: 'interior_gable_interior_depth_mismatch',
            defaultValue:
              'Gable is not available in depth {0} mm. Standard depth ({1} mm) used instead',
            params: [interiorDepth.toString(), item.defaultDepth.toString()],
            editorSection: Enums.EditorSection.Interior,
            item: item,
            severity: Enums.RecalculationMessageSeverity.Warning,
          });
        }
      } else {
        item.Depth = ObjectHelper.clamp(
          item.minDepth,
          interiorDepth,
          item.maxDepth,
        );
      }
      item.HeightReduction = cabinetSection.interior.heightReduction;
    }

    return { items: items, recalculationMessages: msgs };
  }

  public createDrawerGripItem(
    drawer: Client.ConfigurationItem,
    itemType: Interface_Enums.ItemType,
  ): Client.ConfigurationItem | undefined {
    if (!drawer.canHaveGrip) return;
    const gripProduct = drawer.gripProduct;
    if (!gripProduct) return;

    const gripMaterial = drawer.gripMaterial ?? undefined;

    const gripDimensions = this.getGripDimensions(gripProduct, gripMaterial);

    let isEdgeMounted = ProductHelper.edgeMounted(gripProduct);
    let topOffset = isEdgeMounted
      ? ProductHelper.getSnapOffsetY(gripProduct)
      : Constants.drawerGripSpace + gripDimensions.Y;

    const gripPosition = {
      X: drawer.centerX - gripDimensions.X / 2,
      Y: drawer.topY - topOffset,
      Z: isEdgeMounted
        ? drawer.frontZ - Constants.drawerGripEdgemountOffset
        : drawer.frontZ,
    };

    const gripItem = this.createConfigurationItem(
      itemType,
      gripProduct.Id,
      gripMaterial?.Id ?? null,
      drawer.cabinetSection,
    );
    gripItem.ActualHeight = gripItem.Height = ProductHelper.defaultHeight(
      gripProduct,
      gripMaterial,
    );
    gripItem.Width = ProductHelper.defaultWidth(gripProduct, gripMaterial);
    gripItem.Depth = ProductHelper.defaultDepth(
      gripProduct,
      drawer.cabinetSection.cabinet.productLine.Id,
      gripMaterial,
    );

    gripItem.X = gripPosition.X;
    gripItem.Y = gripPosition.Y;
    gripItem.Z = gripPosition.Z;
    return gripItem;
  }

  private getGripDimensions(
    product: Client.Product,
    material?: Client.Material,
    rotate?: boolean,
  ): Vec2d {
    let height = ProductHelper.defaultHeight(product, material ?? undefined);
    if (height <= 0) height = 50;
    let width = ProductHelper.defaultWidth(product, material ?? undefined);
    if (width <= 0) width = 50;
    return { X: rotate ? height : width, Y: rotate ? width : height };
  }

  private setMaterialVariantOptions(item: Client.ConfigurationItem) {
    const variants = item?.Product?.getVariants(item.productLineId!);
    if (!variants) return;
    const materialVariantTypeNumbers = [
      VariantTypeNumbers.NormalMaterial,
      VariantTypeNumbers.FrameMaterial,
      VariantTypeNumbers.GripMaterial,
    ];
    for (const v of variants) {
      if (materialVariantTypeNumbers.indexOf(v.TypeNumber) < 0) continue;
      const materialType = VariantTypeNumbers.materialTypeDict[v.TypeNumber];
      const materials = item.Product?.materials.filter(
        (mat) => mat.Type === materialType,
      );
      const defaultMaterial = MaterialHelper.getDefaultMaterial(materials);
      const variantOption = v.VariantOptions.find(
        (vo) => vo.Number === defaultMaterial?.Number,
      );
      if (variantOption) {
        item.setItemVariant(
          {
            ActualValue: '',
            VariantId: v.Id,
            VariantOptionId: variantOption.Id,
          },
          false,
        );
      }
    }
  }

  private getModuleChildren(
    mainItem: Client.ConfigurationItem,
    section: Client.CabinetSection,
  ): Client.ConfigurationItem[] {
    if (!mainItem.Product) return [];
    if (!mainItem.Product.IsTemplate) {
      return [];
    }
    let itemIndex = mainItem.ConfigurationItemIndex;
    let dtoItems = mainItem.Product.ModuleItems.map((mi) => {
      itemIndex++;
      let clientItem = this.createInteriorItem(
        mi.ProductId,
        mi.MaterialId,
        section,
        itemIndex,
      ).items[0];
      clientItem.ParentModuleProductId = mainItem.ProductId;
      clientItem.moduleParent = mainItem;
      return clientItem;
    });
    return dtoItems;
  }

  public deleteInteriorItems(items: Client.ConfigurationItem[]) {
    for (let inItem of items) {
      let cabSec = inItem.cabinetSection;
      let baseItem = inItem.moduleBaseItem;
      for (let descendant of baseItem.moduleDescendants) {
        let idx = cabSec.interior.items.indexOf(descendant);
        if (idx >= 0) {
          cabSec.interior.items.splice(idx, 1);
        }
      }
      cabSec.isDirty = true;
    }
  }

  public createDtoItem(
    type: Interface_Enums.ItemType,
    productId: number,
    materialId: number | null,
    cabinetSection: Client.CabinetSection,
    setAllItemVariants: boolean,
    quantity: number = 1,
    minConfigurationItemIndex?: number,
  ): Interface_DTO.ConfigurationItem {
    if (!minConfigurationItemIndex) minConfigurationItemIndex = 1;

    let product = this.getProductById(productId, cabinetSection.editorAssets);

    //check if materialId is valid
    let material: Interface_DTO.Material | undefined;
    if (materialId !== null) {
      material = product.materials.filter((mat) => mat.Id === materialId)[0];
      if (!material) {
        material = product.materials[0];
      }
    } else {
      material = undefined;
    }

    materialId = material ? material.Id : -1;

    let variantOptions: Interface_DTO.ItemVariant[];
    if (setAllItemVariants) {
      variantOptions = product
        .getVariants(cabinetSection.cabinet.ProductLineId)
        .filter((v) => v.VariantOptions.length > 0)
        .map<Interface_DTO.ItemVariant>((variant) => {
          return {
            ActualValue: '',
            VariantId: variant.Id,
            VariantOptionId: variant.VariantOptions[0].Id,
          };
        });
    } else {
      variantOptions = [];
    }

    return {
      ActualHeight: 0,
      Depth: ProductHelper.defaultDepth(
        product,
        cabinetSection.cabinet.ProductLineId,
        material,
      ),
      Description: product.Name,
      ExternalItemNo: '',
      Height: ProductHelper.defaultHeight(product, material),
      HeightReduction: false,
      IsHidden: false,
      IsLocked: false,
      ItemNo: product.ProductNo,
      ItemType: type,
      MaterialId: materialId,
      ParentItemNo: '',
      ParentModuleItemIndex: null,
      ParentModuleProductId: 0,
      PositionNumber: -1,
      Price: 0,
      ProductId: productId,
      Quantity: quantity,
      Width: ProductHelper.defaultWidth(product, material),
      X: 0,
      Y: 0,
      Z: 0,
      CabinetIndex: cabinetSection.CabinetIndex,
      CabinetSectionIndex: cabinetSection.CabinetSectionIndex,
      ConfigurationItemIndex: Math.max(
        minConfigurationItemIndex,
        ...cabinetSection.configurationItems.map(
          (ci) => ci.ConfigurationItemIndex + 1,
        ),
      ),
      Children: [],
      VariantOptions: variantOptions,
    };
  }

  public getProductById(
    id: number,
    editorAssets: Client.EditorAssets,
  ): Client.Product {
    return editorAssets.productsDict[id];
  }

  public recalculate(
    item: Client.ConfigurationItem,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    if (!item.Product) {
      //Item is a custom item, added manually by the user
      return result;
    }

    // Dimension variants
    result.push(...this.setWidthVariants(item));
    result.push(...this.setLengthVariants(item));
    result.push(...this.setHeightVariants(item));
    result.push(...this.setDepthVariants(item));

    // Missing variants (ie variants, that are not set already)
    result.push(...this.setMissingVariants(item));
    result.push(...this.reclaculateMaterialVariant(item));

    this.recalculateItemNo(item);
    this.recalculateItemDescription(item);
    this.recalculateIsHidden(item);

    return result;
  }

  /**
   * Sets all width related variants (Width, WidthLeft, WidthRight)
   * @param item
   */
  private setWidthVariants(
    item: Client.ConfigurationItem,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    if (!item.Product) return result;

    if (item.actualWidth) {
      ConfigurationItemHelper.addDimensionVariantByNumber(
        item,
        VariantNumbers.SidePanelWidth,
        item.actualWidth,
        1,
      );
    }

    if (!item.Product.hasWidth22(item.cabinetSection.cabinet.ProductLineId)) {
      ConfigurationItemHelper.addDimensionVariantByNumber(
        item,
        VariantNumbers.Width,
        item.actualWidth ?? item.Width,
        1,
      );
    } else {
      //Corner product specific variants
      if (
        item.Product.hasWidthTotal2(item.cabinetSection.cabinet.ProductLineId)
      ) {
        ConfigurationItemHelper.addDimensionVariantByNumber(
          item,
          VariantNumbers.WidthTotal,
          item.Width + item.width2,
          1,
        );
      } else {
        ConfigurationItemHelper.addDimensionVariantByNumber(
          item,
          VariantNumbers.Width,
          item.Width + item.width2,
          1,
        );
      }
      ConfigurationItemHelper.addDimensionVariantByNumber(
        item,
        VariantNumbers.WidthLeft,
        item.Width,
        1,
      );
      ConfigurationItemHelper.addDimensionVariantByNumber(
        item,
        VariantNumbers.WidthRight,
        item.width2,
        1,
      );
    }

    return result;
  }

  /**
   * Sets all length related variants (Length, LengthTop, LengthBottom)
   * @param item
   */
  private setLengthVariants(
    item: Client.ConfigurationItem,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    //Alu. items use length variants instead of height and width, so handle this by checking for the largest value of either dimension
    if (item.Width > item.Height) {
      ConfigurationItemHelper.addDimensionVariantByNumber(
        item,
        VariantNumbers.Length,
        item.Width,
        1,
      );
      ConfigurationItemHelper.addDimensionVariantByNumber(
        item,
        VariantNumbers.LengthTop,
        item.Width,
        1,
      );
      ConfigurationItemHelper.addDimensionVariantByNumber(
        item,
        VariantNumbers.LengthBottom,
        item.Width,
        1,
      );
    } else {
      ConfigurationItemHelper.addDimensionVariantByNumber(
        item,
        VariantNumbers.Length,
        item.Height,
        1,
      );
      ConfigurationItemHelper.addDimensionVariantByNumber(
        item,
        VariantNumbers.LengthTop,
        item.Height,
        1,
      );
      ConfigurationItemHelper.addDimensionVariantByNumber(
        item,
        VariantNumbers.LengthBottom,
        item.Height,
        1,
      );
    }

    return result;
  }

  /**
   * Sets all height related variants (Height, Trimming)
   * @param item
   */
  private setHeightVariants(
    item: Client.ConfigurationItem,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];
    let productLineId =
      item.cabinetSection && item.cabinetSection.cabinet.ProductLineId;

    if (!item.Product) return result;

    const maxHeight = ProductHelper.maxHeight(
      item.Product,
      item.Material ? item.Material : undefined,
    );
    const matchesMaxHeight = maxHeight === item.Height;
    const defaultHeight = ProductHelper.defaultHeight(
      item.Product,
      item.Material ? item.Material : undefined,
    );
    const matchesDefaultHeight = defaultHeight === item.Height;
    const matchesHeight = matchesMaxHeight || matchesDefaultHeight;

    // Trimming
    if (
      matchesHeight &&
      item.HeightReduction &&
      item.Product.heightReductionAllowed2(productLineId)
    ) {
      ConfigurationItemHelper.addVariantOptionByNumbersWithValue(
        item,
        VariantNumbers.Trimming,
        VariantNumbers.Values.Yes,
      );
    } else {
      ConfigurationItemHelper.addVariantOptionByNumbersWithValue(
        item,
        VariantNumbers.Trimming,
        VariantNumbers.Values.No,
      );
    }

    // Height
    if (
      (item.Product.useActualHeight2(productLineId) &&
        ProductHelper.isFlexHeight(item.Product, productLineId)) ||
      (item.HeightReduction &&
        item.Product.heightReductionAllowed2(productLineId)) ||
      (item.Width > item.Height &&
        item.ItemType !== Interface_Enums.ItemType.Backing) || //maybe do this for all non-filling items?
      (item.Product.canBeJoined2(productLineId) && item.Height > maxHeight) ||
      item.ItemType === Interface_Enums.ItemType.SwingDoor ||
      item.ItemType === Interface_Enums.ItemType.SwingFlexDoor
    ) {
      item.ActualHeight = Math.max(
        item.Height,
        item.ProductData?.MinHeightShippable ?? 0,
      );
    } else {
      item.ActualHeight = maxHeight;
    }
    ConfigurationItemHelper.addDimensionVariantByNumber(
      item,
      VariantNumbers.Height,
      item.ActualHeight,
      1,
    );
    if (item.ActualHeight) {
      ConfigurationItemHelper.addDimensionVariantByNumber(
        item,
        VariantNumbers.SidePanelHeight,
        item.ActualHeight,
        1,
      );
    }

    return result;
  }

  /**
   * Sets all depth related variants (Depth, DepthLeft, DepthRight)
   * @param item
   */
  private setDepthVariants(
    item: Client.ConfigurationItem,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    item.actualDepth = Math.max(
      item.Depth,
      item.ProductData?.MinDepthShippable ?? 0,
    );
    ConfigurationItemHelper.addDimensionVariantByNumber(
      item,
      VariantNumbers.Depth,
      item.actualDepth,
      1,
    );
    ConfigurationItemHelper.addDimensionVariantByNumber(
      item,
      VariantNumbers.DepthLeft,
      item.actualDepth,
      1,
    );
    ConfigurationItemHelper.addDimensionVariantByNumber(
      item,
      VariantNumbers.DepthRight,
      item.depth2,
      1,
    );

    return result;
  }

  /**
   * Sets all variants, that are of type variant and are not already set, to their default value.
   * @param item
   */
  public setMissingVariants(
    item: Client.ConfigurationItem,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    if (!item.Product) return result;

    // Find variants, that are not already set
    let missingVariants = Enumerable.from(
      item.Product.getVariants(item.cabinetSection.cabinet.ProductLineId),
    ).where(
      (v) =>
        v.Type === Interface_Enums.VariantType.Variant &&
        v.VariantOptions.length > 1 &&
        !item.VariantOptions.some((vo) => vo.VariantId === v.Id),
    );

    // Set the variants to the first available value (assumed to be the default value)
    for (let variant of missingVariants.toArray()) {
      let option = variant.VariantOptions[0];
      ConfigurationItemHelper.addVariantOption(item, option);
    }

    return result;
  }

  private reclaculateMaterialVariant(
    item: Client.ConfigurationItem,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];
    if (item.Material) {
      let set = false;
      for (let itemVariant of item.VariantOptions) {
        if (!itemVariant.variant) continue;

        if (
          item.Material.Type === Interface_Enums.MaterialType.Normal &&
          VariantTypeNumbers.NormalMaterial !== itemVariant.variant.TypeNumber
        )
          continue;
        if (
          item.Material.Type === Interface_Enums.MaterialType.Frame &&
          VariantTypeNumbers.FrameMaterial !== itemVariant.variant.TypeNumber
        )
          continue;
        if (
          item.Material.Type === Interface_Enums.MaterialType.Grip &&
          VariantTypeNumbers.GripMaterial !== itemVariant.variant.TypeNumber
        )
          continue;

        for (let variantOption of itemVariant.variant.VariantOptions) {
          if (item.Material.Number === variantOption.Number) {
            itemVariant.variantOption = variantOption;
            set = true;
          }
        }
      }
      if (!set) {
        if (App.useDebug) {
          console.warn(
            "Could not set item material variant for material '" +
              item.Material.DefaultName +
              "' on '" +
              item.Description +
              "'",
            item,
          );
        }
      }
    }
    return result;
  }

  private recalculateItemNo(item: Client.ConfigurationItem) {
    if (!item.Product) return;
    let productNo = item.Product.ProductNo;
    if (item.Product.AddToProductNo) {
      let addVariants = [
        item.Product.AddVariant1,
        item.Product.AddVariant2,
        item.Product.AddVariant3,
      ];
      for (let av of addVariants) {
        if (av < 0) break;
        let itemVariant = item.VariantOptions.filter(
          (iv) => iv.VariantId === av,
        )[0];
        if (itemVariant && itemVariant.variantOption) {
          productNo += itemVariant.variantOption.Number;
        } else {
          if (App.useDebug) {
            console.warn(
              'Could not find variant on item. variant is required to make a correct itemNo',
              item,
              av,
            );
          }
        }
      }
    }
    item.ItemNo = productNo;
  }

  private recalculateItemDescription(item: Client.ConfigurationItem) {
    if (!item.Product) return;
    if (item.isDescriptionLocked) return;
    item.Description = item.Product.Name;
  }

  private recalculateIsHidden(item: Client.ConfigurationItem) {
    item.IsHidden = !!(item.Product && item.Product.IsTemplate);
  }
}
