//#region Not used

export const generator = 'GENERATOR';

//public const string CustomFillingColor = "FillingColor";
//public const string CustomFillingHeight = "FillingHeight";
//public const string CustomFillingPosition = "FillingPosition";

export const SpotColor = 'SPOT_FARVE';

export const DoorType = 'TYPE';
export const RailWidthTop = 'LÆNG_LOFT';
export const RailWidthBottom = 'LÆNG_GULV';
export const RailEndStop = 'ENDESTOP';
//#endregion Not used

//#region General non specific

export const ExtraInfoOffset = -1000;
export const EndUserMark = -10000;

export const UseActualHeight = 'KAO_SÆTMÅL';

export const Height = 'HØJDE';
export const Width = 'BREDDE';
export const WidthLeft = 'BRED_VENST';
export const WidthRight = 'BRED_HØJRE';
export const WidthTotal = 'BREDDE_SAM';
export const Length = 'LÆNGDE';
export const LengthBottom = 'LÆNG_GULV';
export const LengthTop = 'LÆNG_LOFT';
export const Depth = 'DYBDE';
export const DepthLeft = 'DYBDE_HJ_V';
export const DepthRight = 'DYBDE_HJ_H';

export const Color = 'FARVE';
export const FrameColor = 'RAMMEFARVE';
export const GripColor = 'FARVE_GREB';

export const Trimming = 'RENSKÆRING';

export const AdjustmentScrew = 'BORING_STI';

//#endregion General non specific

//#region Interior specific
export const DepthOppositeItemRight = 'DYBDE_M_HO';
export const DepthOppositeItemLeft = 'DYBDE_M_VE';
export const DepthOppositeItemCornerFitting = 'TILF_HJ_B';
//endregion Interior specific

//#region Corpus specific

export const Joint = 'FISK';
export const JointPosition = 'FISK_POSIT';
//Only the values F and S is currently supported for FISK_VE and FISK_HO, see VariantCaulcationHelper
export const JointOtherSectionPositionLeft = 'FISK_VE';
export const JointOtherSectionPositionRight = 'FISK_HO';

export const JointSizeLeft = 'DYBDE_M_VE';
export const JointSizeRight = 'DYBDE_M_HO';

export const Spot = 'SPOT';
export const RecessSpot = 'UDFR_SPOT';
export const SpotCount = 'SPOT_ANTAL';

export const Drilling = 'BORING';
export const DrillingMeasurement = 'MÅL_INDV_G';

export const SidePanelLeftRight = 'GAVL_V_H';

export const EdgingTop = 'ENDEK_T_VH';
export const EdgingBottom = 'ENDEK_B_VH';

export const SidePanelWidth = 'MÅL_SP_ANS';
export const SidePanelHeight = 'MÅL_SP_T';

//#endregion Corpus specific

//#region Door specific

//Door related
export const DoorNumber = 'DØR_NR';
export const DoorHeight = 'HØJDE_DØR';
export const DoorWidth = 'BRED_DØR';
export const DoorWidthMax = 'BRED_MAX';
export const DoorWidthMaxStandardDoor = 'BRED_GDSDØ';
export const DoorFillingColor = 'PANELTYPE';
export const DoorFillingColorDetailed = 'PANELTYPE2';
export const DoorFillingCount = 'ANT_FYLD';
export const DoorDesignFillingCount = 'ANT_DESIGN';
export const VisibleFillingSizes = 'FYLD_SYN';
export const FillingSizes = [
  'FYLD1',
  'FYLD2',
  'FYLD3',
  'FYLD4',
  'FYLD5',
  'FYLD6',
  'FYLD7',
];
export const SoftClose = 'SOFTLUK';
export const DoorCornerCut = 'SKRÅ_DØR';

//Bars
export const BarHeight = 'SPROS_HØJD';
export const BarPlacement = 'SPROS_PLA';
export const LooseBarCount = 'ANT_LØSE';
export const FixedBarCount = 'ANT_FAST';
export const BarPlacementPositionTypes = [
  'FAST_LØS1',
  'FAST_LØS2',
  'FAST_LØS3',
  'FAST_LØS4',
  'FAST_LØS5',
  'FAST_LØS6',
];
export const BarPlacementPositions = [
  'POS1',
  'POS2',
  'POS3',
  'POS4',
  'POS5',
  'POS6',
];

export const ForceFixedBars = 'SPROS_FAST';
export const VerticalBarCount = 'SPROS_LODR';

//Grip
/** There are multiple variants starting with GREBTYPE,
 * but they all behave the same.
 * The only difference is they have other options.  */
export const Grip = 'GREBTYPE';
export const GripPlacementFrontside = 'GREB';
export const GripPlacementBackside = 'GREB_BAG';
export const GripPlacementHeight = 'HØJDE_GREB';

//#endregion Door specific

//#region Backing specific

export const FinishingList = 'AFSL_LISTE';
export const RecessBacking = 'UDFR_BAG';
export const TList = 'T_LISTE';

//#endregion Backing specific

//#region Swing specific

export const HingeSide = 'HAENGS_V_H';
export const HingeType = 'VALG_HANGS';
export const SwingPulloutSingle = 'VALG_UDTR';
export const SwingPulloutDouble = 'VALG_POS';

//#endregion Swing specific

// SwingFlex specific
//#region SwingFlex specific

export const DrawerFrontExtraWidth = 'BRED_EXTRA';
export const DrawerFrontWidth = 'VALG19_22';
export const LightLeft = 'LYS_V';
export const LightRight = 'LYS_H';
export const Plinth = 'TILH_SOKKE';
export const GableAdaptionToFittingPanel = 'GAVL_T_SKU';
export const AdaptionToFittingPanelAboveBelow = 'GAVL_T_PLA';
export const EdgeBandBehind = 'BAGKANTNIN';
export const EdgeBandTopBottom = 'ENDEKA_TB';
export const DoorOpeningMethod = 'VALG_AABNE';
export const SwingFlexHingeSide = 'SWING_V_H';
export const HingeCount = 'ANT_HANGSE';
export const DoorExtraWidth = 'BRED_EXTRA';
export const DoorCoversPlinth = 'SKJUL_SOK';
export const MiterCut = 'SMIG';

//#endregion SwingFlex specific

export const ExludeFromManualSelectionList = [
  Color,
  FrameColor,
  Trimming,
  TList,
  Joint,
  Spot,
  RecessSpot,
  FinishingList,
  RecessBacking,
  SpotColor,
  UseActualHeight,
  GableAdaptionToFittingPanel,
  AdaptionToFittingPanelAboveBelow,
  Plinth,
  MiterCut,
  DoorExtraWidth,
  DrawerFrontWidth,
  GripColor,
];

export module Values {
  export const No = '0';
  export const Yes = '1';

  export const SidePanelLeftRight_Left = '2';
  export const SidePanelLeftRight_Right = '3';

  export const EdgingTop_LeftAndRight = '4';
  export const EdgingBottom_LeftAndRight = '4';

  export const Grip_None = '0';
  export const Grip_Left = '2';
  export const Grip_Right = '3';
  export const Grip_Both = '4';

  export const None = '0';
  export const Left = '2';
  export const Right = '3';
  export const Both = '4';

  export const HingeSide_Left = 'V';
  export const HingeSide_Right = 'H';

  export const SwingPulloutDouble_None = '0';
  export const SwingPulloutDouble_Left = '2';
  export const SwingPulloutDouble_Right = '3';
  export const SwingPulloutDouble_Both = '4';

  export const NoVerticalBars = '0';

  export const JointPositionNoJoint = '0';
  export const JointPositionFront = 'F';
  export const JointPositionSide = 'S';

  export const FittingPanel_None = '0';
  export const FittingPanel_Above = '1';
  export const FittingPanel_Below = '2';

  export const Hinge110Deg = '1';
  export const Hinge155WithBluMotion = '2';
  export const Hinge155WithWithPTO = '3';

  export const DoorOpeningMethod_None = '0';
  export const DoorOpeningMethod_Grip = '1';
  export const DoorOpeningMethod_PushToOpen = '2';

  export const ProductLineWidth_19mm = '19';
  export const ProductLineWidth_22mm = '22';

  export const enum EdgeBandTopBottom {
    None = '0',
    Bottom = '5',
    Top = '6',
    TopAndBottom = '7',
  }
}

export const enum SoftCloseValues {
  SoftClose_Unused = '',
  SoftClose_None = '0',
  SoftClose_Left = '2',
  SoftClose_Right = '3',
}

export const enum DrawerFrontExtraWidthValues {
  None = '0',
  Left = '2',
  Right = '3',
  LeftAndRight = '4',
}
