import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { BaseSnapService } from 'app/ts/services/snap/BaseSnapService';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import Enumerable from 'linq';
export class SnapUnderService extends BaseSnapService<SnapUnderService.SnapInfo> {
  static supportsItem(item: Client.ConfigurationItem): boolean {
    return item.snapUnder || item.snapCoatHanger;
  }

  private readonly possiblePositions: SnapUnderService.PositionInfo[];
  private readonly otherItems: Client.ConfigurationItem[];
  private readonly snapUnderTargets: {
    readonly x: number;
    readonly centerX: number;
    readonly y: number;
    readonly topY: number;
    readonly frontZ: number;
    readonly width: number;
    readonly depth: number;
    readonly centerZ: number;

    readonly isInNeighborCorner: boolean;
    readonly item: Client.ConfigurationItem;
  }[];

  constructor(
    cabinetSection: Client.CabinetSection,
    item: Client.ConfigurationItem,
    dragStartPoint: Interface_DTO_Draw.Vec2d,
    fallbackSnapService: ISnapInfoService,
  ) {
    if (!SnapUnderService.supportsItem(item)) {
      throw new Error('Unsupported item');
    }
    super(cabinetSection, item, dragStartPoint, fallbackSnapService);
    this.otherItems = cabinetSection.interior.items.filter((i) => i !== item);
    if (cabinetSection.isSwing) {
      this.otherItems.push(
        ...this.cabinetSection.swing.items.filter(
          (i) => i.ItemType === Interface_Enums.ItemType.SwingCorpus,
        ),
      );
    }
    if (cabinetSection.isSwingFlex) {
      let shelfs = Enumerable.from(this.cabinetSection.swingFlex.areas)
        .selectMany((area) => area.areaItems)
        .where((item) => item.isShelf);

      this.otherItems = this.otherItems.concat(shelfs.toArray());
    }

    this.snapUnderTargets = this.otherItems
      .filter((target) => this.isSnapTarget(item, target))
      .map((item) => ({
        x: item.X,
        centerX: item.centerX,
        centerZ: item.centerZ,
        y: item.Y,
        topY: item.topY,
        frontZ: item.frontZ,
        width: item.Width,
        depth: item.Depth,
        isInNeighborCorner: false,
        item: item,
      }));

    if (cabinetSection.leftNeighbor) {
      for (let neighborItem of cabinetSection.leftNeighbor.interior.items) {
        if (
          !(neighborItem.isCorner2WayFlex || neighborItem.isCornerDiagonalCut)
        )
          continue;
        if (!this.isSnapTarget(item, neighborItem)) continue;
        this.snapUnderTargets.push({
          x: neighborItem.Z,
          centerX: neighborItem.Z + neighborItem.width2 / 2,
          centerZ: neighborItem.depth2 / 2,
          depth: neighborItem.depth2,
          frontZ: neighborItem.depth2,
          item: neighborItem,
          isInNeighborCorner: true,
          topY: neighborItem.topY,
          width: neighborItem.width2,
          y: neighborItem.Y,
        });
      }
    }

    this.possiblePositions = this.getPossiblePositions();
  }

  private isSnapTarget(
    item: Client.ConfigurationItem,
    target: Client.ConfigurationItem,
  ): boolean {
    if (item.snapUnder && target.isSnapUnderTarget) return true;
    if (item.snapCoatHanger && target.isCoatHanger) return true;
    return false;
  }

  protected overriddenGetSnapInfo(
    pos: Interface_DTO_Draw.Vec2d,
  ): SnapUnderService.SnapInfo | undefined {
    let mouseOffset = VectorHelper.subtract(pos, this.dragStartPoint);
    let rawItemPos = VectorHelper.add(mouseOffset, this.item);

    let sortedPositions = this.possiblePositions.sort(
      (p1, p2) =>
        VectorHelper.dist(rawItemPos, p1) - VectorHelper.dist(rawItemPos, p2),
    );
    let bestPositionMiddle = sortedPositions[0];

    if (!bestPositionMiddle) return;

    let minX = bestPositionMiddle.snapsTo.x;
    let maxX =
      bestPositionMiddle.snapsTo.x +
      bestPositionMiddle.snapsTo.width -
      bestPositionMiddle.Width;
    let x = ObjectHelper.clamp(minX, rawItemPos.X, maxX);
    let bestPosition = {
      ...bestPositionMiddle,
      X: x,
    };
    if (VectorHelper.dist(bestPosition, rawItemPos) > Constants.snapDist)
      return;
    let newSizeZ;
    let dropOffset = VectorHelper.subtract(bestPosition, this.item);
    {
      let productData = this.item.ProductData;
      if (productData) {
        newSizeZ = ObjectHelper.clamp(
          productData.MinDepth,
          bestPositionMiddle.Depth,
          productData.MaxDepth,
        );
      }
    }

    let result: SnapUnderService.SnapInfo = {
      dropOffset: dropOffset,
      pulloutRestriction: this.item.isPullout
        ? Math.max(
            Enums.PulloutWarningSeverity.None,
            ...this.cabinetSection.doors.pulloutWarningAreas
              .filter((pwa) =>
                pwa.overlaps(
                  bestPositionMiddle.X,
                  bestPositionMiddle.X + this.item.Width,
                ),
              )
              .map((pwa) => pwa.severity),
          )
        : Enums.PulloutWarningSeverity.None,
      mirror: false,
      rulers: [],
      suggestedItemOffsets: [],
      horizontalGuidelineHeights: [],
      snapsTo: bestPositionMiddle.snapsTo.items,
      snappingItems: [this.item],
      newSize: {
        Z: newSizeZ,
      },
    };
    return result;
  }

  protected overriddenPlace(
    lastSnapInfo: SnapUnderService.SnapInfo,
  ): [Client.ConfigurationItem] {
    //do nothing, base class handles everything
    return [this.item];
  }

  private getPossiblePositions(): SnapUnderService.PositionInfo[] {
    let result: SnapUnderService.PositionInfo[] = [];
    for (let snapUnderTarget of this.snapUnderTargets) {
      let size: Interface_DTO_Draw.Vec3d = {
        X: ObjectHelper.clamp(
          this.item.minWidth,
          snapUnderTarget.width,
          this.item.maxWidth,
        ),
        Y: this.item.Height,
        Z: ObjectHelper.clamp(
          this.item.minDepth,
          snapUnderTarget.depth - this.item.reductionDepth,
          this.item.maxDepth,
        ),
      };
      let targetItemPos: SnapUnderService.PositionInfo = {
        X: snapUnderTarget.centerX - size.X / 2,
        Y: this.item.snapCoatHanger
          ? snapUnderTarget.topY - this.item.Height + this.item.snapOffsetY
          : snapUnderTarget.y - this.item.Height + this.item.snapOffsetY,

        Z: this.item.snapCoatHanger
          ? snapUnderTarget.centerZ - size.Z / 2 - this.item.reductionDepth
          : snapUnderTarget.frontZ - size.Z - this.item.reductionDepth,
        Width: size.X,
        Height: size.Y,
        Depth: size.Z,
        snapsTo: {
          x: snapUnderTarget.x,
          width: snapUnderTarget.width,
          items: [snapUnderTarget.item],
        },
      };

      result.push(targetItemPos);
    }
    return result;
  }
}

export module SnapUnderService {
  export interface SnapInfo extends Client.SnapInfo {}

  export interface PositionInfo extends Interface_DTO_Draw.Cube {
    snapsTo: {
      x: number;
      width: number;
      items: Client.ConfigurationItem[];
    };
  }
}
