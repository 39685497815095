import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as App from 'app/ts/app';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import * as VariantHelper from 'app/ts/util/VariantHelper';

import { PermissionService } from 'app/identity-and-access/permission.service';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BackingLogic } from 'app/ts/services/ConfigurationLogic/BackingLogic';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
import { ModalService } from 'app/ts/services/ModalService';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Observable } from 'app/ts/util/Observable';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { DragInfoService } from './drag-info.service';
import { EditorTypeService } from 'app/ts/viewmodels/editor-type.service';
import { Pickable } from '@Interfaces/index';

type MaterialInfo = {
  materialPickable: Pickable<Client.Material>;
  openModal: () => Promise<any>;
};

@Component({
  selector: 'item-editor',
  templateUrl: './itemEditor.html',
  styleUrls: ['../../style/editor.scss'],
})
export class ItemEditorVm extends BaseVm implements OnChanges, OnDestroy {
  private static readonly noProducts: Client.Product[] = [];

  constructor(
    baseVmService: BaseVmService,
    permissions: PermissionService,
    private readonly modalService: ModalService,
    private readonly interiorLogic: InteriorLogic,
    private readonly backingLogic: BackingLogic,
    private readonly dragInfo: DragInfoService,
    private readonly editorType: EditorTypeService,
  ) {
    super(baseVmService);
    this.displayAdminOnlyVariants = permissions.canShowOnlyAdminVariants;
    this.subscribeTo(dragInfo.dragItem$, (_) => this.clearCache());
  }

  private alternateProductDict: {
    [ProductGroupingNo: string]: { [productId: number]: Client.Product[] };
  } = {
    '': {},
  };

  private ongoingTouch: { observable: Observable<TouchEvent> } | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    this.clearCache();
  }

  public readonly pickableProduct =
    new Client.ConverterPickable<Client.Product | null>(
      () => this.item && this.item.Product,
      (prod) => {
        if (!prod) {
          return {
            id: '',
            disabledReason: 'No product selected',
            isSelected: false,
            item: null,
            name: 'No product selected',
            override: false,
          };
        } else {
          return {
            id: prod.ProductNo,
            imageUrl: prod.picturePath || undefined,
            isSelected: false,
            disabledReason: null,
            name: prod.Name,
            item: prod,
            override: prod.overrideChain,
          };
        }
      },
    );

  public get showGripSelector(): boolean {
    if (!this.cache.showGripSelector) {
      if (this.item) {
        this.cache.showGripSelector =
          this.item.canHaveGrip &&
          this.item.cabinetSection.swingFlex.availableDoorGrips.length > 0;
      } else {
        this.cache.showGripSelector = false;
      }
    }
    return this.cache.showGripSelector;
  }

  public readonly currentGripPickable =
    new Client.ConverterPickable<Client.Product | null>(
      () => this.item?.gripProduct ?? null,
      (grip) =>
        !!grip
          ? ProductHelper.getPickable(grip)
          : {
              disabledReason: null,
              isSelected: false,
              item: null,
              name: this.translate(
                'editor_swing_select_door_grip',
                'Select grip',
              ),
              override: false,
            },
    );

  private get pickableGrips(): Pickable<Client.Product | null>[] {
    if (this.cache.pickableGrips === undefined) {
      if (!this.item?.canHaveGrip) {
        this.cache.pickableGrips = [];
      } else {
        this.cache.pickableGrips =
          this.item?.cabinetSection.interior.availableDrawerGrips.map(
            (grip) => ({
              ...ProductHelper.getPickable(grip),
              isSelected: grip.Id === this.item?.gripProduct?.Id,
              groupName: this.translate('pickable_grip_group', 'Grips'),
            }),
          );
      }
    }
    return this.cache.pickableGrips;
  }

  public async selectGrip() {
    if (!this.item) return;
    let newGrip;
    try {
      const modal = this.modalService.getPickableSelector(
        [this.item.cabinetSection.interior.noGripPickable].concat(
          this.pickableGrips,
        ),
        this.translate('editor_interior_select_grip', 'Select Grip Type'),
        'product grip-product',
        false,
      );
      newGrip = await modal.result;
    } catch (ex) {
      //modal was cancelled
      return;
    }
    if (!this.item) return;
    this.item.gripProduct = newGrip;
    this.item.gripMaterial = !!newGrip
      ? MaterialHelper.getDefaultMaterial(newGrip.materials)
      : null;
    this.setChanged();
  }

  public readonly currentGripMaterialPickable =
    new Client.ConverterPickable<Client.Material | null>(
      () => this?.item?.gripMaterial ?? null,
      (mat) =>
        !!mat
          ? MaterialHelper.getPickable(mat)
          : {
              disabledReason: null,
              isSelected: false,
              item: null,
              name: this.translate(
                'editor_swing_select_door_grip_material',
                'Select grip material',
              ),
              override: false,
            },
    );

  public async selectGripMaterial() {
    if (!this.item) return;
    const grip = this.item.gripProduct;
    if (!grip) return;

    let fullCatalog =
      this.item.editorAssets.fullCatalog &&
      this.item.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;
    let mats = grip.materials
      .filter(
        (mat) =>
          mat.Id === this.item?.gripMaterial?.Id ||
          (!mat.isDiscontinued && (!mat.isOverride || fullCatalog)),
      )
      .map((m) => MaterialHelper.getPickable(m));
    MaterialHelper.sortPickableMaterials(mats);
    let newMat;
    try {
      const modal = this.modalService.getPickableSelector(
        mats,
        this.translate(
          'editor_interior_select_grip_material',
          'Select Grip Material',
        ),
        'material grip-material',
        true,
      );
      newMat = await modal.result;
    } catch (ex) {
      //modal cancelled
      return;
    }
    if (!this.item) return;
    this.item.gripMaterial = newMat;
    this.setChanged();
  }

  private _item: Client.ConfigurationItem | null = null;

  @Input()
  public get item(): Client.ConfigurationItem | null {
    return this._item;
  }
  public set item(val: Client.ConfigurationItem | null) {
    this._item = val;
  }

  /** Emits a value whenever this component changes the current item */
  @Output()
  public itemChanged = new EventEmitter<void>();

  @Input()
  public selectedProductLine: Interface_DTO.ProductLine | undefined;

  @Input()
  public showPosition = true;

  @Input()
  public readonly displayAdminOnlyVariants: boolean;

  private cache: Partial<{
    drillDepth: number | null;
    materials: MaterialInfo[];
    pickableGrips: Pickable<Client.Product | null>[];
    showGripSelector: boolean;
  }> = {};

  public get alternateProducts(): Client.Product[] {
    if (!this.item) return ItemEditorVm.noProducts;
    if (!this.item.Product) return ItemEditorVm.noProducts;
    if (!this.item.Product.ProductGroupingNo) return ItemEditorVm.noProducts;

    let fullCatalog =
      this.item.editorAssets.fullCatalog &&
      this.item.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
    let productId = this.item.ProductId;
    let key =
      (this.item && this.item.Product && this.item.Product.ProductGroupingNo) ||
      '';
    let dict = this.alternateProductDict[key];
    if (!dict) {
      dict = {};
      this.alternateProductDict[key] = dict;
    }
    let result = dict[productId];
    if (!result) {
      result =
        this.item.editorAssets.productGroupsDict[
          this.item.Product.ProductGroupingNo
        ];
      if (!result) {
        result = [];
      }
      var plid = this.item.cabinetSection.cabinet.ProductLineId;
      result = result.filter((p) => {
        if (p.Id === productId) return true;
        if (fullCatalog) return true;
        if (!p.Enabled) return false;
        if (p.overrideChain) return false;
        if (p.ProductLineIds.indexOf(plid) < 0) return false;
        return true;
      });
      dict[productId] = result;
    }
    return result;
  }

  public clearCache() {
    this.cache = {};
  }

  /** Should be called when this component makes a change to the selected item. Should *not* be called when the selected item changes. */
  public setChanged() {
    if (this.itemChanged) this.itemChanged.emit();
    this.clearCache();
  }

  private async openMaterialModal(
    type: Interface_Enums.MaterialType,
    currentMaterialId: number | null,
  ): Promise<Client.ProductMaterial | null> {
    const pickables = this.getMaterialPickables(type, currentMaterialId);
    try {
      let modal = this.modalService.getPickableSelector(
        pickables,
        this.translate(
          'interior_item_material_selector_title_m',
          'Select Material',
        ),
        'material interior-item-material',
        true,
      );
      return await modal.result;
    } catch (e: any) {
      //User cancelled or closed modal
      return null;
    }
  }

  private getMaterialPickables(
    materialType: Interface_Enums.MaterialType,
    currentMaterialId: number | null,
  ): Pickable<Client.ProductMaterial>[] {
    if (!this.item) return [];
    let fullCatalog =
      this.item.editorAssets.fullCatalog &&
      this.item.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;
    let mats = this.item.Product ? this.item.Product.materials : [];

    let typeMats = mats.filter((mat) => mat.Type === materialType);

    let pickables = typeMats
      .filter(
        (mat) =>
          mat.Id === currentMaterialId ||
          (!mat.isDiscontinued && (!mat.isOverride || fullCatalog)),
      )
      .map((mat) => MaterialHelper.getPickable(mat));

    MaterialHelper.sortPickableMaterials(pickables);
    let selected = pickables.find((p) => p.item.Id === currentMaterialId);
    if (selected) {
      selected.isSelected = true;
    }

    return pickables;
  }

  public get Materials(): MaterialInfo[] {
    if (this.cache.materials === undefined) {
      let r: MaterialInfo[] | undefined = this.item?.allMaterials
        .filter((materialVariant) => !!materialVariant.material)
        .map((materialVariant, materialIndex) => ({
          materialPickable: MaterialHelper.getPickable(
            materialVariant.material!,
          ),
          openModal: async () => {
            let newMat = await this.openMaterialModal(
              materialVariant.materialType,
              materialVariant.material?.Id ?? null,
            );
            if (!newMat) return;
            if (!this.item) return;
            if (materialIndex === 0) {
              this.item.MaterialId = newMat.Id;
            } else if (materialIndex === 1) {
              this.item.Material2 = newMat;
            } else if (materialIndex === 2) {
              this.item.Material3 = newMat;
            } else {
              throw new Error(
                `Too many materials; don't know what to do with material index ${materialIndex}`,
              );
            }
            this.setChanged();
          },
        }));
      this.cache.materials = r ?? [];
    }
    return this.cache.materials;
  }

  public depthChanged() {
    console.log('TODO: ItemEditorVm.depthChanged');
  }

  public heightChanged() {
    console.log('TODO: ItemEditorVm.heightChanged ');
  }

  public widthChanged() {
    console.log('TODO: ItemEditorVm.widthChanged ');
  }

  public get width(): number {
    return this.item ? this.item.Width : 0;
  }
  public set width(val: number) {
    if (!this.item) return;
    this.item.IsLocked = true;
    this.item.Width = ObjectHelper.clamp(
      this.item.minWidth,
      val,
      this.item.maxWidth,
    );
    this.setChanged();
  }

  public get width2(): number {
    return this.item ? this.item.width2 : 0;
  }
  public set width2(val: number) {
    if (!this.item) return;
    this.item.IsLocked = true;
    this.item.width2 = ObjectHelper.clamp(
      this.item.minWidth,
      val,
      this.item.maxWidth,
    );
    this.setChanged();
  }

  public get height(): number {
    return this.item ? this.item.Height : 0;
  }
  public set height(val: number) {
    if (!this.item) return;
    this.item.IsLocked = false;
    this.item.HeightReduction = true;
    this.item.Height = ObjectHelper.clamp(
      this.item.minHeight,
      val,
      this.item.maxHeight,
    );
    this.item.IsLocked = true;
    this.setChanged();
  }

  public get depth(): number {
    return this.item ? this.item.Depth : 0;
  }
  public set depth(val: number) {
    if (!this.item) return;
    this.item.IsLocked = true;
    this.item.Depth = ObjectHelper.clamp(
      this.item.minDepth,
      val,
      this.item.maxDepth,
    );
    this.setChanged();
  }

  public get depth2(): number {
    return this.item ? this.item.depth2 : 0;
  }
  public set depth2(val: number) {
    if (!this.item) return;
    this.item.IsLocked = true;
    this.item.depth2 = ObjectHelper.clamp(
      this.item.minDepth,
      val,
      this.item.maxDepth,
    );
    this.setChanged();
  }

  public get isHeightReductionPossible(): boolean {
    let productLineId = this.selectedProductLine
      ? this.selectedProductLine.Id
      : this.item
        ? this.item.cabinetSection.cabinet.ProductLineId
        : 0;
    let result =
      !!this.item && this.item.isHeightReductionPossible(productLineId);
    return result;
  }

  public get warnAboutHeightReduction(): boolean {
    let productLineId = this.selectedProductLine
      ? this.selectedProductLine.Id
      : this.item
        ? this.item.cabinetSection.cabinet.ProductLineId
        : 0;
    let result =
      !!this.item && this.item.warnAboutHeightReduction(productLineId);
    return result;
  }

  public get heightReduction(): boolean {
    return !!this.item && this.item.HeightReduction;
  }
  public set heightReduction(val: boolean) {
    if (!this.item) return;
    this.item.HeightReduction = val;
    this.setChanged();
  }

  public get locked(): boolean {
    return !!this.item && this.item.IsLocked;
  }
  public set locked(val: boolean) {
    if (this.item) this.item.IsLocked = val;
    this.setChanged();
  }

  public startDrag(evt: MouseEvent | TouchEvent): void {
    if (!this.item || !this.item.Product) return;
    this.dragInfo.value = {
      productId: this.item.ProductId,
      materialId: this.item.MaterialId,
      item: this.item,
      touchEventObservable: null,
    };

    evt.preventDefault();
    evt.stopPropagation();
  }

  public stopDrag(evt: MouseEvent | TouchEvent) {
    this.dragInfo.value = undefined;
  }

  public get allowUserVariants(): boolean {
    if (!this.item || !this.item.Product) return false;

    let productLineId: Interface_Enums.ProductLineId;
    if (this.selectedProductLine) productLineId = this.selectedProductLine.Id;
    else if (this.item.cabinetSection.CabinetIndex > 0)
      productLineId = this.item.cabinetSection.cabinet.ProductLineId;
    else if (this.item.cabinetSection.cabinet.floorPlan.cabinets.length > 1)
      productLineId =
        this.item.cabinetSection.cabinet.floorPlan.cabinets[1].ProductLineId;
    else {
      let defaultPl = this.item.editorAssets.productLines.filter(
        (pl) => pl.IsDefault,
      )[0];
      if (defaultPl) productLineId = defaultPl.Id;
      else {
        productLineId = this.item.cabinetSection.cabinet.ProductLineId;
      }
    }
    let fittingsEnabled = this.item.Product.productCategory
      ? this.item.Product.productCategory.fittingsEnabled(productLineId)
      : true;
    return fittingsEnabled;
  }

  public get canChangePosX(): boolean {
    let result =
      !!this.item &&
      (this.item.isGable || this.item.isTemplate) &&
      this.showPosition !== false;
    return result;
  }
  public get posXMin() {
    return 0;
  }
  public get posXMax() {
    if (!this.item) return 0;
    let interior = this.item.cabinetSection.interior;
    return interior.cube.Width - this.item.Width;
  }
  public get posX() {
    if (!this.item) return 0;
    return this.item.X - this.item.cabinetSection.interior.cube.X;
  }
  public set posX(val: number) {
    if (!this.item) return;
    if (val < 0) {
      val += this.item.cabinetSection.interior.cube.Width - this.item.Width;
    }

    let relativeX = ObjectHelper.clamp(this.posXMin, val, this.posXMax);

    this.item.X = relativeX + this.item.cabinetSection.interior.cube.X;
    this.item.IsLocked = true;
    this.setChanged();
  }

  private posXShiftInterval: number | undefined = undefined;
  public startShiftPosX(step: number) {
    this.shiftPosX(step);

    let shiftRate = 200; //ms
    this.posXShiftInterval = setInterval(() => this.shiftPosX(step), shiftRate);
  }

  public stopShiftPosX() {
    clearInterval(this.posXShiftInterval);
    this.setChanged();
    this.editorType.floorPlan.triggerUpdate();
  }

  private shiftPosX(step: number) {
    if (!this.item) return;
    if (App.debug.showTimings) console.time('shiftPosX');
    try {
      let newVal =
        this.item.X - this.item.cabinetSection.interior.cube.X + step;

      let relativeX = ObjectHelper.clamp(this.posXMin, newVal, this.posXMax);
      this.item.X = relativeX + this.item.cabinetSection.interior.cube.X;
      this.item.IsLocked = true;

      let section = this.item.cabinetSection;
      this.interiorLogic.recalculatePartOne(section);
      this.backingLogic.recalculatePartOne(section);
      this.interiorLogic.recalculatePartTwo(section);
      this.backingLogic.recalculatePartTwo(section);
      section.triggerRulerUpdate();
    } finally {
      if (App.debug.showTimings) console.timeEnd('shiftPosX');
    }
  }

  public get showDrillDepth(): boolean {
    if (!this.item || !this.item.Product) return false;
    return ProductHelper.hasVariant(
      this.item.Product,
      VariantNumbers.DrillingMeasurement,
    );
  }

  public get drillDepthRequired(): boolean {
    if (!this.item || !this.item.Product) return false;

    let hasDrillingVariant = ProductHelper.hasVariant(
      this.item.Product,
      VariantNumbers.Drilling,
    );

    if (!hasDrillingVariant) return false;

    let drillingValue = VariantHelper.getItemVariantValue(
      this.item.Product.getAllVariants(),
      this.item.VariantOptions,
      VariantNumbers.Drilling,
    );

    // We assume that no-value "" means No, since
    // the value has historically not been set
    if (drillingValue === VariantNumbers.Values.No || drillingValue === '') {
      return false;
    }

    return true;
  }

  public get drillDepth(): number | null {
    if (!this.item) return null;
    if (this.cache.drillDepth === undefined) {
      this.cache.drillDepth = this.getDrillDepth2();
    }
    return this.cache.drillDepth;
  }

  private getDrillDepth2(): number | null {
    if (!this.item) return null;
    if (!this.item.Product) return null;
    let drillDepthValue = VariantHelper.getItemVariantValueAsNumber(
      this.item.Product.getAllVariants(),
      this.item.VariantOptions,
      VariantNumbers.DrillingMeasurement,
    );
    if (drillDepthValue === 0) return null;

    return drillDepthValue;
  }

  public set drillDepth(val: number | null) {
    if (!this.item || val == null || val === 0) return;
    ConfigurationItemHelper.addDimensionVariantByNumber(
      this.item,
      VariantNumbers.DrillingMeasurement,
      val,
    );
    this.setChanged();
  }

  // #region touch events
  public touchStart(evt: TouchEvent): void {
    if (this.ongoingTouch) {
      this.ongoingTouch.observable.value = evt;
    } else {
      if (!this.item) {
        return;
      }
      let observable = new Observable(evt);
      this.ongoingTouch = {
        observable: observable,
      };
      this.dragInfo.value = {
        productId: this.item.ProductId,
        materialId: this.item.MaterialId,
        item: this.item,
        touchEventObservable: observable,
      };
    }
  }
  public touchMove(evt: TouchEvent): void {
    if (this.ongoingTouch) {
      this.ongoingTouch.observable.value = evt;
    }
  }
  public touchEnd(evt: TouchEvent): void {
    if (evt.touches.length > 0) {
      //Touch is not done, but a finger has been lifted
      return;
    }
    if (this.ongoingTouch) {
      this.ongoingTouch = undefined;
    }
  }
  public touchCancel(evt: TouchEvent): void {
    this.touchEnd(evt);
  }

  // #endregion touch events
}
