import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { BaseSnapService } from './BaseSnapService';
import { neighbors } from '@Util/ArrayHelper';
import { ProductHelper } from '@Util/ProductHelper';
/**
 * Helper to provide info about snap positions. Only expected to last from dragStart to dragEnd
 */
export class FittingPanelGableSnapService extends BaseSnapService<FittingPanelGableSnapService.SnapInfo> {
  static supportsItems(items: Client.ConfigurationItem[]): boolean {
    return items.length === 1 && items[0].isFittingPanel;
  }

  private readonly possiblePositions: FittingPanelGableSnapService.PositionInfo[];
  private readonly suggestedOffsets: Interface_DTO_Draw.Vec2d[];
  private readonly mouseOffset: Interface_DTO_Draw.Vec2d;

  private _previousRawItemPos?: Interface_DTO_Draw.Vec2d = undefined;
  private readonly fittingPanelSpacerWidth: number;

  constructor(
    cabinetSection: Client.CabinetSection,
    item: Client.ConfigurationItem,
    dragStartPoint: Interface_DTO_Draw.Vec2d,
    fallbackSnapService: ISnapInfoService,
  ) {
    super(cabinetSection, item, dragStartPoint, fallbackSnapService);

    this.fittingPanelSpacerWidth =
      item.cabinetSection.cabinet.productLine.Properties.FittingPanelSpacerWidth;
    this.possiblePositions = this.getPossiblePositions(item);
    this.suggestedOffsets = this.possiblePositions.map((p) =>
      this.toOffset(p, item),
    );

    this.mouseOffset = VectorHelper.subtract(
      dragStartPoint,
      item as Interface_DTO_Draw.Vec2d,
    );
  }

  private getPossiblePositions(
    item: Client.ConfigurationItem,
  ): FittingPanelGableSnapService.PositionInfo[] {
    const result: FittingPanelGableSnapService.PositionInfo[] = [];

    const itemWidth = item.Width + this.fittingPanelSpacerWidth;
    const itemMinHeight = !!item.Product
      ? Math.min(
          ...item.Product.productGroup.map((p) => ProductHelper.minHeight(p)),
        )
      : item.minHeight;

    const shelves = this.cabinetSection.interior.items
      .concat(this.cabinetSection.swingFlex.items)
      .filter((i) => i.isShelf_19_22);

    const gables = this.cabinetSection.interior.items
      .concat(this.cabinetSection.corpus.itemsLeft)
      .concat(this.cabinetSection.corpus.itemsRight)
      .concat(this.cabinetSection.swingFlex.items)
      .filter((gable) => gable.isGable && !gable.isFittingPanel);

    const floorY = this.cabinetSection.interior.cube.Y;

    for (const mountOnLeftSideOfGable of [true, false]) {
      if (mountOnLeftSideOfGable && !item.drilledLeft) continue;
      if (!mountOnLeftSideOfGable && !item.drilledRight) continue;

      for (let gable of gables) {
        if (mountOnLeftSideOfGable && !gable.drilledLeft) continue;
        if (!mountOnLeftSideOfGable && !gable.drilledRight) continue;

        const x = mountOnLeftSideOfGable
          ? gable.X - itemWidth
          : gable.rightX + this.fittingPanelSpacerWidth;

        let gableShelvesEnumerable = Enumerable.from(shelves);
        if (mountOnLeftSideOfGable) {
          gableShelvesEnumerable = gableShelvesEnumerable.where(
            (shelf) => shelf.rightX === gable.X,
          );
        } else {
          gableShelvesEnumerable = gableShelvesEnumerable.where(
            (shelf) => shelf.X === gable.rightX,
          );
        }

        const gableShelves = gableShelvesEnumerable
          .orderBy((shelf) => shelf.Y)
          .toArray();
        const neighborShelves = neighbors(gableShelves, false, false);

        for (let neighbor of neighborShelves) {
          const bottomY = neighbor[0]?.topY ?? floorY;
          const topY =
            neighbor[1]?.Y ?? floorY + this.cabinetSection.interior.cube.Height;
          const height = topY - bottomY;
          if (height >= itemMinHeight) {
            result.push({
              Height: height,
              Width: itemWidth,
              gable,
              bottomShelf: neighbor[0],
              topShelf: neighbor[1],
              Y: bottomY,
              X: x,
            });
          }
        }
      }
    }
    return result;
  }

  protected overriddenGetSnapInfo(
    position: Interface_DTO_Draw.Vec2d,
  ): FittingPanelGableSnapService.SnapInfo | undefined {
    let rawItemPos = VectorHelper.subtract(position, this.mouseOffset);

    if (!this._previousRawItemPos) {
      this._previousRawItemPos = rawItemPos;
    }

    if (!this.possiblePositions || this.possiblePositions.length === 0) {
      return;
    }

    let sortedSuggestions = Enumerable.from(this.possiblePositions)
      .orderBy((possiblePos) =>
        VectorHelper.contains(position, possiblePos) ? 0 : 1,
      )
      .thenBy((p) => Math.abs(p.X - rawItemPos.X))
      .thenBy((p) => Math.abs(p.Y - rawItemPos.Y));

    let bestSuggestion = sortedSuggestions.first();

    let _dropOffset = {
      ...VectorHelper.subtract(bestSuggestion, this.item),
      Z: 0,
    };

    let _result: FittingPanelGableSnapService.SnapInfo = {
      dropOffset: _dropOffset,
      pulloutRestriction: Enums.PulloutWarningSeverity.None,
      rulers: [],
      suggestedItemOffsets: this.item.isTemplate ? [] : this.suggestedOffsets,
      mirror: false,
      horizontalGuidelineHeights: [],
      gable: bestSuggestion.gable,
      topShelf: bestSuggestion.topShelf,
      bottomShelf: bestSuggestion.bottomShelf,
      snapsTo: [
        bestSuggestion.gable!,
        bestSuggestion.topShelf!,
        bestSuggestion.bottomShelf!,
      ].filter((i) => !!i),
      snappingItems: [this.item],
    };

    return _result;
  }

  protected overriddenPlace(
    lastSnapInfo: FittingPanelGableSnapService.SnapInfo,
  ): [Client.ConfigurationItem] {
    if (lastSnapInfo.bottomShelf && lastSnapInfo.topShelf) {
      const distanceBetweenShelves =
        lastSnapInfo.topShelf.Y - lastSnapInfo.bottomShelf.topY;
      this.item.trySetHeight(distanceBetweenShelves, true, true);
    }

    for (let i = 0; i < this.cabinetSection.swingFlex.areas.length; i++) {
      let area = this.cabinetSection.swingFlex.areas[i];

      if (this.lastSnapInfo) {
        let rawItemPos = VectorHelper.subtract(area.insideRect, this.item);
        if (
          area.insideRect.Width - (area.insideRect.Width + rawItemPos.X) ===
            this.fittingPanelSpacerWidth ||
          rawItemPos.X + area.insideRect.Width - this.item.Width ===
            this.fittingPanelSpacerWidth
        ) {
          this.item.swingFlexAreaIndex = area.index;
        }
      }
    }

    return [this.item];
  }

  toOffset(
    position: Interface_DTO_Draw.Rectangle,
    item: Client.ConfigurationItem,
  ): any {
    let _result = VectorHelper.subtract(position, item);
    _result.X = Math.round(_result.X);
    _result.Y = Math.round(_result.Y);
    return _result;
  }
}

export module FittingPanelGableSnapService {
  export interface SnapInfo extends Client.SnapInfo {
    gable: Client.ConfigurationItem;
    bottomShelf: Client.ConfigurationItem | undefined;
    topShelf: Client.ConfigurationItem | undefined;
    /**@deprecated use gable, topShelf and bottomShelf instead */
    snapsTo: Client.ConfigurationItem[];
  }

  export interface PositionInfo extends Interface_DTO_Draw.Rectangle {
    gable: Client.ConfigurationItem;
    bottomShelf: Client.ConfigurationItem | undefined;
    topShelf: Client.ConfigurationItem | undefined;
  }
}
